/**
 * Dependencies:
 * computed:
 *  - resources
 *   - geo_data_src
 */
export default {
    data() {
        return {
            geoDataSrc: {
                service: {
                    address: '',
                    marker: '',
                    src: '',
                },
                customer: {
                    address: '',
                    marker: '',
                    src: '',
                },
            },
        };
    },
    mounted() {
        this.geoDataSrc = this.resources.geo_data_src;
    },
    methods: {
        setGeoDataSource(type, key, value) {
            if (!this.geoDataSrc[type]) {
                this.geoDataSrc[type] = { address: '', marker: '', src: '' };
            }
            if (type === 'customer' && !this.model.related_customer_id) {
                this.geoDataSrc[type][key] = '';
            } else if (type === 'service' && !this.model.related_service_id) {
                this.geoDataSrc[type][key] = '';
            } else {
                this.geoDataSrc[type][key] = value;
            }
        },
        __geoDataSourceMixin__loadData(data) {
            this.geoDataSrc = data.resources.geo_data_src;
        },
    },
    computed: {
        isGeoDataSrcService() {
            return this.geoDataSrc.service.address || this.geoDataSrc.service.marker;
        },
        isServiceAddress() {
            return !!this.model.address && this.isGeoDataSrcService && this.model.address === this.geoDataSrc.service.address;
        },
        isServiceGps() {
            return !!this.model.gps && this.isGeoDataSrcService && this.model.gps === this.geoDataSrc.service.marker;
        },
        isCustomerAddress() {
            return !!this.model.address && this.model.address === this.geoDataSrc.customer.address;
        },
        isCustomerGps() {
            return !!this.model.gps && this.model.gps === this.geoDataSrc.customer.marker;
        },
        gpsSource() {
            let gps = '';

            if (this.isCustomerGps) {
                gps = this.$t('scheduling', 'Customer coordinates');
            }
            if (this.isServiceGps) {
                gps = this.$t('scheduling', 'Service coordinates');
            }

            if (this.isArchived && gps) {
                gps = `(${gps})`;
            }
            return gps;
        },
        addressSource() {
            let address = '';

            if (this.isCustomerAddress) {
                address = this.$t('scheduling', 'Customer address');
            }
            if (this.isServiceAddress) {
                address = this.$t('scheduling', 'Service address');
            }

            if (this.isArchived && address) {
                address = `(${address})`;
            }
            return address;
        },
    },
};
