import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';
import { SET_ADMIN_IMAP_PAGE_DATA } from '../../mutation-types';

const state = {
    securityModes: [],
    whatFindArray: [],
    adminsList: {},
    imapModel: {},
    attributesMeta: {},
    systemSmtpParams: {},
    systemSmtpIsConfigured: true,
    imapIsFullyConfigured: true,
    authTypesList: {},
    domainUrl: '',
    oauthAccount: {
        id: '',
        status: '',
        server_name: '',
        date_added: '',
        error_message: '',
    },
    defaultOfficeImapOauthValues: [],
    defaultOfficeSmtpOauthValues: [],
    smtpTypeOptions: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/profile--load-email-page-data',
                success: (response) => {
                    commit(SET_ADMIN_IMAP_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_ADMIN_IMAP_PAGE_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
