var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-box-header"},[_c('h4',[_vm._v(_vm._s(_vm.$t('messengers', 'Chats')))]),_c('div',{staticClass:"chat-box-actions"},[_c('a',{staticClass:"chat-box-action",attrs:{"data-test-selector":"create-chat","href":"javascript:void(0)","title":_vm.$t('messengers', 'Create chat')},on:{"click":function($event){return _vm.$emit('create')}}},[_c('i',{staticClass:"icon-ic_fluent_add_circle_24_regular"})]),_c('a',{staticClass:"chat-box-action",attrs:{"href":"javascript:void(0)","title":_vm.$t('messengers', 'Quick access')}},[_c('i',{staticClass:"icon-ic_fluent_filter_24_regular",on:{"click":_vm.onFilterClick}}),_c('x-complex-input',{ref:"select",attrs:{"value":_vm.filter,"data-test-selector":"filter-input","type":"multiple-select","params":{
                    options: {
                        id: 'admin_messengers_chatbox_filter_assignee',
                        options: {
                            '0': _vm.$t('messengers', 'Assigned to me'),
                            '1': _vm.$t('messengers', 'Unassigned'),
                        },
                    },
                }},on:{"close":function($event){_vm.filterOpened = false},"open":function($event){_vm.filterOpened = true},"input":_vm.onInput}})],1),_c('a',{staticClass:"chat-box-action",attrs:{"data-test-selector":"sound-notifications-active-state","href":"javascript:void(0)","title":_vm.soundNotificationsActive ? _vm.$t('messengers', 'Mute notifications') : _vm.$t('messengers', 'Unmute notifications')},on:{"click":_vm.toggleSoundNotifications}},[_c('i',{class:[_vm.soundNotificationsActive ? 'icon-ic_fluent_speaker_2_24_regular' : 'icon-ic_fluent_speaker_off_24_regular']})]),_c('a',{staticClass:"chat-box-action",attrs:{"href":"javascript:void(0)","title":_vm.$t('messengers', 'Minimize')},on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"icon-ic_fluent_line_horizontal_1_20_regular"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }