<template>
    <div class="input-group ipv6_input_block">
        <span
            ref="popoverButton"
            class="input-group-text btn-icon"
            :title="t('networking', 'Get IPv6')"
        >
            <i class="icon-ic_fluent_search_24_regular" />
        </span>
        <div
            :id="popoverId"
            class="webui-popover-content"
        >
            <div class="popover-content">
                {{ t('networking', 'Prefix') }}:
                <div class="mb-16">
                    <select
                        ref="prefixSelect"
                        class="select2"
                    >
                        <option
                            v-for="(prefix, index) in prefixes"
                            :key="index"
                            :value="prefix"
                            :selected="prefix === 64"
                        >
                            /{{ prefix }}
                        </option>
                    </select>
                </div>

                <div
                    v-for="(item, index) in networks"
                    :key="index"
                    class="network-wrap"
                >
                    <a @click.prevent="loadNetworkList(item.id)">{{ item.key }} - {{ item.title }}</a>
                    <div
                        class="network-ips-list"
                        :data-id="item.id"
                    >
                        <ul v-if="isShowNetworksList(item.id)">
                            <template v-for="(ip, subIndex) in networkIps[item.id].networks">
                                <br
                                    v-if="isset(ip, 'first') && ip.first"
                                    :key="subIndex"
                                >
                                <li :key="subIndex">
                                    <a
                                        href="javascript:void(0)"
                                        @click.prevent="insertNetwork(ip)"
                                    >{{ ip.network }}/{{ ip.prefix }}</a>
                                </li>
                            </template>

                            <a
                                v-show="isset(networkIps[item.id], 'showNext') && networkIps[item.id].showNext"
                                href="javascript:void(0)"
                                @click.prevent="loadNextNetworks(item.id)"
                            >
                                {{ t('networking', 'Load next') }}
                            </a>
                        </ul>
                        <div
                            v-if="isShowNetworkError(item.id)"
                            class="alert alert-warning"
                            role="alert"
                            v-html="networkIps[item.id].error"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            class="input-wrap"
            multiple
        >
            <select
                ref="select"
                v-bind="options"
            />
        </div>
    </div>
</template>

<script>
import { isset } from '@/utils/functions';

export default {
    name: 'XInputIpV6',
    props: {
        options: Object,
        networks: Array,
        module: String,
        attribute: String,
        id: [String, Number],
        value: [String, Array],
    },
    data() {
        return {
            popoverId: '',
            ipv6Id: null,
            prefixes: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64],
            selectedPrefix: 64,
            networkIps: {},
        };
    },
    watch: {
        selectedPrefix() {
            this.networkIps = {};
        },
        value(newValue) {
            let valueChanged = false;
            if (Array.isArray(newValue)) {
                let values = [];
                newValue.forEach((item) => {
                    values.push(item.id);
                });
                let curVal = $(this.$refs.select).val();
                valueChanged = ((!Array.isArray(curVal) && curVal != values.join(',')) || (Array.isArray(curVal) && values.join(',') !== curVal.join(',')));
            } else {
                valueChanged = newValue != $(this.$refs.select).val();
            }

            if (newValue === null) {
                newValue = '';
            }

            if (valueChanged) {
                if (Array.isArray(newValue)) {
                    newValue.forEach((item) => {
                        $(this.$refs.select).select2('trigger', 'select', {
                            data: item,
                        });
                    });
                } else if (typeof newValue === 'object' && newValue !== null) {
                    $(this.$refs.select).select2('trigger', 'select', {
                        data: newValue,
                    });
                } else {
                    $(this.$refs.select).val(newValue).trigger('change');
                }
            }
        },
    },
    created() {
        this.popoverId = `x_input_ipv6_popover_content_${Math.round(Math.random() * 999999)}`;
    },
    beforeDestroy() {
        let element = $(this.$refs.select);
        if (element.data('select2')) {
            element.select2('destroy');
        }

        let element2 = $(this.$refs.prefixSelect);
        if (element2.data('select2')) {
            element2.select2('destroy');
        }
    },
    mounted() {
        $(this.$refs.popoverButton).webuiPopover({
            title: this.t('networking', 'Select IPv6 network'),
            placement: 'right',
            width: 350,
            height: 250,
            url: `#${this.popoverId}`,
            onShow: () => {
                let select = $(this.$refs.prefixSelect);
                select.closest('.webui-popover').css('z-index', '200');
                select.select2({
                    minimumResultsForSearch: -1,
                    width: '100px',
                });

                select.on('select2:select', () => {
                    this.selectedPrefix = select.val();
                });
            },
        });

        this.initSelect();
    },
    methods: {
        isset,
        insertNetwork({ network, prefix }) {
            let fullNet = `${network}/${prefix}`;
            if (this.checkAllSelectedIpV6(fullNet)) {
                return;
            }

            let newOption = new Option(fullNet, fullNet, true, true);
            $(this.$refs.select).append(newOption).trigger('change');
            $(this.$refs.popoverButton).webuiPopover('hide');
        },
        checkAllSelectedIpV6(ip) {
            let check = false;
            $('.ipv6_input_block').each(function () {
                if ($(this).find('.select2-hidden-accessible').val() && $(this).find('.select2-hidden-accessible').val().indexOf(ip) != '-1') {
                    check = true;
                }
            });
            return check;
        },
        initSelect() {
            let element = $(this.$refs.select);
            if (element.data('select2')) {
                element.select2('destroy');
                element.find('option,optgroup').remove();
            }

            let select = $(this.$refs.select).select2({
                multiple: true,
                tokenSeparators: [' '],
                ajax: {
                    url: '/admin/relations--get-ip-v6',
                    dataType: 'json',
                    delay: 250,
                    data: (params) => ({
                        module: this.module,
                        attribute: this.attribute,
                        id: this.id,
                        query: params.term,
                    }),
                    processResults(data) {
                        return {
                            results: $.map(data.results, (field) => ({
                                text: field.text,
                                disabled: field.disabled,
                                children: $.map(field.children, (child) => ({
                                    id: child.id,
                                    text: child.text,
                                    disabled: child.disabled,
                                })),
                            })),
                        };
                    },
                    cache: true,
                },
                minimumInputLength: 0,
            });

            select.val(this.value).trigger('change');
            select.change(this.inputHandler);

            // @see https://github.com/select2/select2/issues/5993
            select.data('select2').on('open', this.setFocusToSearchInput);

            $(this.$refs.select).next().children('.selection').children('.select2-selection')
                .css('border-radius', '0px 3px 3px 0px');
        },
        setFocusToSearchInput() {
            setTimeout(() => {
                let field = $('.select2-container--open .select2-search__field');
                if (field.length) {
                    field.get(0).focus();
                }
            }, 100);
        },
        inputHandler(event) {
            let val;
            val = $(event.target).select2('data');
            if (val.length < 1) {
                val = event.target.value;
            } else {
                let result = [];
                val.forEach((item) => {
                    result.push({
                        id: item.id,
                        text: item.text,
                    });
                });
                val = result;
            }

            this.$emit('input', val);
            this.$emit('change', val);
        },
        loadNextNetworks(network) {
            let last = this.networkIps[network].networks[this.networkIps[network].networks.length - 1];
            this.loadNetworkList(network, last.network);
        },
        loadNetworkList(network, from) {
            if (isset(this.networkIps, [network, 'networks'])
                && this.networkIps[network].networks.length > 0
                && empty(from)
            ) {
                return;
            }

            let data = {
                network_id: network,
                prefix: this.selectedPrefix,
                selector: '',
            };

            if (from !== undefined && from !== '') {
                data.from = from;
            }

            $.ajax({
                url: '/admin/relations--load-ipv6-networks',
                data,
                success: (data) => {
                    if (!isset(this.networkIps, network)) {
                        this.networkIps[network] = {};
                    }
                    if (isset(data, 'error')) {
                        this.networkIps[network].error = data.error;
                    }
                    if (isset(data, 'showNext')) {
                        this.networkIps[network].showNext = data.showNext;
                    }

                    if (isset(data, 'networks')) {
                        if (isset(this.networkIps, [network, 'networks'])) {
                            data.networks.forEach((net) => {
                                this.networkIps[network].networks.push(net);
                            });
                        } else {
                            this.networkIps[network].networks = data.networks;
                        }
                    }

                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                },
            });
        },
        isShowNetworksList(itemId) {
            return isset(this.networkIps, [itemId, 'networks']) && this.networkIps[itemId].networks.length > 0;
        },
        isShowNetworkError(itemId) {
            return isset(this.networkIps, [itemId, 'error']) && !empty(this.networkIps[itemId].error);
        },
    },
};
</script>

<style scoped>
.input-group-text {
    cursor: pointer;
}

.network-wrap > a {
    cursor: pointer;
}
</style>
