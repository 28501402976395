<template>
    <div class="row">
        <div class="input-group">
            <input
                v-bind="attributes"
                ref="input"
                :value="defaultValue"
                data-test-selector="input"
                :data-test-value="defaultValue"
                @focusout="focusout"
                @keydown.enter="emitFocusout"
            >
            <input
                ref="hiddenInput"
                type="hidden"
                :name="nameForHidden"
                :value="valueForHidden"
                data-test-selector="hidden"
            >
            <button
                v-if="!disabled"
                ref="button"
                type="button"
                class="btn btn-outline-secondary btn-icon cursor-pointer"
                @click="clearPicker"
            >
                <i
                    v-if="!emptyValue || !clearButton"
                    class="icon-ic_fluent_calendar_ltr_24_regular"
                />
                <i
                    v-if="emptyValue && clearButton"
                    class="icon-ic_fluent_dismiss_24_regular"
                />
            </button>
        </div>
        <div class="mt-4">
            <a
                v-for="(month, index) in months"
                :key="index"
                href="javascript:void(0)"
                class="btn btn-link ms-0"
                :class="{'me-16': index !== months.length - 1}"
                @click="addMonths(month.number)"
                v-html="month.title"
            />
        </div>
    </div>
</template>

<script>
import { XInputDate } from '@/components/common/inputs/XInputDate';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputDateMonths',
    extends: XInputDate,
    mixins: [SplInputMixin],
    props: {
        monthsList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            months: [],
            defaultMonths: [
                { title: `+1 ${this.$t('common', 'months')}`, number: 1 },
                { title: `+3 ${this.$t('common', 'months')}`, number: 3 },
                { title: `+6 ${this.$t('common', 'months')}`, number: 6 },
                { title: `+1 ${this.$t('common', 'year')}`, number: 12 },
            ],
        };
    },
    created() {
        this.months = this.monthsList.length ? this.monthsList : this.defaultMonths;
    },
    methods: {
        addMonths(number) {
            let date = this.value;

            if (empty(date) || date === '0000-00-00') {
                date = moment();
            } else {
                date = moment(date, 'YYYY-MM-DD');
            }

            const updatedDate = date.add(number, 'months');
            const formattedDate = updatedDate.format('YYYY-MM-DD');

            this.$refs.input.value = updatedDate.format(date_format);
            $(this.$refs.input).trigger('change');

            this.$emit('input', formattedDate);
            this.emptyValue = false;
        },
    },
};
</script>
