export default {
    data() {
        return {
            templatesForRender: {},
        };
    },
    methods: {
        renderForTemplatesApplied(key) {
            return this.templatesForRender[key];
        },
        changeRenderForTemplates(key, value) {
            this.templatesForRender[key] = value;
        },
    },
};
