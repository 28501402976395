import {
    CLEAR_CREDIT_NOTE_MODAL_INFO,
    SET_CREDIT_NOTE_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { toCamelCase } from '@/utils/functions';

const state = {
    vatPercent: 0,
    taxId: 0,
    model: {},
    needShowPlansSelect: false,
    taxName: 'VAT',
    taxesArray: {},
    attributesMeta: {},
    afAttributesMeta: {},
    isAggregatedBilling: false,
    modelItems: [],
    linkedInvoice: null,
    linkedInvoiceData: [],
    taxTotal: 0,
    totalWithoutTax: 0,
    totalWithTax: 0,
    perTax: [],
    balanceAfter: '',
    readOnly: false,
    invoiceDtHeaders: [],
    invoiceDtModelName: '',
    invoiceDtColumnDefs: [],
    linkToCustomer: '',
    relations: [],
    relationsTotal: 0,
    relationsTotalFormatted: '0',
    additionalAttributes: [],
    linkedInvoiceRelationData: [],
    actionPermissions: {},
    enableResetPeriodForService: false,
    disableResetPeriodSelect: false,
    itemCategory: 0,
    syncedToAccounting: false,
    canBeDeleted: false,
    relatedItems: [],
    accounts: {},
    linkedBillingAccountData: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customer_id, credit_note_id, invoice_id }) {
        let data = {
            customer_id,
        };
        if (credit_note_id) {
            data.credit_note_id = credit_note_id;
        }
        if (invoice_id) {
            data.invoice_id = invoice_id;
        }

        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/customers/billing/credit-notes--get-modal-info',
                method: 'GET',
                data,
                success: (response) => {
                    commit(SET_CREDIT_NOTE_MODAL_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    getNewNumber(obj, { customer_id, date_created }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/credit-notes--number?customer_id=${customer_id}`,
                method: 'GET',
                data: {
                    date_created,
                },
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadInvoiceTransactions(obj, { invoiceId }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/invoices--get-transactions?invoice_id=${invoiceId}`,
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    clearCreditNoteModalInfo({ commit }) {
        commit(CLEAR_CREDIT_NOTE_MODAL_INFO);
    },
};

const mutations = {
    [SET_CREDIT_NOTE_MODAL_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                state.afAttributesMeta = info[key].additional_attributes;
                continue;
            }

            if (key === 'model') {
                state.readOnly = info[key].status !== 'not_refunded' || info[key].partiallyUse === true;
            }

            state[toCamelCase(key)] = info[key];
        }
    },
    [CLEAR_CREDIT_NOTE_MODAL_INFO](state) {
        state.model = {};
        state.modelItems = [];
        state.linkedInvoice = null;
        state.linkedInvoiceData = [];
        state.linkedInvoiceRelationData = [];
        state.taxTotal = 0;
        state.totalWithoutTax = 0;
        state.totalWithTax = 0;
        state.perTax = [];
        state.readOnly = false;
        state.balanceAfter = '';
        state.enableResetPeriodForService = false;
        state.disableResetPeriodSelect = false;
        state.itemCategory = 0;
        state.syncedToAccounting = false;
        state.canBeDeleted = false;
        state.relatedItems = [];
        state.accounts = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
