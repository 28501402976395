import MessengersService from '@/apps/admin/services/messengers/MessengersService';
import Message from '@/apps/admin/services/messengers/Message';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            chatData: {
                id: this.chatId,
                active: false,
                canAdminWrite: false,
                assigneeName: '',
                phone: '',
                profileName: '',
                title: '',
                senderType: '',
                cannedMessages: [],
            },
        };
    },
    mounted() {
        this.initSocketListener();
    },
    methods: {
        ...mapActions('chats_listener', ['addChatHandlers']),
        // Events subscribe
        initSocketListener() {
            this.addChatHandlers({ callbacks: [this.onConversationClose], ids: { chat: this.chatId, messenger: this.messenger } });
            this.addChatHandlers({ callbacks: [this.onNewItem], ids: { chat: this.chatId, messenger: this.messenger } });
            this.addChatHandlers({ callbacks: [this.onAttachmentUpdate], ids: { chat: this.chatId, messenger: this.messenger } });
            this.addChatHandlers({ callbacks: [this.onAddActivitiesForLastMessage], ids: { chat: this.chatId, messenger: this.messenger } });
            this.addChatHandlers({ callbacks: [this.onReassign], ids: { chat: this.chatId, messenger: this.messenger } });
        },
        // Event handlers
        async onAttachmentUpdate({ attachments, message_id }) {
            const message = this.currentPart.messages.find((message) => message.id === message_id);
            if (message) {
                message.attachments = attachments;
            }
        },
        async onAddActivitiesForLastMessage({ activities }) {
            const message = this.currentPart.messages.find((message) => message.id === this.currentPart.order[0]);
            if (message) {
                message.activities = activities;
                if (this.lastMessageInFocus) {
                    this.$nextTick(() => {
                        this.scrollEnd(true);
                    });
                }
            }
        },
        async onNewItem(newItem) {
            const { data, new_conversation, chat } = newItem;
            const { messages, conversations } = data;

            if (messages[0].type === Message.TYPE_OUT && !this.isLastPart) {
                await this.loadLastPart();
                return false;
            }

            const newConversation = new_conversation;
            const conversation = Object.values(conversations)[0] ?? {};
            if (chat && chat.active) {
                await this.loadChatData();
            }
            if (!this.currentPart.conversations[conversation.id]) {
                if (newConversation) {
                    this.restoreConversation(conversation);
                } else {
                    this.$showInformation(this.$t('messengers', 'Below is a new message'), 4);
                    return false;
                }
                if (!chat && !chat.active) {
                    await this.loadChatData();
                }
            }
            this.receiveMessages(messages);
        },
        async onConversationClose({ conversation_id, activities }) {
            if (this.chatbox) {
                this.$chatBoxShowList();
                return false;
            }
            await this.loadChatData();
            // currentPart - from ChatParts mixin
            const conversation = Object.values(this.currentPart.conversations).find((item) => item.id === conversation_id);
            if (conversation) {
                this.lastMessage.activities = activities;
                if (this.lastMessageInFocus) {
                    this.$nextTick(() => {
                        this.scrollEnd(true);
                    });
                }
            } else {
                this.$showInformation(this.$t('messengers', 'Below is a new message'), 4);
            }
        },
        onReassign({ assignee_name, can_admin_write }) {
            this.chatData.canAdminWrite = can_admin_write;
            this.chatData.assigneeName = assignee_name;
        },

        // Functions
        async loadChatData() {
            const service = new MessengersService(this.senderType);
            const { data, result, message } = await service.getChatData(this.chatId);
            if (!result) {
                this.$showError(message, 4);
                this.$emit('chat-not-found');
                return false;
            }
            const {
                assigneeName, active, phone, profileName, title, id,
                checkAccessController, checkAccessAction, canAdminWrite,
                startConversation, senderType, cannedResponses,
            } = data;
            this.chatData.startConversationData = startConversation;
            this.chatData.checkAccessController = checkAccessController;
            this.chatData.checkAccessAction = checkAccessAction;
            this.chatData.id = id;
            this.chatData.active = active;
            this.chatData.canAdminWrite = canAdminWrite;
            this.chatData.assigneeName = assigneeName;
            this.chatData.phone = phone;
            this.chatData.profileName = profileName;
            this.chatData.title = title;
            this.chatData.senderType = senderType;
            this.chatData.cannedMessages = cannedResponses;

            this.$emit('chat-data', this.chatData);
            return true;
        },
        restoreConversation(data) {
            this.currentPart.conversations = {
                ...this.currentPart.conversations,
                [data.id]: data,
            };
        },
        receiveMessages(list) {
            if (!list.length) {
                return false;
            }
            this.currentPart.messages.push(list[0]);
            this.currentPart.order.unshift(list[0].id);
        },

        // User actions
        async closeChat(chatId) {
            this.processing = true;
            await window.close_messenger_chat(chatId, this.senderType);
            this.processing = false;
        },
        async reAssignToMe(chatId) {
            const service = new MessengersService(this.senderType);
            let { result, message } = await service.reAssignAdmin(chatId, window?.spl_config?.spl_user_id);
            if (!result && message) {
                this.$showError(message, 4);
            }
            await this.loadChatData();
        },
        async sendMessage({ content, files }) {
            this.processing = true;
            const service = new MessengersService(this.senderType);
            let { result, message } = await service.sendMessage(this.chatId, content, files);
            if (!result && message) {
                this.$showError(message, 4);
            }
            this.processing = false;
        },
    },
    computed: {
        chatBoxHeadActionsForAnySender() {
            return [
                {
                    title: this.$t('messengers', 'View chat'),
                    name: 'view-chat',
                },
            ];
        },
        unknownSenderActions() {
            const actions = [
                {
                    title: this.$t('messengers', 'Create customer/lead'),
                    name: 'create-from-unknown',
                    icon: 'icon-ic_fluent_people_community_add_24_regular',
                },
                {
                    title: this.$t('messengers', 'Link to customer/lead'),
                    name: 'link-sender',
                    icon: 'icon-ic_fluent_people_swap_24_regular',
                },
            ];
            if (this.chatData.active) {
                actions.push({
                    title: this.$t('messengers', 'Re-assign chat'),
                    name: 're-assign-chat',
                    icon: 'icon-ic_fluent_people_edit_20_regular',
                });
                actions.push({
                    title: this.$t('messengers', 'Close chat'),
                    name: 'close-chat',
                    icon: 'icon-ic_fluent_dismiss_24_regular',
                });
            }

            if (this.chatbox) {
                actions.unshift(...this.chatBoxHeadActionsForAnySender);
            }

            if (this.selectedItems.length) {
                actions.push(...this.getMassActions(false));
            }

            return actions;
        },
        existingSenderActions() {
            const actions = [
                {
                    title: this.$t('messengers', 'Close chat'),
                    name: 'close-chat',
                },
            ];
            if (this.chatData.active) {
                actions.unshift({
                    title: this.$t('messengers', 'Re-assign chat'),
                    name: 're-assign-chat',
                });
            }

            if (this.chatbox) {
                actions.unshift(...this.chatBoxHeadActionsForAnySender);
            }

            if (this.selectedItems.length) {
                actions.push(...this.getMassActions());
            }

            return actions;
        },
        chatActions() {
            if (this.unknownSender) {
                return this.unknownSenderActions;
            }
            return this.existingSenderActions;
        },
        title() {
            return this.chatData.title ?? this.messenger;
        },
    },
};
