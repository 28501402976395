<template>
    <x-twig-page v-if="isTwigEntryPoint" />
    <x-addon-vue-code
        v-else
        :allow-no-vue-point="true"
        :module="$route.query.module"
        :point="$route.query.point"
        :point-params="{ module: $route.query.module, point: $route.query.point }"
        :is-page="true"
        @no-vue-point="isTwigEntryPoint = true"
    >
        <template #preloader>
            <x-preloader-block :loading="true" />
        </template>
    </x-addon-vue-code>
</template>

<script>
import XAddonVueCode from '@/components/dialogs/add-ons/XAddonVueCode.vue';
import { XTwigPage } from '@/components/base/XTwigPage';
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock.vue';

export default {
    name: 'XAddonPage',
    data() {
        return {
            isTwigEntryPoint: false,
        };
    },
    components: {
        XPreloaderBlock,
        XTwigPage,
        XAddonVueCode,
    },
};
</script>
