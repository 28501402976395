import RealNumber from '@/utils/classes/RealNumber';

import createHash from 'create-hash';

function supports_html5_storage() {
    try {
        return 'localStorage' in window && window.localStorage !== null;
    } catch (e) {
        return false;
    }
}

/**
 * Check if var is empty. Analog PHP function empty()
 * This function does not check the object. The object will always be non-empty.
 * Use the @emptyObject function to validate the object correctly
 *
 * @param variable
 * @param checkObject
 * @returns {boolean}
 */
function empty(variable, checkObject = false) {
    let emptyObject = false;
    if (checkObject) {
        emptyObject = variable instanceof Object && Object.keys(variable).length === 0;
    }
    return (
        variable === undefined
        || variable === ''
        || variable === 0
        || variable === '0'
        || variable === null
        || variable === false
        || (variable instanceof Array && variable.length === 0)
        || emptyObject
    );
}

function parseAnonymousSwitchPage(action) {
    let decorator = '';
    if (typeof action !== 'string') {
        decorator = `(${action.toString()})`;
    } else {
        decorator = `(${action})`;
    }
    decorator = decorator.replace('anonymous(', 'anonymous(...params');
    decorator = decorator.replace(/(\)\s*)(;?\s*}\s*\)$)/, ', ...params); })');
    return new Function('event', 'force', `const f = ${decorator}; f(event, force)`);
}

function isset(object, path) {
    let findPath;
    if (typeof object !== 'object' || object === null) {
        return false;
    }

    if (typeof path === 'number') {
        path = path.toString();
    }

    let parts;
    if (typeof path === 'string' || path instanceof String) {
        parts = [path];
    } else if (Array.isArray(path)) {
        parts = path;
    } else {
        return false;
    }

    findPath = function (object, path) {
        let first = path.shift().toString();
        if (object === null || typeof object !== 'object' || typeof object[first] === 'undefined') {
            return false;
        }

        if (path.length <= 0) {
            return true;
        }

        return findPath(object[first], path);
    };

    return findPath(object, parts);
}

function getUrlParam(name) {
    let results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
    return results[1] || 0;
}

/**
 * Converts the object to a parameters URL string.
 * @param object
 * @param parentKey
 * @returns {string}
 */
function param(object, parentKey) {
    const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
    const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

    const buildParam = (key, value, parentKey) => {
        let fullKey = parentKey ? `${parentKey}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

        if (value instanceof Date) {
            return `${fullKey}=${encodeURIComponent(value.toISOString())}`;
        } if (typeof value === 'object' && value !== null) {
            if (isEmptyObject(value) || isEmptyArray(value)) {
                return '';
            }
            return param(value, fullKey);
        }
        return `${fullKey}=${encodeURIComponent(value)}`;
    };

    return Object.keys(object)
        .map((key) => buildParam(key, object[key], parentKey))
        .filter((param) => param !== '')
        .join('&');
}

/**
 * Adds a get parameters from the object to string.
 * @param url
 * @param params
 * @returns {string}
 */
function withGet(url, params) {
    if (emptyObject(params)) {
        return url;
    }
    return `${url}?${param(params)}`;
}

/**
 * Check object is empty. Also works correctly with arrays.
 * @param someObject
 * @returns {boolean}
 */
function emptyObject(someObject) {
    return someObject === undefined || Object.keys(someObject).length <= 0;
}

/**
 * Sort JS object by internal attribute
 * @param object
 * @param propertyName
 * @param deep
 * @returns {{}}
 */
function sortObjectByProperty(object, propertyName, deep = false, useMap = false) {
    let positionList = {};
    for (let key in object) {
        if (!Object.prototype.hasOwnProperty.call(object, key)) {
            continue;
        }

        if (deep) {
            let objectItem = object[key];
            for (let positionKey in propertyName) {
                if (!Object.prototype.hasOwnProperty.call(propertyName, positionKey)) {
                    continue;
                }
                objectItem = objectItem[propertyName[positionKey]];
            }
            positionList[key] = objectItem;
        } else {
            positionList[key] = parseInt(object[key][propertyName]);
        }
    }

    let sortedPosition = [];
    for (let key in positionList) {
        sortedPosition.push([key, positionList[key]]);
    }

    sortedPosition.sort((a, b) => a[1] - b[1]);

    let newList = {};
    if (useMap) {
        newList = new Map();
    }
    for (let itemKey in sortedPosition) {
        if (!Object.prototype.hasOwnProperty.call(sortedPosition, itemKey)) {
            continue;
        }

        if (useMap) {
            newList.set(sortedPosition[itemKey][0], object[sortedPosition[itemKey][0]]);
        } else {
            newList[sortedPosition[itemKey][0]] = object[sortedPosition[itemKey][0]];
        }
    }

    return newList;
}

/**
 * Get value in object by path. Path example: Device.Info.IP where . divide object attributes.
 * @param object
 * @param path
 * @returns {undefined|*}
 */
function getValueByObjectPath(object, path) {
    const pathItems = path.split('.');
    let value = object;
    for (let objectPathKey in pathItems) {
        if (!Object.prototype.hasOwnProperty.call(pathItems, objectPathKey)) {
            continue;
        }

        if (value[pathItems[objectPathKey]] === undefined) {
            return undefined;
        }

        value = value[pathItems[objectPathKey]];
    }

    return value;
}

/**
 * Filter objects by object attribute value
 * @param objects
 * @param path
 * @param value
 * @returns {{object}}
 */
function filterObjectsByValueInPath(objects, path, value) {
    let result = {};

    for (let key in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, key) && getValueByObjectPath(objects[key], path) === value) {
            result[key] = objects[key];
        }
    }

    return result;
}

function rand(min, max) {
    return Math.random() * (max - min) + min;
}

function getDaysToDate(blockingDate = null, format = 'YYYY-MM-DD') {
    if (!blockingDate) {
        return 0;
    }
    const date = moment(blockingDate, format).startOf('day');
    const currentDate = moment().startOf('day');
    const daysDifference = date.diff(currentDate, 'days');
    if (daysDifference > 0) {
        return daysDifference;
    }
    if (daysDifference === 0) {
        return 0;
    }
    return -1;
}

function getUrlParams(url) {
    if (typeof url === 'undefined') {
        url = window.location.href;
    }

    let urlObj = new URL(url);
    return new URLSearchParams(urlObj.search);
}

function getWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

function getWindowHeight() {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this;
        let
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function deepCopyObj(object) {
    return JSON.parse(JSON.stringify(object));
}

function isArray(value) {
    return typeof value === 'object' && value !== null && value.constructor === Array;
}

function mergeDeep(target, source) {
    let output = { ...target };
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] });
                else output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

function processingAdditionalFields(additionalAttributes, afsForAdding) {
    let result = {};
    Object.entries(afsForAdding).forEach(([key, afMeta]) => {
        let processingValue = additionalAttributes[key];
        let isAjax = isset(afMeta, 'ajax') && afMeta.ajax;

        if (isAjax) {
            if (Array.isArray(processingValue)) {
                if (afMeta.type === 'relation_multiple') {
                    let arr = [];
                    processingValue.forEach((item) => {
                        arr.push(item.id);
                    });
                    processingValue = arr;
                } else {
                    let first = processingValue[0];
                    if (typeof first === 'object') {
                        processingValue = first.id;
                    } else {
                        processingValue = first;
                    }
                }
            } else if (typeof processingValue === 'object') {
                processingValue = processingValue.id;
            }
        }
        if (afMeta.type === 'file') {
            result[key] = processingValue;
        } else {
            result[key] = processingRawTypes(processingValue);
        }
    });
    return result;
}

function processingRawTypes(value) {
    // There are examples when the type "NaN" comes in the values when sending to the server
    // and php does not process it correctly, I made such a conversion so that everything would work as before
    return JSON.parse(JSON.stringify(value));
}

function nl2br(string, isXhtml) {
    if (typeof string === 'undefined' || string === null) {
        return '';
    }
    let breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>';
    return (`${string}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

const createDeferredPromise = () => {
    let resolveFn;
    let rejectFn;

    const promise = new Promise((resolve, reject) => {
        resolveFn = resolve;
        rejectFn = reject;
    });

    return {
        promise,
        resolve: resolveFn,
        reject: rejectFn,
    };
};

function promisifiedAjax(data) {
    return new Promise((resolve, reject) => {
        $.ajax({
            ...data,
            success(data) {
                resolve(data);
            },
            error(error) {
                reject(error);
            },
        });
    });
}

const setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
        let range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
};

function asteriskWithExcludeStart(string, count = null) {
    if (!count) {
        return '*'.repeat(string.length);
    }
    return string.slice(0, count) + '*'.repeat(string.substring(count).length);
}

function base64Decode(string) {
    try {
        return atob(string);
    } catch {
        return '';
    }
}

function base64Encode(string) {
    try {
        return btoa(string);
    } catch {
        return '';
    }
}

const md5 = (string) => {
    if (!string) {
        return string;
    }
    return createHash('md5').update(string).digest('hex');
};

function asterisksEmail(input, base64 = false) {
    const email = base64 ? base64Decode(input) : input;
    if (!email) {
        return '';
    }
    let parts = email.split('@');
    let secondParts = parts[1].split('.');
    let firstAsterisks = asteriskWithExcludeStart(parts[0], 2);
    let secondAsterisks = asteriskWithExcludeStart(secondParts[0], 1);
    let thirdAsterisks = asteriskWithExcludeStart(secondParts[secondParts.length - 1]);
    return `${firstAsterisks}@${secondAsterisks}.${thirdAsterisks}`;
}

function phoneNormalize(phone) {
    phone = phone.replace(/\D/g, '').slice(-10);
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
}

function asterisksPhone(input, base64 = false) {
    const phone = base64 ? base64Decode(input) : input;
    if (!phone) {
        return '';
    }
    const normalized = phoneNormalize(phone.replace(/\+/g, ''));
    return normalized
        .substring(0, normalized.length - 2).replace(/\d/g, '*') + normalized.slice(-2);
}

function getValueWithCurrency(value) {
    let pattern = window.currency_format_pattern;
    if (typeof value === 'string') {
        return pattern.replace('0', value.trim());
    }
    if (typeof value === 'number') {
        return pattern.replace('0', value);
    }
    return '';
}

function format(number) {
    return new RealNumber(number, {
        float: window.spl_config.numberConfig.decimal_finance.digits,
        thousandSeparator: window.spl_config.numberConfig.thousand_finance.separator,
        decimalSeparator: window.spl_config.numberConfig.decimal_finance.separator,
    })._stringFormat;
}

function taxFormat(number, withoutSeparatorChanges = false) {
    if (withoutSeparatorChanges) {
        return new RealNumber(number, {
            float: window.spl_config.numberConfig.tax.digits,
        })._stringFormat;
    }
    return new RealNumber(number, {
        float: window.spl_config.numberConfig.tax.digits,
        thousandSeparator: window.spl_config.numberConfig.thousand_finance.separator,
        decimalSeparator: window.spl_config.numberConfig.decimal_finance.separator,
    })._stringFormat;
}

function formatDate(date) {
    let format = window.moment(date, 'YYYY-MM-DD').format(date_format);
    if (format !== 'Invalid date') {
        return format;
    }
    return '';
}

function getTodayDate() {
    return window.moment().format(date_format);
}

function formatDateTime(dateTime) {
    let format = window.moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format(datetime_format);
    if (format !== 'Invalid date') {
        return format;
    }
    return '';
}

function toCamelCase(str) {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

const snakeCase = (string) => string && string.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
)
    .map((string) => string.toLowerCase())
    .join('_');

function formattedResponse(response) {
    if (typeof response !== 'object') {
        return response;
    }
    return Object.keys(response).reduce((acc, key) => {
        acc[toCamelCase(key)] = response[key];
        return acc;
    }, {});
}

function formattedRequest(request) {
    if (typeof request !== 'object' || request === null) {
        return request;
    }
    if (Object.prototype !== Object.getPrototypeOf(request)) {
        return request;
    }
    return Object.keys(request).reduce((acc, key) => {
        acc[snakeCase(key)] = request[key];
        return acc;
    }, {});
}

function compareDays(...days) {
    let daysArray = [...days].filter((item) => Number(item) > 0);

    for (let i = 0; i < daysArray.length - 1; i++) {
        let a = Number(daysArray[i]);
        let b = Number(daysArray[i + 1]);
        if (a >= b) {
            window.showErrors([window.t('common', '"Reminder #{number} day" should be in ascending order.', { number: i + 1 })], 4);
            return true;
        }
    }
    return false;
}

function sizeInBytesToStringValue(bytes, roundLength, kb_is, bits) {
    if (typeof bytes === 'undefined' || bytes === '') {
        return '';
    }
    if (typeof roundLength === 'undefined') {
        roundLength = 2;
    }
    if (typeof kb_is === 'undefined') {
        kb_is = window.spl_config.numberConfig.networking.size_of_kb;
    }
    if (typeof bits === 'undefined') {
        bits = false;
    }

    bytes = parseFloat(bytes);

    let types;
    let i;

    if (bits) {
        types = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    } else {
        types = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    }

    for (i = 0; bytes >= kb_is && i < (types.length - 1); i++) {
        bytes /= kb_is;
    }

    return `${bytes.toFixed(roundLength) / 1} ${types[i]}`;
}

function findJsonInText(text) {
    // JSON.parse is not suitable for such a case,
    // so the following function has been added for simple JSON responses,
    // which may include text
    let jsonObjects = [];
    let openBrackets = 0;
    let startPosition = -1;

    if (typeof text !== 'string' || !text) {
        return jsonObjects;
    }

    for (let i = 0; i < text.length; i++) {
        if (text[i] === '{') {
            if (openBrackets === 0) {
                startPosition = i;
            }
            openBrackets++;
        } else if (text[i] === '}') {
            openBrackets--;

            if (openBrackets === 0 && startPosition !== -1) {
                const jsonString = text.slice(startPosition, i + 1);
                try {
                    const jsonObject = JSON.parse(jsonString);
                    jsonObjects.push(jsonObject);
                } catch (e) {
                    console.error('Invalid JSON:', e);
                }
                startPosition = -1;
            }
        }
    }
    return jsonObjects;
}

function interceptError(xhr, redirectHandler, clientErrorHandle, serverErrorHandle) {
    let response = xhr.responseJSON;
    let handler = () => {};
    if (!response) {
        response = findJsonInText(xhr.responseText)[0];
    }
    if (xhr.status >= 300 && xhr.status < 400) {
        if (typeof redirectHandler === 'function') {
            handler = redirectHandler;
        }
    }
    if (xhr.status >= 400 && xhr.status < 500) {
        if (typeof clientErrorHandle === 'function') {
            handler = clientErrorHandle;
        }
    }
    if (xhr.status >= 500) {
        if (typeof serverErrorHandle === 'function') {
            handler = serverErrorHandle;
        }
    }
    if (arguments.length === 2) {
        // Common function if defined only first handler
        handler = redirectHandler;
    }
    handler.apply(null, [response]);
}

function convertToHoursAndMinutes(decimalNumber) {
    const hours = Math.floor(decimalNumber);
    const minutes = Math.round((decimalNumber - hours) * 60);
    return {
        hours,
        minutes,
    };
}

function generateParams(params) {
    let stringParams = [];

    for (let key in params) {
        stringParams.push(`${key}=${params[key]}`);
    }

    return stringParams.join('&');
}

function responseNotification(response, successCallback, errorCallback) {
    let { result, message } = response;
    if (result && message) {
        show_success(message, 4);
        typeof successCallback === 'function' && successCallback();
    } else {
        show_error(message, 4);
        typeof errorCallback === 'function' && errorCallback();
    }
}

function getDropdownButton(title, clickCallback, options = {}, params = {}) {
    return {
        options: { class: 'btn btn-outline-primary', ...options },
        title,
        events: {
            click: clickCallback,
        },
        ...params,
    };
}

function getButton(text, className, click, disabled = false, options = {}, params = {}) {
    return {
        text,
        options: {
            disabled,
            class: ['btn', `btn-${className}`],
            ...options,
        },
        click,
        ...params,
    };
}

function getAttributeLabel(attributesMeta, attribute) {
    if (attributesMeta) {
        if (isset(attributesMeta.attributes, [attribute, 'label'])) {
            return attributesMeta.attributes[attribute].label;
        }
        if (isset(attributesMeta, [attribute, 'label'])) {
            return attributesMeta[attribute].label;
        }
    }
    return attribute;
}

export {
    parseAnonymousSwitchPage,
    convertToHoursAndMinutes,
    createDeferredPromise,
    interceptError,
    findJsonInText,
    supports_html5_storage,
    empty,
    isset,
    nl2br,
    getUrlParam,
    emptyObject,
    sortObjectByProperty,
    getValueByObjectPath,
    filterObjectsByValueInPath,
    rand,
    getUrlParams,
    getWindowWidth,
    getWindowHeight,
    debounce,
    mergeDeep,
    isObject,
    deepCopyObj,
    isArray,
    processingAdditionalFields,
    promisifiedAjax,
    processingRawTypes,
    setSelectionRange,
    getValueWithCurrency,
    format,
    taxFormat,
    formatDate,
    formatDateTime,
    getTodayDate,
    toCamelCase,
    formattedResponse,
    formattedRequest,
    compareDays,
    base64Decode,
    base64Encode,
    md5,
    asteriskWithExcludeStart,
    asterisksPhone,
    asterisksEmail,
    sizeInBytesToStringValue,
    getDaysToDate,
    generateParams,
    withGet,
    param,
    responseNotification,
    getButton,
    getDropdownButton,
    getAttributeLabel,
};
