export default class IDBHelper {
    constructor(dbName = 'splynx-db', version = 1, storeNames = []) {
        this.dbName = dbName;
        this.version = version;
        this.storeNames = storeNames;
        this.db = null;
        this.ready = this.init();
    }

    init() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.version);

            request.onupgradeneeded = (e) => {
                const db = e.target.result;
                this.storeNames.forEach((store) => {
                    if (!db.objectStoreNames.contains(store)) {
                        db.createObjectStore(store, { keyPath: 'id' });
                    }
                });
            };

            request.onsuccess = (e) => {
                this.db = e.target.result;
                resolve();
            };

            request.onerror = (e) => {
                reject(e.target.error);
            };
        });
    }

    async _withStore(storeName, mode, fn) {
        await this.ready;
        const tx = this.db.transaction(storeName, mode);
        const store = tx.objectStore(storeName);
        return fn(store, tx);
    }

    async add(storeName, value) {
        return this._withStore(storeName, 'readwrite', (store) => new Promise((resolve, reject) => {
            const req = store.add(value);
            req.onsuccess = () => resolve(true);
            req.onerror = () => reject(req.error);
        }));
    }

    async put(storeName, value) {
        return this._withStore(storeName, 'readwrite', (store) => new Promise((resolve, reject) => {
            const req = store.put(value);
            req.onsuccess = () => resolve(true);
            req.onerror = () => reject(req.error);
        }));
    }

    async get(storeName, id) {
        return this._withStore(storeName, 'readonly', (store) => new Promise((resolve, reject) => {
            const req = store.get(id);
            req.onsuccess = () => resolve(req.result);
            req.onerror = () => reject(req.error);
        }));
    }

    async getAll(storeName) {
        return this._withStore(storeName, 'readonly', (store) => new Promise((resolve, reject) => {
            const req = store.getAll();
            req.onsuccess = () => resolve(req.result);
            req.onerror = () => reject(req.error);
        }));
    }

    async delete(storeName, id) {
        return this._withStore(storeName, 'readwrite', (store) => new Promise((resolve, reject) => {
            const req = store.delete(id);
            req.onsuccess = () => resolve(true);
            req.onerror = () => reject(req.error);
        }));
    }

    close() {
        this.db?.close?.();
    }
}
