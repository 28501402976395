// Persian
jQuery.timeago.settings.strings = {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: "پیش",
    suffixFromNow: "از حال",
    seconds: "کمتر از یک دقیقه",
    minute: "حدود یک دقیقه",
    minutes: "%d دقیقه",
    hour: "حدود یک ساعت",
    hours: "حدود %d ساعت",
    day: "یک روز",
    days: "%d روز",
    month: "حدود یک ماه",
    months: "%d ماه",
    year: "حدود یک سال",
    years: "%d سال",
    wordSeparator: " ",
    numbers: ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']
};