import {
    CLEAR_PROFORMA_INVOICE_MODAL_INFO,
    SET_PROFORMA_INVOICE_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    formattedResponse,
    toCamelCase,
} from '@/utils/functions';

const state = {
    vatPercent: 0,
    taxId: 0,
    model: {},
    needShowPlansSelect: false,
    readOnly: false,
    taxName: 'VAT',
    taxesArray: {},
    attributesMeta: {},
    afAttributesMeta: {},
    isAggregatedBilling: false,
    modelItems: [],
    taxTotal: 0,
    totalWithoutTax: 0,
    totalWithTax: 0,
    perTax: [],
    linkToCustomer: '',
    relations: [],
    relationsTotal: 0,
    relationsTotalFormatted: '0',
    additionalAttributes: [],
    actionPermissions: {},
    accounts: {},
    linkedBillingAccountData: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, { customer_id, request_id }) {
        let data = {
            customer_id,
        };
        if (request_id) {
            data.request_id = request_id;
        }

        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/customers/billing/proforma-invoices--get-modal-info',
                method: 'GET',
                data,
                success: (response) => {
                    commit(SET_PROFORMA_INVOICE_MODAL_INFO, formattedResponse(response));
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    getNewNumber(obj, { customer_id, date_created }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/proforma-invoices--number?customer_id=${customer_id}`,
                method: 'GET',
                data: {
                    date_created,
                },
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_PROFORMA_INVOICE_MODAL_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                state.afAttributesMeta = info[key].additional_attributes;
                continue;
            }
            if (key === 'model' && info[key].status == 'paid') {
                state.readOnly = true;
            }
            state[toCamelCase(key)] = info[key];
        }
    },
    [CLEAR_PROFORMA_INVOICE_MODAL_INFO](state) {
        state.model = {};
        state.modelItems = [];
        state.taxTotal = 0;
        state.totalWithoutTax = 0;
        state.totalWithTax = 0;
        state.perTax = [];
        state.readOnly = false;
        state.accounts = {};
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
