import { empty } from '@/utils/functions';
import { mapActions } from 'vuex';

/**
 * Dependencies:
 * data:
 *  - className
 *  - options
 *   - customer_options
 *  - model
 *   - partner_id
 *   - location_id
 *   - related_customer_id
 * methods:
 *  - showWarningIncorrectValues
 *  - setAddressAction
 *  - setCoordinateAction
 *  - checkPartners
 *  - getRelated
 *  - renderTemplates
 *  - setGeoDataSource
 */
export default {
    data() {
        return {
            needShowServiceField: false,
        };
    },
    methods: {
        ...mapActions('scheduling_resources', ['updateCustomerOptions']),
        /** @method Call it after loadData */
        __customerMixin_loadData(data) {
            this.needShowServiceField = data.resources.options.need_show_service_field;
            this.relatedToProjectData = data.resources.related_to_project_data;

            if (this.needShowServiceField) {
                this.getRelated();
            }
        },
        processDataFromSelect() {
            let selectCustomerData = $(`#scheduling_task_${this.isSidebar ? 'preview' : 'customer_details'}_related_customer_id`).select2('data');

            if (typeof selectCustomerData === 'undefined') {
                return false;
            }

            if (!empty(selectCustomerData[0]?.partner_id)) {
                this.model.partner_id = selectCustomerData[0].partner_id;
            }

            if (!this.isSidebar) {
                this.updateCustomerOptions(selectCustomerData);
            }

            if (!empty(selectCustomerData[0]?.location_id)) {
                this.model.location_id = selectCustomerData[0].location_id;
            }
        },
        changeCustomer(newVal) {
            if (Array.isArray(newVal)) {
                this.model.related_customer_id = newVal[0].id;
            }

            // If we remove customer we should remove service value
            if (empty(newVal)) {
                this.model.related_service_id = '';
                this.model.partner_id = '';
                this.model.location_id = '0';
            }

            // Update partner, location and customer options for details
            this.processDataFromSelect();
            // Check for correct partner
            this.checkPartners();
            this.renderTemplates();

            if (this.needShowServiceField) {
                this.getRelated();
            }
        },
    },
    computed: {
        customerAllParams() {
            return {
                placeholder: this.$t('scheduling', 'Select customer'),
                data: this.options.customer_options,
                options: {
                    id: `scheduling_task_${this.isSidebar ? 'preview' : 'customer_details'}_related_customer_id`,
                    name: `${this.className}[related_customer_id]`,
                    class: this.showWarningIncorrectValues('related_customer_id') && this.model.related_customer_id ? 'incorrect' : '',
                    disabled: this.disableFields,
                },
                ajaxUrl: '/admin/scheduling/tasks--find-customers',
                searchKey: 'login',
                ajaxData: {
                    partner_id: !empty(this.model.partner_id) ? this.model.partner_id : 0,
                },
            };
        },
    },
};
