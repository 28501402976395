import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_SCHEDULING_MAP_INFO, SET_SCHEDULING_MAP_MARKERS } from '../../mutation-types';

const state = {
    markers: [],
    projects: {},
    priorities: [],
    partners: [],
    assignee_list: [],
    partner_id: 0,
    labels: [],
    tags_for_render: [],
    map_type: 'open_street',
    api_key: '',
    main_bounds_area: null,
    custom_bounds_area: null,
    date_range_default: '',
    error: null,
    icons_config: {},
    projects_to_workflows: {},
    workflow_items: {},
    selected_project: 0,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadPageData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/scheduling/maps--get-page-data',
                method: 'GET',
                success: (response) => {
                    commit(SET_SCHEDULING_MAP_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_SCHEDULING_MAP_INFO](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
    [SET_SCHEDULING_MAP_MARKERS](state, markers) {
        state.markers = markers;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
