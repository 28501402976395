<template>
    <div class="photoswipe-component">
        <photo-swipe
            v-if="isOpen"
            ref="slider"
            :is-open="isOpen"
            :items="items"
            :options="options"
            @close="hidePhotoSwipe"
        />
    </div>
</template>

<script>
import { PhotoSwipe } from 'v-photoswipe/src';

export default {
    name: 'XPhotoSwipe',
    components: {
        'photo-swipe': PhotoSwipe,
    },
    data() {
        return {
            isOpen: false,
            items: [],
            options: {
                index: 0,
                bgOpacity: 0.8,
                closeOnScroll: false,
                closeOnVerticalDrag: false,
                mouseUsed: true,
                history: false,
            },
            titleAttributes: {
                'pswp__button--close': this.$t('common', 'Close'),
                'pswp__button--share': this.$t('common', 'Share'),
                'pswp__button--fs': this.$t('common', 'Full Screen'),
                'pswp__button--zoom': this.$t('common', 'Zoom'),
                'pswp__button--arrow--left': this.$t('common', 'Previous'),
                'pswp__button--arrow--right': this.$t('common', 'Next'),
            },
        };
    },
    mounted() {
        this.options.shareButtons = this.shareButtons;
        window.$photoswiper = this;
    },
    methods: {
        setTitles(element) {
            const self = this;
            $(element).find('*[title]').each(function () {
                $(this).attr('title', self.titleAttributes[$(this).get(0).classList[1]]);
            });
        },
        getMeta(url) {
            return new Promise((resolve) => {
                const img = new Image();
                img.addEventListener('error', () => {
                    resolve(false);
                });
                img.addEventListener('load', function () {
                    resolve({
                        name: this.name,
                        width: this.naturalWidth,
                        height: this.naturalHeight,
                    });
                });
                img.src = url;
            });
        },
        getShareButtons() {
            const downloadButton = {
                id: 'download',
                label: this.$t('common', 'Download'),
                download: true,
                url: this.items[this.options.index].src,
            };
            const openInNewTabButton = {
                id: 'open_new_tab',
                label: this.$t('common', 'Open in new window'),
                url: this.items[this.options.index].src,
            };
            const downloadAllButton = {
                id: 'download_all',
                label: this.$t('common', 'Download All'),
                url: this.items[this.options.index].src,
            };

            return [
                openInNewTabButton,
                downloadButton,
                downloadAllButton,
            ];
        },
        downloadImagesList(list) {
            list.forEach((item) => {
                this.download(item.src, item.name);
            });
        },
        filterSizes(image) {
            if (image.width > window.innerWidth) {
                image.width = (window.innerWidth / 100) * 90;
            }
            if (image.height > window.innerHeight) {
                image.height = (window.innerHeight / 100) * 90;
            }
            return image;
        },
        async prepareImages(images) {
            const promises = images.map(async (image) => {
                if (!image.src) return null;

                if (image.type && image.type === 'iframe') {
                    return this.prepareIframe(image);
                }

                const data = await this.getMeta(image.src);
                if (!data) return null;

                const filteredData = this.filterSizes(data);
                return {
                    src: image.src, w: filteredData.width, h: filteredData.height, title: image.name,
                };
            });

            return (await Promise.all(promises)).filter(Boolean);
        },
        prepareIframe(file) {
            return {
                type: 'iframe',
                title: file.name,
                html: `<iframe
                    frameborder="0"
                    width="100%"
                    height="100%"
                    class="embed-responsive-item"
                    src="${file.src}"
                ></iframe>`,
            };
        },
        getNearestIndex(all, items, index) {
            let nearestImageIndex = index;
            all.forEach(() => {
                if (items[nearestImageIndex] && items[nearestImageIndex].src) {
                    return false;
                }
                nearestImageIndex -= 1;
                if (nearestImageIndex < 0) {
                    nearestImageIndex = items.length - 1;
                }
            });
            return nearestImageIndex;
        },
        showImages(items, index) {
            /**
             * Dependencies:
             *  - src - for displaying images(file.link)
             *  - name - for captions(file.name)
             *
             *  If the photoswiper component will display the document, you must pass the following parameters
             *
             *  - type - to determine what type of file we should open(file['data-pws-type'])
             */

            if (empty(items)) {
                return false;
            }
            this.prepareImages(items).then((images) => {
                if (!images[index]) {
                    index = this.getNearestIndex(items, images, index);
                }
                this.$set(this.options, 'index', index);
                this.items = images;
                // Open slider
                this.options.shareButtons = this.getShareButtons();
                this.isOpen = true;
                this.$nextTick(() => {
                    this.setTitles($(this.$refs.slider.$el));
                    this.prepareDownloadAll();
                });
            });
        },
        download(uri, name) {
            let link = document.createElement('a');
            link.download = name;
            link.href = uri;
            link.click();
        },
        prepareDownloadAll() {
            const selector = '.pswp__ui:not(.pswp__ui--hidden) .pswp__button--share';
            $(selector).off('click');
            $(selector).on('click', () => {
                const downloadSelector = '.pswp__ui:not(.pswp__ui--hidden) .pswp__share--download_all';
                $(downloadSelector).off('click');
                $(downloadSelector).on('click', (e) => {
                    e.preventDefault();
                    e.target.target = '';
                    e.target.href = '';
                    e.target.download = true;
                    this.downloadImagesList(this.items);
                });
            });
        },
        hidePhotoSwipe() {
            this.items = [];
            this.isOpen = false;
        },
    },
};
</script>
