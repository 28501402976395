import XTwigPage from '@/components/base/XTwigPage';

const StatisticsInternetReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/statistics-internet/StatisticsInternetReport');
const FinanceReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/finance-report/FinanceReport');
const UsageReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/usage-report/UsageReport');
const RefillCardsStatistics = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/refill-card-statistics/RefillCardsStatistics');
const MRRNetChangeReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/mrr-net-change-report/MRRNetChangeReport');
const BlockedReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/blocked-report/BlockedReport');
const FinanceLogsReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/finance-logs/FinanceLogsReport');
const StatementsMainReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/statements-main/StatementsMainReport');
const TaxReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/tax/TaxReport');
const InvoiceReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/invoice-report/InvoiceReport');
const CustomersTotalReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/customers-total-report/CustomersTotalReport');
const NewServicesReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/new-services-report/NewServicesReport');
const PricesAndDiscountReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/prices-and-discount/PricesAndDiscountReport');
const TransactionsCategoriesReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/transactions-categories/TransactionsCategoriesReport');
const TicketReports = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/ticket-reports/TicketReports');
const CustomerContracts = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/customer-contracts/CustomerContracts');
const FutureCharges = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/future-charges/FutureCharges');
const ChargeHistory = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/charge-history/ChargeHistory');
const DailyReceiptReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/daily-receipt/DailyReceiptReport');
const FinanceCustomersReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/finance-customers/FinanceCustomersReport');
const StatementsReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/statements/StatementsReport');
const HotspotExportReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/hotspot-export/HotspotExportReport');
const CustomersChart = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/customers-chart/CustomersChart');
const InventoryReport = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/inventory-report/InventoryReports');
const AccountStatusPreview = () => import(/* webpackChunkName: "reports" */ '@/apps/admin/views/admin/administration/reports/account-status-preview/AccountStatusPreview');

export default [
    {
        path: 'statistics-internet',
        name: 'admin.administration.reports.statistics_internet',
        component: StatisticsInternetReport,
        meta: {
            keepAlive: true,
            componentName: 'StatisticsInternetReport',
        },
    },
    {
        path: 'services-export',
        name: 'admin.administration.reports.services_export',
        component: XTwigPage,
    },
    {
        path: 'finance-report',
        name: 'admin.administration.reports.finance_report',
        component: FinanceReport,
        meta: {
            keepAlive: true,
            componentName: 'FinanceReport',
        },
    },
    {
        path: 'usage-report',
        name: 'admin.administration.reports.usage_report',
        component: UsageReport,
        meta: {
            keepAlive: true,
            componentName: 'UsageReport',
        },
    },
    {
        path: 'refill-card-statistics',
        name: 'admin.administration.reports.refill_card_statistics',
        component: RefillCardsStatistics,
        meta: {
            keepAlive: true,
            componentName: 'RefillCardsStatistics',
        },
    },
    {
        path: 'mrr-net-change-report',
        name: 'admin.administration.reports.mrr_net_change_report',
        component: MRRNetChangeReport,
        meta: {
            keepAlive: true,
            componentName: 'MRRNetChangeReport',
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Reports'], url: '/admin/administration#reports' },
                {
                    title: ['administration', 'Monthly recurring revenue net change report'],
                    url: '/admin/administration/reports/mrr-net-change-report',
                },
            ],
            title: ['administration', 'Monthly recurring revenue net change report'],
        },
    },
    {
        path: 'blocked-report',
        name: 'admin.administration.reports.blocked_report',
        component: BlockedReport,
        meta: {
            keepAlive: true,
            componentName: 'BlockedReport',
        },
    },
    {
        path: 'finance-logs',
        name: 'admin.administration.reports.finance_logs',
        component: FinanceLogsReport,
        meta: {
            keepAlive: true,
            componentName: 'FinanceLogsReport',
        },
    },
    {
        path: 'statements-main',
        name: 'admin.administration.reports.statements_main',
        component: StatementsMainReport,
        meta: {
            keepAlive: true,
            componentName: 'StatementsMainReport',
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Featured reports'], url: '/admin/administration#featured-reports' },
                {
                    title: ['administration', 'Account statements'],
                    url: '/admin/administration/reports/statements-main',
                },
            ],
            title: ['administration', 'Account statements'],
        },
    },
    {
        path: 'tax',
        name: 'admin.administration.reports.tax',
        component: TaxReport,
        meta: {
            keepAlive: true,
            componentName: 'TaxReport',
        },
    },
    {
        path: 'invoice-report',
        name: 'admin.administration.reports.invoice_report',
        component: InvoiceReport,
        meta: {
            keepAlive: true,
            componentName: 'InvoiceReport',
        },
    },
    {
        path: 'customers-total-report',
        name: 'admin.administration.reports.customers_total_report',
        component: CustomersTotalReport,
        meta: {
            keepAlive: true,
            componentName: 'CustomersTotalReport',
        },
    },
    {
        path: 'new-services-report',
        name: 'admin.administration.reports.new_services_report',
        component: NewServicesReport,
        meta: {
            keepAlive: false,
            componentName: 'NewServicesReport',
            title: ['administration', 'New services'],
            breadcrumbs: [
                {
                    title: ['administration', 'Administration'],
                    url: '/admin/administration',
                },
                {
                    title: ['administration', 'Featured reports'],
                    url: '/admin/administration#featured-reports',
                },
                {
                    title: ['administration', 'New services'],
                    url: '#',
                },
            ],
        },
    },
    {
        path: 'custom-prices-and-discount-report',
        name: 'admin.administration.reports.prices_and_discounts',
        component: PricesAndDiscountReport,
        meta: {
            keepAlive: true,
            componentName: 'PricesAndDiscountReport',
        },
    },
    {
        path: 'transactions-categories',
        name: 'admin.administration.reports.transactions_categories',
        component: TransactionsCategoriesReport,
        meta: {
            keepAlive: true,
            componentName: 'TransactionsCategoriesRe',
        },
    },
    {
        path: 'ticket-reports',
        name: 'admin.administration.reports.ticket_reports',
        component: TicketReports,
        meta: {
            keepAlive: true,
            componentName: 'TicketReports',
        },
    },
    {
        path: 'customer-contracts',
        name: 'admin.administration.reports.customer_contracts',
        component: CustomerContracts,
        meta: {
            keepAlive: true,
            componentName: 'CustomerContracts',
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Other reports'], url: '/admin/administration#other-reports' },
                {
                    title: ['administration', 'Customer contracts reports'],
                    url: '/admin/administration/reports/customer-contracts',
                },
            ],
            title: ['administration', 'Customer contracts reports'],
        },
    },
    {
        path: 'future-charges',
        name: 'admin.administration.reports.future_charges',
        component: FutureCharges,
        meta: {
            keepAlive: true,
            componentName: 'FutureCharges',
        },
    },
    {
        path: 'charge-history',
        name: 'admin.administration.reports.charge_history',
        component: ChargeHistory,
        meta: {
            keepAlive: true,
            componentName: 'ChargeHistory',
        },
    },
    {
        path: 'daily-receipt',
        name: 'admin.administration.reports.daily_receipt',
        component: DailyReceiptReport,
        meta: {
            keepAlive: true,
            componentName: 'DailyReceiptReport',
        },
    },
    {
        path: 'finance-customers-report',
        name: 'admin.administration.reports.finance_customers_report',
        component: FinanceCustomersReport,
        meta: {
            keepAlive: true,
            componentName: 'FinanceCustomersReport',
        },
    },
    {
        path: 'statements',
        name: 'admin.administration.reports.statements',
        component: StatementsReport,
        meta: {
            keepAlive: true,
            componentName: 'StatementsReport',
        },
    },
    {
        path: 'tax-total-report',
        name: 'admin.administration.reports.tax_total_report',
        component: XTwigPage,
    },
    {
        path: 'tax-report',
        name: 'admin.administration.reports.tax_report',
        component: XTwigPage,
    },
    {
        path: 'hotspot-export',
        name: 'admin.administration.reports.hotspot_export',
        component: HotspotExportReport,
        meta: {
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Other reports'], url: '/admin/administration#other-reports' },
                { title: ['administration', 'Hotspot export'], url: '/admin/administration/reports/hotspot-export' },
            ],
            title: ['administration', 'Hotspot export'],
        },
    },
    {
        path: 'customers-chart',
        name: 'admin.administration.reports.customers_chart',
        component: CustomersChart,
        meta: {
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Other reports'], url: '/admin/administration#other-reports' },
                {
                    title: ['administration', 'Customer status chart'],
                    url: '/admin/administration/reports/customers-chart',
                },
            ],
            title: ['administration', 'Customer status chart'],
        },
    },
    {
        path: 'account-status-preview',
        name: 'admin.administration.reports.account_status_preview',
        component: AccountStatusPreview,
        meta: {
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Other reports'], url: '/admin/administration#other-reports' },
                { title: ['administration', 'Account status preview'], url: '/admin/administration/reports/account-status-preview' },
            ],
            title: ['administration', 'Account status preview'],
        },
    },
    {
        path: 'inventory',
        name: 'admin.administration.reports.inventory',
        component: InventoryReport,
        meta: {
            breadcrumbs: [
                { title: ['administration', 'Administration'], url: '/admin/administration' },
                { title: ['administration', 'Other reports'], url: '/admin/administration#other-reports' },
                { title: ['administration', 'Inventory reports'], url: '/admin/administration/reports/inventory' },
            ],
            title: ['administration', 'Inventory report'],
            controller: 'controllers\\admin\\administration\\reports\\InventoryController',
        },
    },
];
