import IDBHelper from './IDBHelper.js';

export default class TabSingletonExecutor {
    constructor(lockKey = 'default-task') {
        this.lockKey = lockKey;
        this.tabId = `${Date.now()}-${crypto.randomUUID()}`;
        this.db = new IDBHelper('splynx-db', 1, ['locks']);
    }

    async tryExecute(callback) {
        try {
            await this.db.ready;
            const existingLock = await this.db.get('locks', this.lockKey);
            if (existingLock) {
                return false;
            }
            const lock = {
                id: this.lockKey,
                tabId: this.tabId,
                timestamp: Date.now(),
            };
            await this.db.add('locks', lock);
            await callback();
            await this.db.delete('locks', this.lockKey);
            return true;
        } catch (err) {
            return false;
        } finally {
            await this.db.delete('locks', this.lockKey);
        }
    }

    destroy() {
        this.db?.close?.();
    }
}
