<script>
import { isset } from '@/splang/utils';
import { debounce, empty } from '@/utils/functions';
import { mapActions, mapGetters } from 'vuex';

const SESSION_TABS_REFRESH = 'tabs_refresh';
const SESSION_TABS_STATE = 'tabs_state';

const DIRECTION_HORIZONTAL = 'horizontal';
// const DIRECTION_VERTICAL = 'vertical';

export default {
    name: 'XTabs',
    provide() {
        return {
            tabs: this,
        };
    },
    props: {
        /**
         * vertical|horizontal
         */
        direction: {
            type: String,
            default: DIRECTION_HORIZONTAL,
        },
        value: [String, Number, Object],
        id: String,
        /* Always remind tabs content state */
        cacheTabs: {
            type: Boolean,
            default: false,
        },
        /* Always remind tabs state */
        persistent: {
            type: Boolean,
            default: true,
        },
        /**
         * light, light-gray
         */
        mode: {
            type: String,
            default: 'light',
        },
        activateTabByHash: {
            type: Boolean,
            default: true,
        },
        activateTabByStore: {
            type: Boolean,
            default: true,
        },
        withoutCheckUnsavedData: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeTabIndex: 0,
            tabs: [],
            refreshTimer: null,
            dropdownState: false,
        };
    },
    beforeCreate() {
        window.addEventListener('beforeunload', this.handleRefreshPage);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleRefreshPage);
        window.removeEventListener('resize', this.handleWindowResize);
    },
    created() {
        let savedTabIndex = this.getInitialTab();
        if (savedTabIndex !== null) {
            this.activateTab(savedTabIndex);
        }

        if (this.cacheTabs) {
            const promises = [];
            this.$nextTick(() => {
                this.$refs.tablist.classList.add('tab-light-loading');
                this.tabs.forEach((tab) => {
                    promises.push(new Promise((resolve) => {
                        this.waitForElement(tab).then((loadedTab) => {
                            if (tab.active) {
                                loadedTab.classList.remove('x-tab-cache-hidden');
                                loadedTab.classList.add('x-tab-cache-visible');
                            } else {
                                loadedTab.classList.remove('x-tab-cache-visible');
                                loadedTab.classList.add('x-tab-cache-hidden');
                            }
                            resolve();
                        });
                    }));
                });
            });
            Promise.all(promises).then(debounce(() => {
                this.$refs.tablist.classList.remove('tab-light-loading');
            }, 500));
        }
    },
    mounted() {
        $(window).resize(this.handleWindowResize);

        this.toggleTabFromStore();

        if (this.activateTabByHash && window.location.hash.substring(1)) {
            this.findTabAndActivate(window.location.hash.substring(1));
        }
    },
    methods: {
        ...mapActions('tabs_store', ['clearTab']),
        waitForElement(tab) {
            return new Promise((resolve) => {
                const wait = () => {
                    setTimeout(() => {
                        if (tab.$el.nodeType !== 8) {
                            resolve(tab.$el);
                        } else {
                            wait(tab.$el);
                        }
                    }, 50);
                };
                if (tab.$el.nodeType !== 8) {
                    resolve(tab.$el);
                } else {
                    wait();
                }
            });
        },
        reloadTab() {
            if (this.activeTabIndex < this.tabs.length && this.activeTabIndex >= 0) {
                let activeTab = this.tabs[this.activeTabIndex];
                activeTab.refresh();
            }
        },
        navigateToTab(index, route) {
            if (this.withoutCheckUnsavedData) {
                this.changeTab(this.activeTabIndex, index, route);
                return;
            }
            checkUnsavedDataBeforeLeaveTab(this.$el, () => {
                this.changeTab(this.activeTabIndex, index, route);
            });
        },
        activateTab(index) {
            this.activeTabIndex = index;
            if (this.tabs.length > 0) {
                let tab = this.tabs[index];
                tab.active = true;
                this.$emit('input', tab.id);
            }
        },
        changeTab(oldIndex, newIndex, route) {
            let oldTab = this.tabs[oldIndex] || {};
            let newTab = this.tabs[newIndex];
            if (newTab.disabled) return;
            this.activeTabIndex = newIndex;
            oldTab.active = false;
            newTab.active = true;

            this.saveTabsState(newTab, newIndex);

            if (newIndex === oldIndex) {
                return false;
            }

            if (this.cacheTabs) {
                this.waitForElement(newTab).then((loadedTab) => {
                    loadedTab.classList.remove('x-tab-cache-hidden');
                    loadedTab.classList.add('x-tab-cache-visible');
                    oldTab.$el.classList.remove('x-tab-cache-visible');
                    oldTab.$el.classList.add('x-tab-cache-hidden');
                });
            }

            this.$emit('input', this.tabs[newIndex].id);
            this.$emit('tab-change', newIndex, newTab, oldTab);
            splynx_event_bus.emit('x-tab-change', {
                id: this.id,
                newIndex,
                newTab,
                oldTab,
                newTabId: newTab.$options?.propsData.id,
                oldTabId: oldTab.$options?.propsData.id,
            });
            this.tryChangeRoute(route);
        },
        tryChangeRoute(route) {
            if (this.$router && route) {
                this.$router.push(route);
            }
        },
        addTab(item) {
            let index = this.$slots.default.indexOf(item.$vnode);
            if (index === -1) {
                index = this.tabs.length;
            }
            this.tabs.splice(index, 0, item);
        },
        removeTab(item) {
            const { tabs } = this;
            const index = tabs.indexOf(item);
            if (index > -1) {
                tabs.splice(index, 1);
            }
        },
        getTabs() {
            if (this.$slots.default) {
                return this.$slots.default.filter((comp) => comp.componentOptions);
            }
            return [];
        },
        findTabAndActivate(tabNameOrIndex) {
            let indexToActivate = this.tabs.findIndex((tab, index) => tab.title === tabNameOrIndex || tab.id === tabNameOrIndex || index === tabNameOrIndex);
            // if somehow activeTabIndex is not reflected in the actual vue-tab instance, set it.
            if (indexToActivate === this.activeTabIndex && !this.tabs[this.activeTabIndex].active) {
                this.tabs[this.activeTabIndex].active = true;
            }
            if (indexToActivate !== -1) {
                this.changeTab(this.activeTabIndex, indexToActivate);
            } else {
                this.changeTab(this.activeTabIndex, 0);
            }
        },
        handleRefreshPage() {
            if (this.refreshTimer !== null) {
                clearTimeout(this.refreshTimer);
            }
            this.setToStorage(SESSION_TABS_REFRESH, '1');
        },
        getInitialTab() {
            let tabsState = this.getFromStorage(SESSION_TABS_STATE);

            if (tabsState !== null && tabsState !== undefined) {
                tabsState = JSON.parse(tabsState);

                let isRefresh = this.getFromStorage(SESSION_TABS_REFRESH);
                isRefresh = isRefresh === '1';

                // Restore state only after refresh or always for persistent tabs (with data-persistent-state attribute)
                if (isRefresh || this.persistent) {
                    if (isset(tabsState, this.id)) {
                        return tabsState[this.id];
                    }
                }
                this.clearTabsRefresh();
            }

            return null;
        },
        clearTabsRefresh() {
            if (this.refreshTimer !== null) {
                clearTimeout(this.refreshTimer);
            }
            this.refreshTimer = setTimeout(() => {
                this.setToStorage(SESSION_TABS_REFRESH, '0');
            }, 5000);
        },
        saveTabsState(tab, index) {
            if (!this.persistent) {
                return false;
            }
            let tabsState = this.getFromStorage(SESSION_TABS_STATE);
            tabsState = tabsState === null || tabsState === undefined ? {} : JSON.parse(tabsState);
            tabsState[this.id] = index;

            this.setToStorage(SESSION_TABS_STATE, JSON.stringify(tabsState));
        },
        setToStorage(key, value) {
            localStorage.setItem(key, value);
        },
        getFromStorage(key) {
            return localStorage.getItem(key);
        },
        handleWindowResize() {
            this.$forceUpdate();
        },
        renderTabTitle(h, index) {
            if (this.tabs.length === 0) return;
            let tab = this.tabs[index];
            let { active, title } = tab;

            if (tab.$slots.title) return tab.$slots.title;
            if (tab.$scopedSlots.title) {
                return tab.$scopedSlots.title({
                    active,
                    title,
                });
            }

            return title;
        },
        renderTabs(h) {
            let list = [];
            let dropdownList = [];
            const itemWidth = 100;
            let wrapper;
            let availableWidth = 800;
            if (!empty(this.$parent.$el)) {
                if (this.$parent.$el.nodeType === Node.COMMENT_NODE) {
                    wrapper = $(this.$parent.$el).parent();
                } else {
                    wrapper = $(this.$parent.$el);
                }
                availableWidth = wrapper.width() - 38; // 38 - width of ... button
            }

            let showDropdown = false;
            let activeInDropdown = false;

            this.tabs.forEach((tab, index) => {
                if (!tab) return;
                let {
                    route, className, title, tabId,
                } = tab;
                let active = this.activeTabIndex === index;
                className = className !== undefined ? className : '';

                if (className == 'billing-help' && window.innerWidth < 550) {
                    return;
                }

                if (this.direction === DIRECTION_HORIZONTAL && availableWidth < itemWidth) {
                    showDropdown = true;
                    // Render to dropdown

                    if (active) {
                        activeInDropdown = true;
                    }

                    dropdownList.push(h('a', {
                        class: ['dropdown-item', { active, 'active-tab-item': active }],
                        key: title,
                        attrs: {
                            name: 'tab',
                            id: `t-${tabId}`,
                            'aria-selected': active,
                            'aria-controls': `p-${tabId}`,
                            role: 'tab',
                            href: this.getTabUrl(tab),
                        },
                        on: {
                            click: (e) => {
                                tab.$emit('click', e);
                                e.preventDefault();
                                this.navigateToTab(index, route);
                            },
                        },
                    }, [
                        tab.counter?.value ? h('span', { class: `badge x-tab-counter ${tab.counter.color ? `bg-${tab.counter.color}` : ''}` }, `${tab.counter.value}`) : null,
                        h('a', {
                            class: [{ active_tab: active }, 'tabs__link'],
                            attrs: {
                                role: 'tab',
                                href: this.getTabUrl(tab),
                            },
                        }, [
                            this.renderTabTitle(h, index),
                        ]),
                    ]));
                    return;
                }

                availableWidth -= itemWidth;
                list.push(h('li', {
                    name: 'tab',
                    class: ['x-tab', { active, 'active-tab-item': active }, className],
                    key: title,
                    attrs: {
                        id: `t-${tabId}`,
                    },
                    'aria-selected': active,
                    'aria-controls': `p-${tabId}`,
                    role: 'tab',
                    on: {
                        click: (e) => {
                            if (tab.canOpen) {
                                tab.$emit('click', e);
                                e.preventDefault();
                                this.dropdownState = false;
                                this.navigateToTab(index, route);
                            }
                        },
                    },
                }, [
                    tab.counter?.value ? h('span', { class: `badge x-tab-counter ${tab.counter.color ? `bg-${tab.counter.color}` : ''}` }, `${tab.counter.value}`) : null,
                    h('a', {
                        class: [{ active_tab: active }, 'tabs__link'],
                        role: 'tab',
                        attrs: {
                            href: this.getTabUrl(tab),
                        },
                    }, [
                        this.renderTabTitle(h, index),
                    ]),
                ]));
            });
            if (showDropdown) {
                list.push(h('li', {
                    class: ['x-tab', 'dropdown', 'x-tab-more', {
                        active: activeInDropdown,
                        'active-tab-item': activeInDropdown,
                    }],
                    attrs: {
                        tabindex: '1',
                    },
                    on: {
                        click: () => {
                            this.dropdownState = !this.dropdownState;
                        },
                        focusout: (event) => {
                            if (!event.relatedTarget || event.relatedTarget.classList.contains('dropdown')) {
                                this.dropdownState = false;
                            }
                        },
                    },
                }, [
                    h('span', {
                        class: 'dropdown-toggle x-tab-dropdown',
                        attrs: {
                            id: 'dropdownTabsButton',
                            'data-toggle': 'dropdown',
                            'aria-expanded': false,
                            'aria-haspopup': true,
                            role: 'button',
                        },
                    }, '...'),
                    h('div', {
                        class: 'dropdown-menu dropdown-menu-right dropdown-menu-tab',
                        attrs: {
                            'aria-labelledby': 'dropdownTabsButton',
                            tabIndex: '1',
                        },
                    }, dropdownList),
                ]));
            }

            return list;
        },
        getTabUrl(tab) {
            if (!tab.canOpen) {
                return false;
            }
            return `${empty(tab.$route.hash) ? tab.$route.fullPath : tab.$route.fullPath.replace(tab.$route.hash, '')}#${tab.id}`;
        },
        toggleTabFromStore() {
            if (!empty(this.activeTab) && this.activateTabByStore) {
                this.findTabAndActivate(this.activeTab);
            }
        },
    },
    computed: {
        classList() {
            return ['x-tabs-bar', `mode-${this.mode}`];
        },
        ...mapGetters('tabs_store', ['activeTab']),
    },
    watch: {
        tabs(newList) {
            if (newList.length === 0) {
                return;
            }
            if (!this.value) {
                if (newList.length <= this.activeTabIndex) {
                    // Open last tab
                    this.activateTab(newList.length - 1);
                } else {
                    this.activateTab(this.activeTabIndex);
                }
            } else {
                this.findTabAndActivate(this.value);
            }
        },
        value(newVal) {
            this.findTabAndActivate(newVal);
        },
        dropdownState(newState) {
            const dropdown = $(`#${this.id} > .x-tabs-bar`).find('.x-tab-dropdown');
            if (newState) {
                dropdown.dropdown('show');
            } else {
                dropdown.dropdown('hide');
            }
        },
        activeTab() {
            this.toggleTabFromStore();
        },
    },
    render(h) {
        const tabList = this.renderTabs(h);

        return h('div', {
            class: ['x-tabs', `x-tabs-${this.direction}`],
            attrs: {
                id: this.id,
            },
        }, [
            h('ul', { class: this.classList, role: 'tablist' }, tabList),
            h('div', { ref: 'tablist', class: ['x-tabs-item', 'x-tab-content'] }, [
                this.$slots.default,
            ]),
        ]);
    },
};
</script>
