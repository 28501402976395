import { render, staticRenderFns } from "./ChatBoxChatView.vue?vue&type=template&id=0dd401ee"
import script from "./ChatBoxChatView.vue?vue&type=script&lang=js"
export * from "./ChatBoxChatView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports