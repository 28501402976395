<template>
    <div class="input-wrap">
        <select
            ref="input"
            v-bind="attributes"
        />
    </div>
</template>

<script>
import XSelect from '@/components/common/inputs/XSelect';

export default {
    name: 'XSelectSearch',
    extends: XSelect,
    inheritAttrs: false,
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('common', 'Search');
            },
        },
        searchKey: {
            type: String,
            default: 'search',
        },
        ajaxUrl: String,
        ajaxData: {
            type: Object,
            default: () => {},
        },
        customConfig: {
            type: Object,
            default: () => {},
        },
        defaultDataResponse: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        reInit() {
            let config = {};
            let defaultConfig = this.ajaxConfig.config;
            $.extend(true, config, defaultConfig);
            this.initHooks(config);
        },
        responseDataWithChildren(data) {
            return data.results.map((field) => ({
                text: field.text,
                disabled: field.disabled,
                children: field.children ? field.children.map((child) => ({
                    id: child.id,
                    text: child.text,
                    disabled: child.disabled,
                })) : [],
            }));
        },
    },
    computed: {
        ajaxConfig() {
            return {
                config: {
                    data: this.data,
                    ajax: {
                        url: this.ajaxUrl,
                        delay: 250,
                        data: (params) => ({
                            [this.searchKey]: params.term,
                            ...this.ajaxData,
                        }),
                        processResults: (data) => ({
                            results: this.defaultDataResponse ? data.data : this.responseDataWithChildren(data),
                        }),
                        cache: true,
                    },
                    allowClear: true,
                    placeholder: this.placeholder,
                    minimumInputLength: 2,
                    ...this.customConfig,
                },
                options: this.options,
            };
        },
    },
};
</script>
