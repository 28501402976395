export default class MessageStatus {
    static colors = {
        DEFAULT: '#6C757D',
        FAILED: '#EB5757',
        READ: '#357bf2',
    };

    static icons = {
        QUEUE: 'icon-ic_fluent_clock_24_regular',
        SENT: 'icon-ic_fluent_checkmark_24_regular',
        DELIVERED: 'icon-ic_fluent_checkmark_24_regular',
        READ: 'icon-ic_fluent_checkmark_24_regular',
        FAILED: 'icon-ic_fluent_error_circle_24_regular',
    };

    static QUEUE = {
        key: 'queue',
        icon: MessageStatus.icons.QUEUE,
        color: MessageStatus.colors.DEFAULT,
        double: false,
    };

    static SENT = {
        key: 'sent',
        icon: MessageStatus.icons.SENT,
        color: MessageStatus.colors.DEFAULT,
        double: false,
    };

    static DELIVERED = {
        key: 'delivered',
        icon: MessageStatus.icons.DELIVERED,
        color: MessageStatus.colors.DEFAULT,
        double: true,
    };

    static READ = {
        key: 'read',
        icon: MessageStatus.icons.READ,
        color: MessageStatus.colors.READ,
        double: true,
    };

    static FAILED = {
        key: 'failed',
        icon: MessageStatus.icons.FAILED,
        color: MessageStatus.colors.FAILED,
        double: false,
    };

    static get(status) {
        return Object.keys(MessageStatus).map((key) => MessageStatus[key]).find((property) => property.key === status);
    }
}
