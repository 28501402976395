<template>
    <div class="content clearfix active">
        <div class="splynx-top-nav">
            <x-breadcrumbs :items="$route.meta.breadcrumbs" />
        </div>

        <x-preloader-block :loading="loading">
            <div class="spl-page-panel splynx-config-panel">
                <div
                    id="admin_config_tools_reload_search_engine"
                    class="main-data-table-holder pt-40"
                >
                    <div class="text-center">
                        <p
                            data-test-selector="reload-description"
                            class="color-secondary mb-12"
                        >
                            <x-translate
                                tag="false"
                                category="config"
                                message="Click this button to reload the search engine if the search is {br} not functioning properly."
                            >
                                <template #br>
                                    <br>
                                </template>
                            </x-translate>
                        </p>
                        <div class="mb-24">
                            <button
                                data-test-selector="reset-button"
                                class="btn btn-outline-primary me-24"
                                :disabled="!showProcess || disabledResetButton"
                                @click="reset"
                            >
                                {{ $t('config', 'Reset') }}
                            </button>
                            <button
                                data-test-selector="reload-button"
                                class="btn btn-primary"
                                :disabled="showProcess"
                                @click="showProcessToggle"
                            >
                                {{ $t('config', 'Reload search') }}
                            </button>
                        </div>
                    </div>

                    <div
                        class="card-block mx-16"
                        data-test-selector="reload-process"
                    >
                        <div class="card-block-heading">
                            <h3>{{ $t('config', 'Reload process') }} </h3>
                        </div>
                        <div class="card-block-body">
                            <div class="form-horizontal">
                                <pre
                                    id="admin_config_tools_reload_search_engine_log"
                                    ref="logContainer"
                                    data-test-selector="reload-process-log"
                                    class="pre-scrollable full-height"
                                    v-html="log"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </x-preloader-block>
    </div>
</template>

<script>
import { XPreloaderBlock } from '@/components/common/XPreloaderBlock';
import { XBreadcrumbs } from '@/components/base/XBreadcrumbs';
import ReloadSearchEngineService from '@/apps/admin/services/config/tools/reload-search-engine/ReloadSearchEngine';
import { formattedResponse } from '@/utils/functions';

const DISABLED_RESET_BUTTON_KEY = 'disable-reset-button-end-time';

export default {
    name: 'ReloadSearchEngine',
    data() {
        return {
            loading: true,
            isRunning: false,
            log: '',
            loadInterval: null,
            disabledResetButton: false,
        };
    },
    mounted() {
        this.loadData();
        this.checkDisableResetButton();
    },
    methods: {
        async loadData() {
            const data = formattedResponse(await ReloadSearchEngineService.getPageData());
            this.setData(data);
            this.loading = false;
        },
        async reload() {
            const { result, message } = formattedResponse(await ReloadSearchEngineService.reloadEngine());
            this.isRunning = result;
            this.setLoadInterval();

            if (message) {
                if (result) {
                    this.$showSuccess(message, 4);
                } else {
                    this.$showWarning(message, 4);
                }
            }
        },
        async reset() {
            const { result, message } = formattedResponse(await ReloadSearchEngineService.resetEngine());

            if (result) {
                this.setLoadInterval();
            }

            if (message) {
                if (result) {
                    this.$showSuccess(message, 4);
                } else {
                    this.$showWarning(message, 4);
                }
            }

            this.addDisableResetButtonTimer();
        },
        setData({ isRunning, log }) {
            this.isRunning = isRunning;
            this.log = log;

            if (isRunning) {
                this.setLoadInterval();
            } else {
                clearInterval(this.loadInterval);
            }
        },
        async showProcessToggle() {
            await this.reload();
        },
        scrollToBottom() {
            const resultContainer = this.$refs.logContainer;
            resultContainer.scrollTo({
                top: resultContainer.scrollHeight,
            });
        },
        setLoadInterval() {
            clearInterval(this.loadInterval);
            this.loadInterval = setInterval(this.loadData, 10000);
        },
        addDisableResetButtonTimer() {
            const disableEndTime = Date.now() + 60000;
            localStorage.setItem(DISABLED_RESET_BUTTON_KEY, disableEndTime.toString());
            this.disabledResetButton = true;
            this.startDisableResetButtonTimer();
        },
        startDisableResetButtonTimer() {
            const interval = setInterval(() => {
                const disableEndTime = parseInt(localStorage.getItem(DISABLED_RESET_BUTTON_KEY) || '0', 10);
                if (Date.now() >= disableEndTime) {
                    clearInterval(interval);
                    localStorage.removeItem(DISABLED_RESET_BUTTON_KEY);
                    this.disabledResetButton = false;
                }
            }, 1000);
        },
        checkDisableResetButton() {
            const disableEndTime = parseInt(localStorage.getItem(DISABLED_RESET_BUTTON_KEY) || '0', 10);
            if (Date.now() < disableEndTime) {
                this.disabledResetButton = true;
                this.startDisableResetButtonTimer();
            }
        },
    },
    computed: {
        showProcess() {
            return this.isRunning;
        },
    },
    watch: {
        log() {
            this.$nextTick(() => {
                this.scrollToBottom();
            });
        },
    },
    components: {
        XPreloaderBlock,
        XBreadcrumbs,
    },
};
</script>
