<template>
    <div class="splynx-breadcrumbs">
        <span
            v-if="icon"
            class="btn-icon-lg color-white me-12"
            :class="'bg-'+color"
        ><i :class="icon" /></span>
        <ul>
            <li
                v-for="(item, index) in items"
                :key="index"
            >
                <a
                    :href="item.url"
                    @click.prevent="handleClick(item, index)"
                >{{ renderTitle(item.title) }}</a>
                <slot
                    v-if="items.length === index + 1"
                    name="label"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { REFRESH_ROUTE } from '@/apps/admin/store/mutation-types';

export default {
    name: 'XBreadcrumbs',
    props: {
        items: Array,
    },
    data() {
        return {
            icon: this.$route.meta.icon,
            color: this.$route.meta.color,
        };
    },
    methods: {
        ...mapMutations([REFRESH_ROUTE]),
        handleClick(breadcrumb) {
            if (this.isNeedRefresh(breadcrumb)) {
                this.REFRESH_ROUTE();
                return;
            }

            if (breadcrumb.url && breadcrumb.url !== '#' && breadcrumb.url !== this.$route.path) {
                this.$router.push(breadcrumb.url);
            }
        },
        renderTitle(title) {
            if (typeof title === 'string') {
                return title;
            }

            if (Array.isArray(title)) {
                return this.$t(title[0], title[1], title.length === 3 ? title[2] : null);
            }

            return title;
        },
        isNeedRefresh(breadcrumb) {
            return breadcrumb.url === this.$route.path
                    || breadcrumb.url === '#'
                    || empty(breadcrumb.url);
        },
        isLastItem(index) {
            return this.items.length - 1 === index;
        },
    },
};
</script>
