import XTwigPage from '@/components/base/XTwigPage';

const TicketRecipientsList = () => import(/* webpackChunkName: "tickets" */ '@/apps/admin/views/admin/tickets/TicketRecipientsList');
const TicketsDashboard = () => import(/* webpackChunkName: "tickets" */ '@/apps/admin/views/admin/tickets/TicketsDashboard');
const OpenedTicketsList = () => import(/* webpackChunkName: "tickets" */ '@/apps/admin/views/admin/tickets/OpenedTicketsList');
const TrashTicketsList = () => import(/* webpackChunkName: "closed_tickets_list" */ '@/apps/admin/views/admin/tickets/TrashTicketsList');

export default [
    {
        path: 'dashboard',
        name: 'admin.tickets.dashboard',
        component: TicketsDashboard,
        meta: {
            componentName: 'TicketsDashboard',
        },
    },
    {
        path: 'create',
        name: 'admin.tickets.create',
        component: XTwigPage,
    },
    {
        path: 'opened',
        name: 'admin.tickets.opened',
        component: OpenedTicketsList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tickets'], url: '/admin/tickets/opened' },
                { title: ['support', 'List'], url: '/admin/tickets/opened' },
            ],
            title: ['support', 'List'],
            keepAlive: true,
            componentName: 'OpenedTicketsList',
        },
    },
    {
        path: 'opened--view',
        name: 'admin.tickets.opened.view',
        component: XTwigPage,
    },
    {
        path: 'closed--view',
        name: 'admin.tickets.closed.view',
        component: XTwigPage,
    },
    {
        path: 'trash',
        name: 'admin.tickets.trash',
        component: TrashTicketsList,
        meta: {
            breadcrumbs: [
                { title: ['common', 'Tickets'], url: '/admin/tickets/opened' },
                { title: ['support', 'Archive'], url: '/admin/tickets/trash' },
            ],
            title: ['support', 'Archive'],
            keepAlive: true,
            componentName: 'TrashTicketsList',
        },
    },
    {
        path: 'trash--view',
        name: 'admin.tickets.trash.view',
        component: XTwigPage,
    },
    {
        path: 'requesters',
        name: 'admin.tickets.requesters',
        component: TicketRecipientsList,
        meta: {
            keepAlive: true,
            componentName: 'TicketRecipientsList',
        },
    },
];
