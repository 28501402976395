<template>
    <div class="chat-box-header">
        <h4>{{ $t('messengers', 'Chats') }}</h4>
        <div class="chat-box-actions">
            <a
                class="chat-box-action"
                data-test-selector="create-chat"
                href="javascript:void(0)"
                :title="$t('messengers', 'Create chat')"
                @click="$emit('create')"
            >
                <i class="icon-ic_fluent_add_circle_24_regular" />
            </a>
            <a
                class="chat-box-action"
                href="javascript:void(0)"
                :title="$t('messengers', 'Quick access')"
            >
                <i
                    class="icon-ic_fluent_filter_24_regular"
                    @click="onFilterClick"
                />
                <x-complex-input
                    ref="select"
                    :value="filter"
                    data-test-selector="filter-input"
                    type="multiple-select"
                    :params="{
                        options: {
                            id: 'admin_messengers_chatbox_filter_assignee',
                            options: {
                                '0': $t('messengers', 'Assigned to me'),
                                '1': $t('messengers', 'Unassigned'),
                            },
                        },
                    }"
                    @close="filterOpened = false"
                    @open="filterOpened = true"
                    @input="onInput"
                />
            </a>
            <a
                class="chat-box-action"
                data-test-selector="sound-notifications-active-state"
                href="javascript:void(0)"
                :title="soundNotificationsActive ? $t('messengers', 'Mute notifications') : $t('messengers', 'Unmute notifications')"
                @click="toggleSoundNotifications"
            >
                <i :class="[soundNotificationsActive ? 'icon-ic_fluent_speaker_2_24_regular' : 'icon-ic_fluent_speaker_off_24_regular']" />
            </a>
            <a
                class="chat-box-action"
                href="javascript:void(0)"
                :title="$t('messengers', 'Minimize')"
                @click="$emit('close')"
            >
                <i class="icon-ic_fluent_line_horizontal_1_20_regular" />
            </a>
        </div>
    </div>
</template>

<script>
import { XComplexInput } from '@/components/common/inputs/XComplexInput';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ChatBoxHeader',
    data() {
        return {
            filterOpened: false,
        };
    },
    mounted() {
        setTimeout(() => {
            $('#admin_messengers_chatbox_filter_assignee')
                .attr('style', 'display: none !important');
            $('#admin_messengers_chatbox_filter_assignee + div > .ms-choice')
                .attr('style', 'display: none !important');
            $('.ms-select-all').hide();
            $('.ms-search').hide();
        }, 100);
    },
    methods: {
        ...mapActions('chatbox', [
            'setChatBoxFilter',
            'setChatBoxSoundNotificationsActive',
        ]),
        toggleSoundNotifications() {
            this.setChatBoxSoundNotificationsActive(!this.soundNotificationsActive);
        },
        onInput(value) {
            this.setChatBoxFilter(value);
        },
        onFilterClick() {
            if (this.filterOpened) {
                this.closeSelect();
            } else {
                this.openSelect();
            }
        },
        openSelect() {
            setTimeout(() => {
                $('#admin_messengers_chatbox_filter_assignee').multipleSelect('open');
            });
        },
        closeSelect() {
            setTimeout(() => {
                $('#admin_messengers_chatbox_filter_assignee').multipleSelect('close');
            });
        },
    },
    computed: {
        ...mapGetters('chatbox', [
            'filter',
            'soundNotificationsActive',
        ]),
    },
    components: {
        XComplexInput,
    },
};
</script>
