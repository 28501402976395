import Locale from '@/apps/admin/services/scheduling/Locale';
import LocaleConfig from '@/apps/admin/services/scheduling/locale/Locale';
import Duration from './Duration';
import Date from './Date';

export const PRIORITY_LOW = 'priority_low';
export const PRIORITY_MEDIUM = 'priority_medium';
export const PRIORITY_HIGH = 'priority_high';

export const DAY = 'day';
export const WEEK = 'week';
export const WORKING_HOURS = 'working_hours';
export const FULL_DAY = 'full_day';

export const COLOR_DARK = '#1B2124';
export const COLOR_BLACK = '#1B2124';
export const COLOR_WHITE = '#ffffff';
export const COLOR_GRAY = '#DFE4ED';
export const COLOR_GRAY_100 = '#F7F8FC';
export const LANGUAGES = {
    en: 'en-gb',
    'en-us': 'en-us',
    es: 'es-es',
    cs: 'cs-cz',
    sk: 'sk-sk',
    uk: 'uk-ua',
    it: 'it-it',
    pl: 'pl-pl',
    ca: 'ca-es',
    'pt-br': 'pt-br',
    fr: 'fr-fr',
    de: 'de-de',
    nl: 'nl-nl',
    fa: 'fa-ir',
    ps: 'en-gb',
};
export class SchedulingHelpers {
    static calendarColors = {
        [PRIORITY_LOW]: 'bg-success',
        [PRIORITY_MEDIUM]: 'bg-warning',
        [PRIORITY_HIGH]: 'bg-danger',
    };

    static backlogColors = {
        [PRIORITY_LOW]: 'success',
        [PRIORITY_MEDIUM]: 'warning',
        [PRIORITY_HIGH]: 'danger',
    };

    static labelColors = {
        [PRIORITY_LOW]: 'Low',
        [PRIORITY_MEDIUM]: 'Medium',
        [PRIORITY_HIGH]: 'High',
    };

    static calendarTaskClasses(priority) {
        return SchedulingHelpers.calendarColors[priority] || priority;
    }

    static calendarTaskLabels(priority) {
        return SchedulingHelpers.labelColors[priority] || priority;
    }

    static getTimeUnits(type) {
        let time;
        switch (type) {
            case DAY: time = 'Minutes'; break;
            case WEEK: time = 'Hours'; break;
            default: time = 'Minutes';
        }
        return time;
    }

    static backlogTaskClasses(priority) {
        return SchedulingHelpers.backlogColors[priority] || priority;
    }

    static compareDateTimes(dateTime) {
        return dateTime < new Date.now();
    }

    static taskAreas(viewType, {
        moveTo, moveFrom, start, end, taskInPast, taskHtml,
    }) {
        const DAY_VIEW = viewType === DAY;

        const areas = [];
        if (moveTo && DAY_VIEW) {
            areas.push({
                start,
                end: start.addMinutes(moveTo),
                html: '<span class="btn-icon"><i class="icon-ic_fluent_vehicle_car_profile_ltr_16_regular"></i></span>',
                cssClass: `before_task${taskInPast}`,
            });
        }
        if (taskHtml) {
            areas.push({
                start: start.addMinutes(moveTo || 0),
                html: taskHtml,
                end: end.addMinutes(-moveFrom || 0),
                cssClass: `scheduler_task${taskInPast} scheduler_task_${viewType}`,
            });
        }
        if (moveFrom && DAY_VIEW) {
            areas.push({
                start: end.addMinutes(-moveFrom),
                end,
                html: '<span class="btn-icon"><i class="icon-ic_fluent_vehicle_car_profile_ltr_16_regular"></i></span>',
                cssClass: `after_task${taskInPast}`,
            });
        }
        return areas;
    }

    static getBackgroundImage(url) {
        return `
            background-image:url('${url}');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        `;
    }

    static parseResource(resource) {
        if (resource === 'A0') {
            return 'assigned_to_anyone';
        }
        let res = resource.split('');
        let assign = res[0].includes('A') ? 'admin_' : 'team_';
        res.shift();
        assign += res.join('');
        return assign;
    }

    static parseDate(date) {
        return date.toString('yyyy-MM-dd HH:mm:ss');
    }

    static calculateDuration(start, end) {
        return `${new Duration(this.parseDate(start), this.parseDate(end)).totalMinutes()}m`;
    }

    static localeLanguage() {
        return LANGUAGES[window.spl_config.language] ?? LANGUAGES.en;
    }

    static addCustomLocales() {
        Locale.register(
            new Locale('fa-ir', LocaleConfig['fa-ir']),
        );
    }
}

export default {
    SchedulingHelpers,
    PRIORITY_LOW,
    PRIORITY_MEDIUM,
    PRIORITY_HIGH,
    COLOR_DARK,
    COLOR_BLACK,
    COLOR_WHITE,
    COLOR_GRAY,
    COLOR_GRAY_100,
};
