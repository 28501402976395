import { promisifiedAjax, withGet } from '@/utils/functions';

export default class TaskViewService {
    static async loadData(id) {
        return promisifiedAjax({
            methods: 'GET',
            url: withGet('/admin/scheduling/tasks/view--load-data', { id }),
        });
    }

    static async loadWatchers(id) {
        return promisifiedAjax({
            methods: 'GET',
            url: withGet('/admin/scheduling/tasks/view--get-watchers', { id }),
        });
    }

    static async loadAttachments(id) {
        return promisifiedAjax({
            methods: 'GET',
            url: withGet('/admin/scheduling/tasks/view--get-attachments', { id }),
        });
    }

    static async loadInventoryData(taskId, customerId) {
        return promisifiedAjax({
            method: 'GET',
            url: withGet('/admin/scheduling/tasks/view--load-customer-inventory-data', { id: taskId, customer_id: customerId }),
        });
    }

    static async loadTaskViewInventory(taskId) {
        return promisifiedAjax({
            method: 'GET',
            url: withGet('/admin/scheduling/tasks/view--load-inventory', { id: taskId }),
        });
    }

    static async loadTaskInventoryLinkData(taskId) {
        return promisifiedAjax({
            method: 'GET',
            url: withGet('/admin/inventory/items--link-to-task-with-items-on-form', { scheduling_task_id: taskId }),
        });
    }

    static async loadTaskInventoryLinkDataForDataTable(taskId, data, customer_id = '') {
        let getData = { id: taskId };
        if (!empty(customer_id)) {
            getData.customer_id = customer_id;
        }

        return promisifiedAjax({
            method: 'POST',
            url: withGet('/admin/scheduling/tasks/view--get-inventory-items-for-link', getData),
            data,
        });
    }

    static async loadTaskInventoryLinkItemsForTable(data) {
        return promisifiedAjax({
            method: 'POST',
            url: '/admin/inventory/items--link-to-task',
            data,
        });
    }
}
