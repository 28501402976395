import { promisifiedAjax } from '@/utils/functions';

export default class ReloadSearchEngineService {
    static GET_PAGE_DATA = '/admin/config/tools/reload-search-engine--get-page-data';

    static RELOAD = '/admin/config/tools/reload-search-engine--reload';

    static RESET = '/admin/config/tools/reload-search-engine--reset';

    static async getPageData() {
        return promisifiedAjax({
            url: ReloadSearchEngineService.GET_PAGE_DATA,
            method: 'GET',
        });
    }

    static async reloadEngine() {
        return promisifiedAjax({
            url: ReloadSearchEngineService.RELOAD,
            method: 'POST',
            data: {},
        });
    }

    static async resetEngine() {
        return promisifiedAjax({
            url: ReloadSearchEngineService.RESET,
            method: 'POST',
            data: {},
        });
    }
}
