<template>
    <input
        v-bind="options"
        ref="input"
        type="hidden"
        :value="value"
        @input="inputHandler"
    >
</template>

<script>
export default {
    name: 'XInputHidden',
    props: {
        value: [String, Number],
        options: Object,
    },
    mounted() {
        $(this.$refs.input).change(this.inputHandler);
    },
    methods: {
        inputHandler(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
