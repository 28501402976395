<template>
    <div
        class="help-wrapper"
    >
        <!-- eslint-disable -->
        <x-translate
            tag="false"
            category="tariffs"
            message="If a customer’s location, category, or label does not match the tariff’s criteria, the tariff will be unavailable for selection. When ‘Hide tariff on Admin portal’ is disabled, the options below apply only to the customer portal. If enabled, these settings will also apply to the admin portal."
        />
        <!-- eslint-enable -->
    </div>
</template>

<script>

export default {
    name: 'HideOnAdminPortalTooltip',
};
</script>

<style>
.help-wrapper {
    display: inline-block;
}
</style>
