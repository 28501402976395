<template>
    <div class="input-group">
        <input
            v-bind="attributes"
            ref="input"
            :value="defaultValue"
            data-test-selector="input"
            :data-test-value="defaultValue"
            @focusout="focusout"
            @keydown.enter="emitFocusout"
        >
        <input
            ref="hiddenInput"
            type="hidden"
            :name="nameForHidden"
            :value="valueForHidden"
            data-test-selector="hidden"
        >
        <button
            v-if="!disabled"
            ref="button"
            type="button"
            class="btn btn-outline-secondary btn-icon admin_customers_billing_finance_items_tariff cursor-pointer"
            @click="clearPicker"
        >
            <i
                v-if="!emptyValue || !clearButton"
                class="icon-ic_fluent_calendar_ltr_24_regular"
            />
            <i
                v-if="emptyValue && clearButton"
                class="icon-ic_fluent_dismiss_24_regular"
            />
        </button>
    </div>
</template>

<script>
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputDate',
    mixins: [SplInputMixin],
    props: {
        options: Object,
        datePickerConfig: {
            type: Object,
            default: () => ({}),
        },
        clearButton: {
            type: Boolean,
            default: true,
        },
        value: String,
    },
    data() {
        return {
            disabled: false,
            emptyValue: true,
        };
    },
    computed: {
        attributes() {
            let options = { ...this.options };
            if (!isset(options, 'class')) {
                options.class = '';
            }
            options.class = this.addClass(options.class, ['form-control', 'input-sm', 'date-picker-single', 'input-date-range']);

            if (isset(options, 'name')) {
                // Name sets to hidden input instead of this
                delete options.name;
            }

            return options;
        },
        nameForHidden() {
            if (isset(this.options, 'name')) {
                return this.options.name;
            }

            return '';
        },
        valueForHidden() {
            if (this.value === '') {
                return '';
            }

            return this.value;
        },
        defaultValue() {
            let defaultValue = '';
            if (!empty(this.value) && this.value !== '0000-00-00') {
                defaultValue = moment(this.value, 'YYYY-MM-DD')
                    .format(date_format);
            }
            return defaultValue;
        },
    },
    watch: {
        value(newValue, currentVal) {
            if (newValue != currentVal) {
                if (empty(newValue)) {
                    $(this.$refs.input)
                        .val('');
                    this.emptyValue = false;
                } else {
                    $(this.$refs.input)
                        .val(moment(newValue, 'YYYY-MM-DD')
                            .format(date_format));
                    this.emptyValue = true;
                }
            }
        },
        options: {
            deep: true,
            handler(newOptions) {
                if (isset(newOptions, 'disabled')) {
                    this.disabled = newOptions.disabled;
                }
            },
        },
    },
    mounted() {
        let self = this;
        let config = {
            drops: 'auto',
        };
        $.extend(true, config, daterangepicker_single_default_config, this.datePickerConfig);

        $(this.$refs.input)
            .daterangepicker(config, (start) => {
                let datePickerSingle = $(self.$refs.input);
                datePickerSingle.val(start.format(date_format));
                datePickerSingle.trigger('change');
            });

        if (empty(this.defaultValue)) {
            this.emptyValue = false;
        }

        $(this.$refs.input)
            .on('apply.daterangepicker', (event, picker) => {
                self.$emit('input', picker.startDate.format('YYYY-MM-DD'));
            });

        [$('.vue-dialog-body'), $(window), $('.splynx-dialog-content')].forEach((scrollElement) => {
            $(scrollElement)
                .on('scroll', () => {
                    if ($(this.$refs.input).length && window.matchMedia('(min-width: 768px)').matches) {
                        $(this.$refs.input)
                            .blur();
                        $(this.$refs.input)
                            .data('daterangepicker')
                            ?.hide();
                    }
                });
        });

        if (this.value === '0000-00-00') {
            this.clearAndEmit();
        }

        this.disabled = this.$refs.input.disabled;
    },
    methods: {
        emitFocusout() {
            $(this.$refs.input)
                .trigger('blur');
        },
        focusout(e) {
            this.$emit('input', $(e.target)
                .data('daterangepicker')
                .startDate
                .format('YYYY-MM-DD'));
        },
        clearPicker() {
            if (!this.clearButton) {
                $(this.$refs.input)
                    .trigger('click');
                return;
            }
            if (!this.emptyValue || !this.clearButton) {
                $(this.$refs.input)
                    .trigger('click');
                return;
            }

            this.clearAndEmit();
        },
        clearAndEmit() {
            $(this.$refs.input)
                .val('')
                .trigger('change');
            this.$emit('input', '');
            this.emptyValue = false;
        },
    },
};
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
