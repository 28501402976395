<template>
    <div class="input-group">
        <input
            type="password"
            :value="value"
            v-bind="attributes"
            autocomplete="off"
            @input="(event) => $emit('input', event.target.value)"
        >
    </div>
</template>

<script>
import { isset } from '@/utils/functions';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputEncrypt',
    mixins: [SplInputMixin],
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        value: [String, Number],
    },
    computed: {
        attributes() {
            let attributes = { ...this.options };
            if (!isset(attributes, ['class'])) {
                attributes.class = '';
            }

            attributes.class = this.addClass(attributes.class, ['form-control', 'input-sm']);
            delete attributes.id;
            delete attributes.name;

            return attributes;
        },
    },
};
</script>
