import XTwigPage from '@/components/base/XTwigPage';
import SupplyRoutes from './inventory/supply';
import Products from './inventory/products';

const InventoryDashboard = () => import(/* webpackChunkName: "inventory_dashboard" */ '@/apps/admin/views/admin/inventory/dashboard/InventoryDashboard');
const InventoryItemsList = () => import(/* webpackChunkName: "inventory_items" */ '@/apps/admin/views/admin/inventory/items/InventoryItemsList');

export default [
    {
        path: 'dashboard',
        name: 'admin.inventory.dashboard',
        component: InventoryDashboard,
        meta: {
            keepAlive: true,
            componentName: 'InventoryDashboard',
        },
    },
    {
        path: 'items',
        name: 'admin.inventory.items',
        component: InventoryItemsList,
        meta: {
            keepAlive: false,
            title: ['inventory', 'Items'],
            componentName: 'InventoryItemsList',
            breadcrumbs: [
                { title: ['inventory', 'Inventory'], url: '/admin/inventory/dashboard' },
                { title: ['inventory', 'Items'], url: '/admin/inventory/items' },
            ],
        },
    },
    {
        path: 'items--history',
        name: 'admin.inventory.items.history',
        component: XTwigPage,
    },
    {
        path: 'products',
        children: Products,
    },
    {
        path: 'supply',
        children: SupplyRoutes,
    },
];
