export default {
    data() {
        return {
            setFieldContentTrigger: '',
            showCannedMessagesDropdown: false,
            cannedMessagesSearchQuery: '',
        };
    },
    methods: {
        async onCannedMessageSearch(query) {
            this.cannedMessagesSearchQuery = query;
        },
        onCannedMessageSelect(message) {
            this.setFieldContentTrigger = message;
            this.$nextTick(() => {
                this.setFieldContentTrigger = '';
            });
        },
        focusChatField(withSymbol) {
            this.$refs.chatField.focus(withSymbol);
        },
        onFieldArrow() {
            this.$refs.cannedMessages.focus();
        },
    },
};
