import { mapGetters, mapActions } from 'vuex';

/** *
 * Usage: import mixin and in route meta set attr controller = 'controllers\\directory\\controllerName'
 ** */

export default {
    data() {
        return {
            permissions: {},
        };
    },
    methods: {
        ...mapActions('admin_permissions', ['loadPermissions']),
        usePermissions() {
            if (this.instanceClass) {
                this.permissions = this.instanceClass.getPermissions(this.$route.meta.controller);
            }
        },
        adminCan(action) {
            return this.permissions[action];
        },
        getPermissionsByKey(controller, action) {
            if (action) {
                return this.map[controller][action];
            }
            if (controller) {
                return this.map[controller];
            }
            throw new Error(`Controller ${controller} not specified`);
        },
    },
    computed: {
        ...mapGetters('admin_permissions', ['instanceClass', 'map']),
    },
};
