import {
    RESET_CUSTOMER_PRESELECTED_OPTION, SET_CUSTOMER_PRESELECTED_OPTION, SET_TASK_ID_FOR_INVENTORY_MODAL,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';

const state = {
    customerPreselectedOption: [],
    taskId: 0,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    updateCustomerPreselectedOption({ commit }, customerPreselectedOption) {
        commit(SET_CUSTOMER_PRESELECTED_OPTION, customerPreselectedOption);
    },
    resetCustomerPreselectedOption({ commit }) {
        commit(RESET_CUSTOMER_PRESELECTED_OPTION);
    },
    updateTaskId({ commit }, customerPreselectedOption) {
        commit(SET_TASK_ID_FOR_INVENTORY_MODAL, customerPreselectedOption);
    },
};

const mutations = {
    [SET_CUSTOMER_PRESELECTED_OPTION](state, customerPreselectedOption) {
        state.customerPreselectedOption = customerPreselectedOption;
    },
    [RESET_CUSTOMER_PRESELECTED_OPTION](state) {
        state.customerPreselectedOption = [];
    },
    [SET_TASK_ID_FOR_INVENTORY_MODAL](state, taskId) {
        state.taskId = taskId;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
