import { stateToGetters } from '@/apps/admin/store/helpers';
import {
    SET_INCOMING_MAILBOXES_PAGE_DATA,
} from '../../../../mutation-types';

const state = {
    model: {
        email: '',
        username: '',
        password: '',
        imap_host: '',
        imap_port: '',
        imap_no_validate_cert: true,
        encryption: 'tls',
        imap_what_find: 'all',
        imap_last_processing_date: '',
        imap_mark_as_read: true,
        imap_receive_unregistered_email: true,
        ticket_priority: '',
        ticket_group_id: '',
        ticket_type_id: '',
        imap_enabled: true,
    },
    oauth_account: {
        id: '',
        status: '',
        server_name: '',
        date_added: '',
        error_message: '',
    },
    whatFindArray: [],
    addressFromArray: [],
    prioritiesArray: [],
    groupsArray: [],
    typesArray: [],
    authTypesArray: [],
    encryptionArray: [],
    attributeLabels: [],
    attributesMeta: {},
    domainUrl: '',
    defaultOfficeOauthValues: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadEditPageData({ commit }, id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/config/support/incoming-mail--load-edit-page-data?id=${id}`,
                success: (response) => {
                    commit(SET_INCOMING_MAILBOXES_PAGE_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
    loadAddPageData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/support/incoming-mail--load-add-page-data',
                success: (response) => {
                    commit(SET_INCOMING_MAILBOXES_PAGE_DATA, response);
                    resolve();
                },
                error: () => {
                    reject();
                },
            });
        });
    },
};

function replaceNullToZero(val) {
    if (val === null) {
        return '0';
    }
    return val;
}

const mutations = {
    [SET_INCOMING_MAILBOXES_PAGE_DATA](state, info) {
        state.model = info.model;
        state.model.ticket_group_id = replaceNullToZero(state.model.ticket_group_id);
        state.model.ticket_priority = replaceNullToZero(state.model.ticket_priority);
        state.model.ticket_type_id = replaceNullToZero(state.model.ticket_type_id);
        state.whatFindArray = info.whatFindArray;
        state.addressFromArray = info.addressFromArray;
        state.prioritiesArray = info.prioritiesArray;
        state.groupsArray = info.groupsArray;
        state.typesArray = info.typesArray;
        state.authTypesArray = info.authTypesArray;
        state.encryptionArray = info.encryption_array;
        state.attributeLabels = info.attributeLabels;
        state.attributesMeta = info.attributesMeta.attributes;
        if (info.oauth_account) {
            state.oauth_account = info.oauth_account;
        }
        state.domainUrl = info.domainUrl;
        state.defaultOfficeOauthValues = info.default_office_oauth_values;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
