import { promisifiedAjax } from '@/utils/functions';

export default class ChatBoxService {
    static DEFAULT_SOUND = 'software-interface-start';

    static soundsList() {
        return {
            'software-interface-start': 'Software interface start',
            'bell-notification': 'Bell notification',
            'confirmation-tone': 'Confirmation tone',
            'correct-answer-reward': 'Correct answer reward',
            'correct-answer-tone': 'Correct answer tone',
            'digital-quick-tone': 'Digital quick tone',
            'doorbell-single-press': 'Doorbell single press',
            'elevator-tone': 'Elevator tone',
            'gaming-lock': 'Gaming lock',
            'guitar-notification-alert': 'Guitar notification alert',
            'happy-bells-notification': 'Happy bells notification',
            'interface-hint-notification': 'Interface hint notification',
            'interface-option-select': 'Interface option select',
            'magic-marimba': 'Magic marimba',
            'message-pop-alert': 'Message pop alert',
            'positive-notification': 'Positive notification',
            'sci-fi-click': 'Sci-fi click',
            'sci-fi-confirmation': 'Sci-fi confirmation',
            'sci-fi-reject-notification': 'Sci-fi reject notification',
            'software-interface-back': 'Software interface back',
            'software-interface-remove': 'Software interface remove',
            'tile-game-reveal': 'Tile game reveal',
            'wrong-answer-fail-notification': 'Wrong answer fail notification',
        };
    }

    static async playNotification(selected) {
        const audio = new Audio(`/audio/notifications/${selected}.mp3`);
        await audio.play();
    }

    static fetchChats() {
        return promisifiedAjax({
            url: '/admin/support/chat_box/chat-box--load-chats',
        });
    }

    static fetchChatBoxConfigPageData(type = 'config') {
        return promisifiedAjax({
            url: `/admin/config/support/chat-box--get-page-data?type=${type}`,
        });
    }
}
