import TasksRoutes from './scheduling/tasks';
import ArchiveRoutes from './scheduling/archive';

const SchedulingDashboard = () => import(/* webpackChunkName: "scheduling_dashboard" */ '@/apps/admin/views/admin/scheduling/dashboard/SchedulingDashboard');
const SchedulingProjectsList = () => import(/* webpackChunkName: "scheduling_projects" */ '@/apps/admin/views/admin/scheduling/projects/SchedulingProjectsList');
const SchedulingCalendar = () => import(/* webpackChunkName: "scheduling_calendar" */ '@/apps/admin/views/admin/scheduling/calendars/SchedulingCalendar');
const SchedulingMap = () => import(/* webpackChunkName: "scheduling_map" */ '@/apps/admin/views/admin/scheduling/map/SchedulingMap');

export default [
    {
        path: 'dashboard',
        name: 'admin.scheduling.dashboard',
        component: SchedulingDashboard,
        meta: {
            keepAlive: true,
            componentName: 'SchedulingDashboard',
        },
    },
    {
        path: 'projects',
        name: 'admin.scheduling.projects',
        component: SchedulingProjectsList,
        meta: {
            keepAlive: true,
            componentName: 'SchedulingProjectsList',
        },
    },
    {
        path: 'tasks',
        children: TasksRoutes,
    },
    {
        path: 'calendars',
        name: 'admin.scheduling.calendars',
        component: SchedulingCalendar,
        meta: {
            breadcrumbs: [
                { title: ['crm', 'Scheduling'], url: '/admin/scheduling/dashboard' },
                { title: ['crm', 'Calendar'], url: '/admin/scheduling/calendars' },
            ],
            title: ['crm', 'Calendar'],
            // Turned off until all pages would work with vuex for setting project value on twig pages
            keepAlive: false,
            componentName: 'SchedulingCalendar',
        },
    },
    {
        path: 'maps',
        name: 'admin.scheduling.maps',
        component: SchedulingMap,
        meta: {
            breadcrumbs: [
                { title: ['crm', 'Scheduling'], url: '/admin/scheduling/dashboard' },
                { title: ['crm', 'Maps'], url: '/admin/scheduling/maps' },
            ],
            title: ['crm', 'Scheduling Map'],
            keepAlive: true,
            componentName: 'SchedulingMap',
        },
    },
    {
        path: 'archive',
        children: ArchiveRoutes,
    },
];
