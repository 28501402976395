<template>
    <div
        class="canned-messages"
        @keydown="onKeyDown"
        @keyup="onKeyUp"
    >
        <div
            v-for="(item, index) in filteredItems"
            :key="index"
            tabindex="0"
            class="canned-message"
            @click="$emit('select', item.code)"
        >
            <h5>{{ item.title }}</h5>
            <p>{{ item.code }}</p>
        </div>
        <div
            v-if="!filteredItems.length"
            class="canned-message empty"
        >
            <h5>{{ $t('messengers', 'No canned response found') }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CannedMessages',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        searchQuery: String,
    },
    data() {
        return {
            elements: [],
            activeElement: 0,
        };
    },
    mounted() {
        this.elements = this.$el.querySelectorAll('.canned-message');
    },
    methods: {
        focus() {
            this.activeElement = 0;
            this.elements[this.activeElement].focus();
        },
        onKeyUp(event) {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.$emit('select', this.filteredItems[this.activeElement].code);
            }
            if (this.activeElement > this.filteredItems.length - 1) {
                this.activeElement = 0;
                this.$emit('focus-field', '');
            }
        },
        onKeyDown(event) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                this.activeElement++;
                if (this.activeElement > this.filteredItems.length - 1) {
                    return false;
                }
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.activeElement--;
                if (this.activeElement < 0) {
                    this.activeElement = this.filteredItems.length - 1;
                }
            } else if (event.key !== 'Enter') {
                event.preventDefault();
                this.$emit('focus-field', event.key.length === 1 ? event.key : '');
                return false;
            }
            if (!this.elements[this.activeElement]) {
                this.activeElement = 0;
                return false;
            }
            this.elements[this.activeElement].focus();
        },
    },
    computed: {
        filteredItems() {
            if (!this.searchQuery) {
                return this.items;
            }
            const query = this.searchQuery.toLowerCase();
            return this.items.filter((item) => item.title.toLowerCase().includes(query));
        },
    },
    watch: {
        filteredItems() {
            this.elements = this.$el.querySelectorAll('.canned-message');
        },
    },
};
</script>
