const initCannedPlaceholdersPlugin = () => {
    const pluginPopup = 'cannedPlaceholders.popup';
    let placeholderContent = null;

    $.FroalaEditor.PLUGINS.cannedPlaceholders = function (editor) {
        // Add a popup template to avoid the problem with `replace`
        if (!$.FroalaEditor.POPUP_TEMPLATES[pluginPopup]) {
            $.FroalaEditor.POPUP_TEMPLATES[pluginPopup] = '[_BUTTONS_][_CUSTOM_LAYER_]';
        }

        function initPopup() {
            const template = {
                buttons: '',
                custom_layer: `<div id="placeholders_content"><div class="card-body">${window.t('common', 'Loading...')}</div></div>`,
            };

            return editor.popups.create(pluginPopup, template);
        }

        function showPopup() {
            let popup = editor.popups.get(pluginPopup);

            if (!popup) {
                popup = initPopup();
            }

            editor.popups.setContainer(pluginPopup, editor.$tb);

            const btn = editor.$tb.find('.fr-command[data-cmd="cannedPlaceholders"]');
            if (btn.length === 0) {
                return;
            }

            const left = 100;
            const top = btn.offset().top + (editor.opts.toolbarBottom ? 10 : btn.outerHeight() - 10);

            editor.popups.show(pluginPopup, left, top, btn.outerHeight());

            if (!placeholderContent) {
                $.ajax({
                    url: '/admin/tickets--ticket-placeholders',
                    success(response) {
                        placeholderContent = response;
                        $('#placeholders_content .card-body').html(placeholderContent);

                        $('#placeholders_content').off('click').on('click', '.placeholder', function () {
                            const placeholder = $(this).data('placeholder');
                            editor.html.insert(`{{ ${placeholder} }}`);
                            editor.events.trigger('contentChanged');
                        });
                    },
                    error() {
                        $('#placeholders_content .card-body').html(`<p>${window.t('common', 'Error loading placeholders')}</p>`);
                    },
                });
            }

            // close select2 when we hide popup
            editor.popups.onHide(pluginPopup, () => {
                window.closeSelects();
            });

            $(document).off('click.cannedPlaceholders').on('click.cannedPlaceholders', (event) => {
                if (popup !== event.target && !popup.has(event.target).length) {
                    editor.popups.hide(pluginPopup);
                }
            });
        }

        function hidePopup() {
            editor.popups.hide(pluginPopup);
        }

        return {
            showPopup,
            hidePopup,
        };
    };

    $.FroalaEditor.DefineIcon('cannedPlaceholders', { NAME: '{ }', template: 'text' });
    $.FroalaEditor.RegisterCommand('cannedPlaceholders', {
        title: window.t('common', 'Placeholders'),
        icon: 'cannedPlaceholders',
        focus: true,
        undo: false,
        refreshAfterCallback: true,
        popup: true,
        callback() {
            if (!this.popups.isVisible(pluginPopup)) {
                this.cannedPlaceholders.showPopup();
            } else {
                this.cannedPlaceholders.hidePopup();
            }
        },
    });
};

export default initCannedPlaceholdersPlugin;
