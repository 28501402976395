<template>
    <div class="input-group ip_input_block">
        <span
            ref="popoverButton"
            class="input-group-text btn-icon"
            :title="t('common', 'Get IPv4')"
        >
            <i class="icon-ic_fluent_search_24_regular" />
        </span>
        <div
            :id="popoverId"
            class="webui-popover-content"
        >
            <div class="popover-content">
                <div class="mb-16">
                    {{ t('networking', 'First free IP will be used') }}
                </div>

                <div
                    v-for="(item, index) in networks"
                    :key="index"
                    class="network-wrap"
                >
                    <a
                        href="javascript:void(0)"
                        @click.prevent="handleNetworkClick(item.id)"
                    >{{ item.text }} - {{ item.title }}</a>
                </div>
            </div>
        </div>

        <div
            class="input-wrap"
            multiple
        >
            <select
                ref="select"
                v-bind="options"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'XInputIp',
    props: {
        options: Object,
        networks: Array,
        module: String,
        attribute: String,
        id: [String, Number],
        value: [String, Array],
    },
    data() {
        return {
            popoverId: '',
            ipv4Id: null,
        };
    },
    watch: {
        value(newValue) {
            let valueChanged = false;
            if (Array.isArray(newValue)) {
                let values = [];
                newValue.forEach((item) => {
                    values.push(item.id);
                });
                let curVal = $(this.$refs.select).val();
                valueChanged = ((!Array.isArray(curVal) && curVal != values.join(',')) || (Array.isArray(curVal) && values.join(',') !== curVal.join(',')));
            } else {
                valueChanged = newValue != $(this.$refs.select).val();
            }

            if (newValue === null) {
                newValue = '';
            }

            if (valueChanged) {
                if (Array.isArray(newValue)) {
                    newValue.forEach((item) => {
                        $(this.$refs.select).select2('trigger', 'select', {
                            data: item,
                        });
                    });
                } else if (typeof newValue === 'object' && newValue !== null) {
                    $(this.$refs.select).select2('trigger', 'select', {
                        data: newValue,
                    });
                } else {
                    $(this.$refs.select).val(newValue).trigger('change');
                }
            }
        },
    },
    created() {
        this.popoverId = `x_input_ip_popover_content_${Math.round(Math.random() * 999999)}`;
    },
    beforeDestroy() {
        let element = $(this.$refs.select);
        if (element.data('select2')) {
            element.select2('destroy');
        }
    },
    mounted() {
        $(this.$refs.popoverButton).webuiPopover({
            title: this.t('networking', 'Select IPv4 network'),
            placement: 'right',
            width: 350,
            height: 150,
            url: `#${this.popoverId}`,
        });

        this.initSelect();
    },
    methods: {
        handleNetworkClick(networkId) {
            this.addItem(networkId);
        },
        addItem(network, skip, ipv4Id) {
            $.ajax({
                url: '/admin/relations--get-first-unused',
                dataType: 'json',
                data: {
                    ip: network,
                    skip,
                    ipv4Id,
                },
                success: (data) => {
                    if (data.id) {
                        this.ipv4Id = data.id;
                    }

                    let selectVal = $(this.$refs.select).val();
                    if ((selectVal && selectVal.indexOf(data.ip) != -1) || this.checkAllSelectedIp(data.ip)) {
                        this.addItem(network, true, this.ipv4Id);
                    } else {
                        this.processSelect(data.ip);
                        return true;
                    }
                },
            });
        },
        checkAllSelectedIp(ip) {
            let check = false;

            $('.ip_input_block').each(function () {
                if ($(this).find('.select2-hidden-accessible').val() && $(this).find('.select2-hidden-accessible').val().indexOf(ip) != '-1') {
                    check = true;
                }
            });
            return check;
        },
        processSelect(network) {
            $.ajax({
                url: '/admin/relations--get-ip',
                dataType: 'json',
                data: {
                    module: this.module,
                    attribute: this.attribute,
                    id: this.id,
                },
                success: (data) => {
                    let readyData = $.map(data.results, (field) => ({
                        text: field.text,
                        disabled: field.disabled,
                        children: $.map(field.children, (child) => ({
                            id: child.id,
                            text: child.text,
                            disabled: child.disabled,
                        })),
                    }));

                    let selectedElements = $(this.$refs.select).val();
                    if (selectedElements) {
                        selectedElements.push(network);
                    } else {
                        selectedElements = network;
                    }

                    $(this.$refs.select).html('').select2({ data: readyData });
                    $(this.$refs.select).val(selectedElements).trigger('change');

                    $(this.$refs.popoverButton).webuiPopover('hide');
                    $(this.$refs.select).next().children('.selection').children('.select2-selection')
                        .css('border-radius', '0px 3px 3px 0px');
                },
            });
        },
        initSelect() {
            $(this.$refs.popoverButton).webuiPopover('hide');

            let element = $(this.$refs.select);
            if (element.data('select2')) {
                element.select2('destroy');
                element.find('option,optgroup').remove();
            }

            let select = $(this.$refs.select).select2({
                multiple: true,
                tokenSeparators: [' '],
                ajax: {
                    url: '/admin/relations--get-ip',
                    dataType: 'json',
                    delay: 250,
                    data: (params) => ({
                        module: this.module,
                        attribute: this.attribute,
                        id: this.id,
                        query: params.term,
                    }),
                    processResults(data) {
                        return {
                            results: $.map(data.results, (field) => ({
                                text: field.text,
                                disabled: field.disabled,
                                children: $.map(field.children, (child) => ({
                                    id: child.id,
                                    text: child.text,
                                    disabled: child.disabled,
                                })),
                            })),
                        };
                    },
                    cache: true,
                },
                minimumInputLength: 0,
            });

            select.val(this.value).trigger('change');
            select.change(this.inputHandler);

            // @see https://github.com/select2/select2/issues/5993
            select.data('select2').on('open', this.setFocusToSearchInput);

            $(this.$refs.select).next().children('.selection').children('.select2-selection')
                .css('border-radius', '0px 3px 3px 0px');
        },
        setFocusToSearchInput() {
            setTimeout(() => {
                let field = $('.select2-container--open .select2-search__field');
                if (field.length) {
                    field.get(0).focus();
                }
            }, 100);
        },
        inputHandler(event) {
            let val;
            val = $(event.target).select2('data');
            if (val.length < 1) {
                val = event.target.value;
            } else {
                let result = [];
                val.forEach((item) => {
                    result.push({
                        id: item.id,
                        text: item.text,
                    });
                });
                val = result;
            }

            this.$emit('input', val);
            this.$emit('change', val);
        },
    },
};
</script>

<style scoped>
.network-wrap {
    cursor: pointer;
}
</style>
