<template>
    <div>
        <x-datatable-progress-optimized
            v-show="showMassProgress"
            :initial-progress="massActionInfo.progress"
            :type-mass-action-event="typeMassActionEvent"
            :initial-message="massActionInfo.message"
            @progress="showMassProgress = true"
            @finish="handleFinishMassAction"
        />
        <slot
            name="dt-under-header"
        />
        <x-mass-actions
            v-if="chunksTable"
            :id="id"
            ref="massActions"
            :wrapper-id="wrapperId"
            :mass-actions="massActions"
            :checked-ids="checkedIds"
            :data-for-mass-actions="dataForMassActions"
        />
        <table
            :id="id"
            ref="table"
            class="display supertable table table-striped"
            v-bind="localTableTagOptions"
        >
            <thead>
                <tr>
                    <th
                        v-for="columnTitle in headers"
                        :key="columnTitle"
                        v-html="columnTitle"
                    />
                    <th
                        v-if="actionColumn"
                        class="actions_column"
                    >
                        {{ titleOfActionColumn }}
                    </th>
                </tr>
            </thead>
        </table>
    </div>
</template>

<script>
import {
    empty, emptyObject, isArray, isObject,
} from '@/utils/functions';
import XDatatableProgressOptimized from '@/components/common/XDatatable/XDatatableProgressChunks.vue';
import XDatatableCommonMixin from '@/components/common/XDatatable/mixins/XDatatableCommonMixin';
import ColoredButton from '@/components/common/XDatatable/mixins/ColoredButton';
import ActionsAsObjectMixin from '@/components/common/XDatatable/mixins/ActionsAsObjectMixin';
import DTEvents from '@/components/common/XDatatable/mixins/DTEvents';
import WrapperSelectors from '@/components/common/XDatatable/mixins/WrapperSelectors';
import XMassActions from '@/components/common/XDatatable/XMassActions.vue';
import UpdateRows from '@/apps/admin/mixins/messengers/list/update-rows';
import BackgroundUpdate from '@/apps/admin/mixins/messengers/list/background-update';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'XDatatableChunks',
    mixins: [ActionsAsObjectMixin, WrapperSelectors, ColoredButton, XDatatableCommonMixin, DTEvents, UpdateRows, BackgroundUpdate],
    props: {
        massActions: {
            type: Object,
        },
        typeMassActionEvent: String,
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            localData: [],
            allRecordsIds: [],
            dataForMassActions: [],
            checkedIds: [],
            headerCheckboxSelector: '',
            idCurrent: '',
            processing: false,
        };
    },
    mounted() {
        this.localData = this.data;
        window.currentDataTableJsSelector = this.id;
        this.headerCheckboxSelector = document.querySelector(`#${this.id} th input.dt-checkboxes`);
        splynx_event_bus.off('data-for-table-refreshed');
        splynx_event_bus.on('data-for-table-refreshed', this.refreshActions);
        splynx_event_bus.off('clear-selection-data-table');
        splynx_event_bus.on('clear-selection-data-table', this.handleFinishMassAction);
        this.tableId = this.id;
    },
    activated() {
        this.init();
    },
    methods: {
        ...mapActions('update_table_items', ['updateTableRows', 'deleteRows', 'clearNeedToUpdate', 'addTableRows']),
        drawCallBackPrimary() {
            let self = this;

            return function () {
                let table = this.api();
                table.columns.adjust().responsive.recalc();
                if (!empty(self.drawCallback) && typeof self.drawCallback === 'function') {
                    self.drawCallback();
                }
                if (self.checkbox) {
                    $(`#${self.id} input.dt-checkboxes`).each(function () {
                        $(this).off().on('change', () => {
                            let val = $(this).val();
                            let prop = $(this).prop('checked');
                            if (val === 'select_all') {
                                self.selectAll(prop);
                                return false;
                            }

                            self.setCheckedIds(val, prop);
                        });
                    });
                }
            };
        },
        setCheckedIds(val) {
            if (this.checkedIds.includes(String(val))) {
                this.checkedIds.splice(this.checkedIds.indexOf(String(val)), 1);
            } else {
                this.checkedIds.push(val);
            }

            this.checkRenderAlert();
        },
        selectAll(prop) {
            this.processing = true;
            this.renderCheckboxesAndData(false, prop);
            this.processing = false;
        },
        addListener() {
            if (document.querySelector('.reset-button')) {
                document.querySelector('.reset-button').addEventListener('click', this.resetCheckboxes);
            }
        },
        resetCheckboxes() {
            if (document.querySelector('.reset-button')) {
                document.querySelector('.reset-button').removeEventListener('click', this.resetCheckboxes);
            }
            this.headerCheckboxSelector.removeEventListener('click', this.resetCheckboxes);
            this.headerCheckboxSelector.checked = false;
            this.selectAll(false);
        },
        checkboxTemplate(id, attrChecked) {
            return `<div class="form-check checkbox">
                        <input type="checkbox" class="dt-checkboxes dt-header form-check-input"
                               id="checkbox_${id}" value="${id}" ${attrChecked} />
                        <label for="checkbox_${id}"></label>
                   </div>`;
        },
        renderCheckboxesAndData(dataChanged = false, checked = false) {
            let tableDataSearch = $(this.$refs.table).DataTable().rows({ search: 'applied' });
            let searchedIds = tableDataSearch[0];
            let selectAllRecords = searchedIds?.length === this.localData.length;

            let localData = [];

            if (this.needToUpdate) {
                if (!emptyObject(this.rowsForAdd)) {
                    this.localData = JSON.parse(JSON.stringify([...this.localData, ...this.rowsForAdd]));
                }

                if (!empty(this.idsForDelete)) {
                    this.localData = this.localData.filter((item) => !this.idsForDelete.includes(item.checkbox.id));
                }

                if (!emptyObject(this.rowsForUpdate)) {
                    this.localData = this.localData.map((item) => {
                        let rowForUpdate = this.rowsForUpdate.find((row) => row.checkbox.id === item.checkbox.id);
                        return !empty(rowForUpdate) ? rowForUpdate : item;
                    });
                }
            }

            if (selectAllRecords) {
                this.checkedIds = checked ? [...this.allRecordsIds] : [];
                this.checkRenderAlert();
            }

            if (!dataChanged && !selectAllRecords && !this.needToUpdate) {
                let self = this;
                tableDataSearch.every(function () {
                    let itemData = this.data();
                    let jqEl = itemData.checkbox ? $(itemData.checkbox) : $(itemData[0]);
                    let newItemVal = jqEl.find('input').val();
                    let attrChecked = '';
                    if (checked) {
                        self.checkedIds.push(String(newItemVal));
                        attrChecked = 'checked="checked"';
                    } else if (self.checkedIds.includes(String(newItemVal))) {
                        self.checkedIds.splice(self.checkedIds.indexOf(String(newItemVal)), 1);
                    }

                    if (itemData.checkbox) {
                        this.data().checkbox = self.checkboxTemplate(newItemVal, attrChecked);
                    } else {
                        this.data()[0] = self.checkboxTemplate(newItemVal, attrChecked);
                    }
                    this.invalidate();
                    return this.data();
                }).draw();
                this.checkRenderAlert();
            } else {
                this.localData.forEach((item) => {
                    let itemNew;
                    if (isArray(item)) {
                        itemNew = this.getLayoutForRowFromArray(item, checked, dataChanged);
                    } else {
                        itemNew = this.getLayoutForRowFromObject(item, checked, dataChanged);
                    }

                    localData.push(itemNew);
                });

                this.updateTableRows([]);
                this.deleteRows([]);
                this.addTableRows([]);

                this.addRows(localData);
            }
        },
        getLayoutForRowFromObject(item, checked, dataChanged = false) {
            let itemNew = { ...item };
            let { id, checkbox, actions } = itemNew;

            if (!id && checkbox) {
                id = checkbox.id;
            }

            if (checkbox.type === 'checkbox') {
                let attrChecked = checked ? 'checked="checked"' : '';

                if (dataChanged || this.needToUpdate) {
                    let visibleActions = JSON.parse(JSON.stringify(checkbox.visible_actions));
                    this.allRecordsIds.push(String(id));
                    this.dataForMassActions[id] = visibleActions;
                }

                itemNew.checkbox = this.checkboxTemplate(id, attrChecked);
            }

            if (actions && typeof actions === 'object') {
                itemNew.actions = this.getActionsTemplate(actions);
            }
            return itemNew;
        },
        getLayoutForRowFromArray(item, checked, dataChanged) {
            let itemNew = [...item];
            let { id, type } = itemNew[0];

            if (type === 'checkbox') {
                let attrChecked = checked ? 'checked="checked"' : '';

                if (dataChanged) {
                    let visibleActions = JSON.parse(JSON.stringify(itemNew[0].visible_actions));
                    this.allRecordsIds.push(String(id));
                    this.dataForMassActions[id] = visibleActions;
                }

                itemNew[0] = this.checkboxTemplate(id, attrChecked);
            }
            return itemNew;
        },
        addRows(data) {
            $(this.$refs.table).DataTable().clear();
            $(this.$refs.table).DataTable().rows.add(data);
            $(this.$refs.table).DataTable().draw();
        },
        renderCheckboxAlert() {
            let rows = `<b>${this.$t('common', '{rows} out of {total}', {
                rows: this.checkedIds.length,
                total: this.allRecordsIds.length,
            })}</b>`;
            if (this.checkedIds.length === this.allRecordsIds.length) {
                rows = `<b>${this.$t('common', 'All')}</b>`;
            }
            let translate = this.$t('administration', '{rows} rows are selected. Actions will be applied to selected rows.', { rows });

            this.renderAlert(translate);
        },
        renderAlert(innerHtml) {
            let span = document.createElement('span');
            span.innerHTML = innerHtml;

            let button = document.createElement('button');
            button.innerHTML = this.$t('common', 'Reset selection');
            button.className = 'btn btn-outline-primary mx-4 reset-button';

            let subDiv = document.createElement('div');
            subDiv.className = 'alert alert-info text-center mass-actions-alert';
            subDiv.setAttribute('role', 'alert');
            subDiv.appendChild(span);
            subDiv.appendChild(button);

            this.setAlertHtml(subDiv.outerHTML);
        },
        handleFinishMassAction() {
            this.showMassProgress = false;
            if (!empty(this.rowsForUpdate)) {
                this.prepareForUpdateRows();
            }
            this.refreshActions();
        },
        refreshActions() {
            this.checkedIds = [];
            this.headerCheckboxSelector.checked = false;
            this.allRecordsIds = [];
            this.dataForMassActions = [];
            this.resetCheckboxes();
        },
        checkRenderAlert() {
            if (this.checkedIds.length > 0) {
                this.renderCheckboxAlert();
                this.addListener();
            } else {
                this.setAlertHtml('');
            }

            if (this.checkedIds.length > 0 && this.checkedIds.length < this.allRecordsIds.length) {
                this.headerCheckboxSelector.indeterminate = true;
                this.headerCheckboxSelector.addEventListener('click', this.resetCheckboxes);
            } else if (this.checkedIds.length === 0 || this.checkedIds.length === this.allRecordsIds.length) {
                this.headerCheckboxSelector.indeterminate = false;
                this.headerCheckboxSelector.removeEventListener('click', this.resetCheckboxes);
            }
        },
        prepareForUpdateRows() {
            this.rowsForUpdate.forEach((item) => {
                if (isObject(item.actions)) {
                    item.actions.forEach((action, index) => {
                        item.actions[index] = this.getLinkActions(action);
                    });
                }
            });
        },
    },
    computed: {
        ...mapGetters('update_table_items', [
            'rowsForUpdate', 'idsForDelete', 'needToUpdate', 'rowsForAdd',
        ]),
    },
    watch: {
        data(newV) {
            this.localData = structuredClone(newV);
            this.renderCheckboxesAndData(true);
        },
        processing(newVal) {
            if (newVal) {
                $(`#${this.pageName}_table_processing`).show();
            } else {
                $(`#${this.pageName}_table_processing`).hide();
            }
        },
    },
    components: {
        XDatatableProgressOptimized,
        XMassActions,
    },
    deactivated() {
        this.refreshActions();
        $(this.$refs.table).DataTable().destroy();
    },
};
</script>
