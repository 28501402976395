import { empty, promisifiedAjax } from '@/utils/functions';

/**
 * Dependencies:
 * data:
 *  - changedModel
 *  - model
 *   - related_customer_id
 *   - related_service_id
 *   - title
 *   - description
 *   - assigned_value
 *   - additional_attributes
 * methods:
 *  - doesHasCurlyBrackets
 *  - changeRenderForTemplates
 */
export default {
    data() {
        return {
            template: 0,
            oldTemplate: 0,
        };
    },
    methods: {
        renderTemplates() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    let customer = this.model.related_customer_id ?? 0;
                    let service = this.model.related_service_id ?? 0;
                    return new Promise(() => {
                        promisifiedAjax({
                            url: '/admin/scheduling/tasks--render-templates',
                            data: {
                                id: Number(customer),
                                template_id: this.template,
                                assignee: this.model.assigned_value,
                                templates: this.templatesForRender,
                                service_id: Number(service),
                            },
                            method: 'POST',
                        }).then((response) => {
                            this.convertDataFromTemplate(response);
                            resolve();
                        });
                    });
                }, 0);
            });
        },
        convertDataFromTemplate(response) {
            for (let attr in response) {
                switch (attr) {
                    case 'schedulingtask-title':
                        this.model.title = response[attr];
                        break;
                    case 'admin_scheduling_task_create_description_area_froala_input':
                        this.model.description = response[attr];
                        this.updateFroalaEditor(response[attr]);
                        break;
                    case 'title':
                        if (this.doesHasCurlyBrackets(response[attr])) {
                            this.changeRenderForTemplates('schedulingtask-title', response[attr]);
                        } else {
                            delete this.templatesForRender['schedulingtask-title'];
                        }
                        this.model.title = response[attr];
                        break;
                    case 'description':
                        if (this.doesHasCurlyBrackets(response[attr])) {
                            this.changeRenderForTemplates('admin_scheduling_task_create_description_area_froala_input', response[attr]);
                        } else {
                            delete this.templatesForRender.admin_scheduling_task_create_description_area_froala_input;
                        }
                        this.model.description = response[attr];
                        this.updateFroalaEditor(response[attr]);
                        break;
                    case 'additional_fields':
                        this.model.additional_attributes = response[attr];
                        break;
                    case 'project_id':
                        if (response[attr] != 0) {
                            this.model[attr] = response[attr];
                        }
                        break;
                    default:
                        this.model[attr] = response[attr];
                }
            }
        },
        changeTemplate(value) {
            if (this.oldTemplate !== value) {
                if (!empty(value)) {
                    return new Promise((resolve) => {
                        promisifiedAjax({
                            url: `/admin/scheduling/tasks/view--load-data-template?templateId=${value}`,
                            method: 'GET',
                        }).then((response) => {
                            if (response.status === true && !empty(response.result)) {
                                if (this.changedModel) {
                                    window.vue_confirm_dialog(
                                        this.$t('scheduling', 'Are you sure, you want to do this? If you change the template, your changes in the task will be lost.'),
                                        () => {
                                            this.convertDataFromTemplate(response.result);
                                            this.oldTemplate = this.template;
                                            this.renderTemplates();
                                        },
                                        () => {
                                            this.template = this.oldTemplate;
                                        },
                                    );
                                } else {
                                    this.convertDataFromTemplate(response.result);
                                    this.renderTemplates();
                                }
                            }
                            resolve();
                        });
                    });
                }
            }
        },
        updateFroalaEditor(value) {
            if (this.$refs.froala) {
                $(this.$refs.froala.$el).find('textarea').froalaEditor('html.set', value);
                $(this.$refs.froala.$el).find('textarea').trigger('froalaEditor.contentChanged', value);
            }
        },
    },
};
