import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_CUSTOMER_BILLING_INFO, SET_CUSTOMER_BLOCKING_INFO } from '../../../mutation-types';

const state = {
    customer: {},
    payment_method: null,
    formatted_deposit: 0,
    formatted_mrr: 0,
    blocking_info: {},
    show_finance_document_tab: false,
    is_sub_account: false,
    show_transactions_tab: false,
    show_billing_config_tab: false,
    addonTabs: [],
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadCustomerBillingInfo({ commit }, id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing--get-page-data?id=${id}`,
                success: (response) => {
                    commit(SET_CUSTOMER_BILLING_INFO, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    setCustomerBlockingInfo({ commit }, data) {
        commit(SET_CUSTOMER_BLOCKING_INFO, data);
    },
};

const mutations = {
    [SET_CUSTOMER_BILLING_INFO](state, data) {
        for (let k in data) {
            state[k] = data[k];
        }
    },
    [SET_CUSTOMER_BLOCKING_INFO](state, data) {
        state.blocking_info = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
