import XTwigPage from '@/components/base/XTwigPage';
import BillingRoutes from './leads/billing';
import ActivityRoutes from './leads/activity';

const LeadsList = () => import(/* webpackChunkName: "leads_list" */ '@/apps/admin/views/admin/crm/LeadsList');
const LeadsAdd = () => import(/* webpackChunkName: "leads_list" */ '@/apps/admin/views/admin/crm/leads/LeadsAdd');
const LeadView = () => import(/* webpackChunkName: "leads_list" */ '@/apps/admin/views/admin/crm/leads/view/LeadView');
const LeadConvertation = () => import(/* webpackChunkName: "leads_list" */ '@/apps/admin/views/admin/crm/leads/convertation/LeadConvertation');

export default [
    {
        path: 'list',
        name: 'admin.crm.leads.list',
        component: LeadsList,
        meta: {
            breadcrumbs: [
                { title: ['crm', 'Leads'], url: '/admin/crm/dashboard' },
                { title: ['crm', 'List'], url: '/admin/crm/leads/list' },
            ],
            title: ['crm', 'Leads list'],
            keepAlive: true,
            componentName: 'LeadsList',
        },
    },
    {
        path: 'add',
        name: 'admin.crm.leads.add',
        component: LeadsAdd,
        meta: {
            breadcrumbs: [
                { title: ['crm', 'Leads'], url: '/admin/crm/dashboard' },
                { title: ['crm', 'Add lead'], url: '/admin/crm/leads/add' },
            ],
            title: ['crm', 'Add lead'],
        },
    },
    {
        path: 'view',
        name: 'admin.crm.leads.view',
        component: LeadView,
        meta: {
            breadcrumbs: [
                { title: ['crm', 'Leads'], url: '/admin/crm/dashboard' },
                { title: ['crm', 'List'], url: '/admin/crm/leads/list' },
            ],
            title: ['crm', 'Leads list'],
            componentName: 'LeadsList',
        },
    },
    {
        path: 'convertation',
        name: 'admin.crm.leads.convertation',
        component: LeadConvertation,
        meta: {
            componentName: 'LeadConvertation',
            breadcrumbs: [
                { title: ['crm', 'Leads'], url: '/admin/crm/dashboard' },
                { title: ['crm', 'List'], url: '/admin/crm/leads/list' },
            ],
        },
    },
    {
        path: 'documents',
        name: 'admin.crm.leads.documents',
        component: XTwigPage,
        meta: {
            title: ['crm', 'Documents'],
        },
    },
    {
        path: 'communication',
        name: 'admin.crm.leads.communication',
        component: XTwigPage,
        meta: {
            title: ['crm', 'Communication'],
        },
    },
    {
        path: 'billing',
        children: BillingRoutes,
    },
    {
        path: 'activity',
        children: ActivityRoutes,
    },
    {
        path: 'comments',
        name: 'admin.crm.leads.comments',
        component: XTwigPage,
        meta: {
            title: ['customers', 'Comments'],
        },
    },
];
