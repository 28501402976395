import Permissions from '@/apps/admin/services/common/Permissions';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { SET_PERMISSIONS, SET_MAP } from '../mutation-types';

const state = {
    instanceClass: null,
    map: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    async loadPermissions({ dispatch, state }) {
        if (!state.instanceClass) {
            dispatch('createInstance');
            await dispatch('fetchDataInstance');
        }
        return false;
    },
    createInstance({ commit }) {
        const instanceClass = new Permissions();
        commit(SET_PERMISSIONS, instanceClass);
    },
    async fetchDataInstance({ commit, state }) {
        await state.instanceClass.fetchPermissions();
        commit(SET_MAP, state.instanceClass.getAllPermissions());
    },
};

const mutations = {
    [SET_PERMISSIONS](state, instance) {
        state.instanceClass = instance;
    },
    [SET_MAP](state, map) {
        state.map = map;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
