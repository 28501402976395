<template>
    <div class="scheduling-preview sidebar-content-inner h-100 px-0 pt-0">
        <x-preloader-block :loading="loading">
            <x-form
                :id="formId"
                ref="form"
                :url="formUrl"
                class="h-100 scheduling-task-preview form-label-start"
                method="POST"
                prevent-db="#admin_scheduling_task_preview_save_button"
                :data="model"
                :class-name="className"
                :attributes-meta="attributesMeta"
                :af-attributes-meta="additionalAttributesMeta"
                :before-send="beforeSend"
                @success="onFormSuccess"
            >
                <div class="form-content">
                    <div
                        v-if="newRecord"
                        class="row"
                        data-test-selector="task-template-row"
                    >
                        <label class="col-form-label">
                            {{ $t('scheduling', 'Task template') }}
                        </label>
                        <div class="col-md-12">
                            <x-complex-input
                                v-model="template"
                                type="select"
                                data-test-selector="task-template-select"
                                :params="{
                                    allowClear: true,
                                    placeholder: $t('scheduling', 'Select template'),
                                    options: {
                                        id: 'admin_config_task_templates_select_template',
                                        options: options.templates,
                                        disabled: disableFields,
                                    },
                                }"
                                @input="changeTemplate"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-form-label">
                            {{ $t('scheduling', 'Title') }}
                        </label>
                        <div class="col-md-12">
                            <x-complex-input
                                v-model="model.title"
                                type="text"
                                :meta="attributesMeta.title"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_title',
                                        disabled: disableFields,
                                    },
                                }"
                                @focusout="changeTitle"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-form-label">
                            {{ $t('scheduling', 'Description') }}
                        </label>
                        <div class="col-md-12">
                            <x-complex-input
                                ref="froala"
                                v-model="model.description"
                                class="pt-0"
                                type="froala"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_description',
                                    },
                                    toolbarButtons,
                                    toolbarSticky: false,
                                    mentions: true,
                                    mentionsList,
                                    heightMax: 105,
                                    disabled: disableFields,
                                }"
                                :meta="attributesMeta.description"
                                @focusout="changeDescription"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Project') }}
                            </label>
                            <x-complex-input
                                v-model="model.project_id"
                                type="select"
                                :params="{
                                    placeholder: $t('scheduling', 'Select project'),
                                    options: {
                                        id: 'scheduling_task_preview_project_id',
                                        options: options.projects,
                                        class: showWarningIncorrectValues('project_id') ? 'incorrect' : '',
                                        disabled: disableFields,
                                    },
                                }"
                                :meta="attributesMeta.project_id"
                                @input="changeProject"
                            />
                            <small
                                v-if="showWarningIncorrectValues('project_id')"
                                data-test-selector="project-warning"
                                v-html="getWarningMessage('project_id')"
                            />
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Assigned to') }}
                            </label>
                            <x-complex-input
                                v-model="model.assigned_value"
                                type="select"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_assigned_value',
                                        options: options.assignee_list,
                                        class: showWarningIncorrectValues('assigned_value') ? 'incorrect' : '',
                                        disabled: disableFields,
                                    },
                                }"
                                :meta="attributesMeta.assigned_value"
                                @input="changeAdmin"
                            />
                            <small
                                v-if="showWarningIncorrectValues('assigned_value')"
                                data-test-selector="admin-warning"
                                v-html="getWarningMessage('assigned_value')"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Priority') }}
                            </label>
                            <x-complex-input
                                v-model="model.priority"
                                type="select"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_priority',
                                        options: options.priorities,
                                        disabled: disableFields,
                                    },
                                    templateResult: true,
                                    templateSelection: true,
                                }"
                                :meta="attributesMeta.priority"
                            />
                        </div>
                        <div class="col-md-6">
                            <label
                                class="col-form-label"
                                v-text="getAttributeLabel('workflow_status_id')"
                            />
                            <x-complex-input
                                v-model="model.workflow_status_id"
                                type="select"
                                :params="{
                                    placeholder: $t('scheduling', 'Select project'),
                                    options: {
                                        id: 'scheduling_task_preview_workflow_status_id',
                                        options: options.statuses,
                                        disabled: disableProject,
                                    },
                                    templateResult: true,
                                    templateSelection: true,
                                }"
                                :meta="attributesMeta.workflow_status_id"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-form-label">
                            {{ $t('scheduling', 'Customer') }}
                        </label>
                        <div class="col-12">
                            <x-complex-input
                                v-model="model.related_customer_id"
                                data-test-selector="customer-select"
                                type="select-search"
                                :params="customerAllParams"
                                :meta="attributesMeta.related_customer_id"
                            />
                            <small
                                v-if="showWarningIncorrectValues('related_customer_id')"
                                data-test-selector="customer-warning"
                                v-html="getWarningMessage('related_customer_id')"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showRelatedServices"
                        data-test-selector="related-services-row"
                        class="row"
                    >
                        <label class="col-form-label">
                            {{ $t('scheduling', 'Related service') }}
                        </label>
                        <div class="col-12">
                            <x-complex-input
                                v-model="model.related_service_id"
                                type="select"
                                :meta="attributesMeta.related_service_id"
                                :params="{
                                    allowClear: true,
                                    options: {
                                        id: 'scheduling_task_related_service_id',
                                        options: serviceRelated,
                                        disabled: disableFields,
                                    },
                                    placeholder: $t('scheduling', 'Select related service'),
                                }"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Partner') }}
                            </label>
                            <div class="col-md-12">
                                <x-complex-input
                                    v-model="model.partner_id"
                                    type="select"
                                    :params="{
                                        placeholder: $t('scheduling', 'Select partner'),
                                        options: {
                                            id: 'scheduling_task_preview_partner_id',
                                            options: formattedPartners,
                                            class: showWarningIncorrectValues('partner_id')? 'incorrect': '',
                                            disabled: disableFields,
                                        },
                                    }"
                                    :meta="attributesMeta.partner_id"
                                    @input="checkPartners"
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Location') }}
                            </label>
                            <x-complex-input
                                v-model="model.location_id"
                                type="select"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_location_id',
                                        options: options.locations,
                                        placeholder: $t('scheduling', 'Select location'),
                                        disabled: disableFields,
                                    },
                                }"
                                :meta="attributesMeta.location_id"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-form-label">
                            <span class="d-flex justify-content-between align-items-center">
                                <span v-text="$t('scheduling', 'Address')" />
                                <span
                                    class="text-secondary"
                                >{{ addressSource }}</span>
                            </span>
                        </label>
                        <div class="col-md-12">
                            <x-complex-input
                                v-model="model.address"
                                type="textarea"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_address',
                                        rows: 2,
                                        disabled: disableFields,
                                    },
                                }"
                                :meta="attributesMeta.address"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-form-label">
                            <span class="d-flex justify-content-between align-items-center">
                                <span v-text="$t('scheduling', 'Coordinates')" />
                                <span
                                    class="text-secondary"
                                >{{ gpsSource }}</span>
                            </span>
                        </label>
                        <div class="col-md-12">
                            <x-complex-input
                                v-model="model.gps"
                                type="text"
                                data-test-selector="gps-input"
                                :params="coordinateParams"
                                :meta="attributesMeta.gps"
                            />
                        </div>
                    </div>
                    <div
                        v-if="newRecord"
                        class="row"
                        data-test-selector="check-list-row"
                    >
                        <label class="col-form-label">
                            {{ $t('scheduling', 'Checklist template') }}
                        </label>
                        <div class="col-md-12">
                            <x-complex-input
                                v-model="model.checklist_template_id"
                                type="select"
                                data-test-selector="check-list-select"
                                :params="{
                                    allowClear: true,
                                    placeholder: $t('scheduling', 'Select checklist'),
                                    options:{
                                        options: options.checklists_templates,
                                        disabled: disableFields,
                                    }
                                }"
                                :meta="attributesMeta.checklist_template_id"
                                @input="changeListOfChecklist"
                            />
                        </div>
                    </div>
                    <div
                        v-if="!empty(checklistTemplateItems)"
                        class="row"
                        data-test-selector="checklists-row"
                    >
                        <div class="col-12">
                            <div class="admin-scheduling--tasks-form-checklist-items">
                                <ul class="ps-16">
                                    <li
                                        v-for="checkItem in checklistTemplateItems"
                                        :key="checkItem.id"
                                    >
                                        {{ checkItem.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Start date & time') }}
                            </label>
                            <x-complex-input
                                v-model="model.scheduled_from"
                                type="datetime"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_start_date',
                                        disabled: disableFields,
                                    },
                                    datePickerConfig: {
                                        drops: 'up',
                                    },
                                    activeClearButton: !disableFields,
                                }"
                                :meta="attributesMeta.scheduled_from"
                                @input="changeStartDate"
                            />
                        </div>
                        <div class="col-md-6">
                            <label
                                class="col-form-label"
                                v-text="getAttributeLabel('scheduled_to')"
                            />
                            <x-complex-input
                                v-model="model.scheduled_to"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_end_date',
                                        disabled: disableEndDateTime,
                                    },
                                    datePickerConfig: {
                                        drops: 'up',
                                    },
                                    activeClearButton: !disableEndDateTime,
                                }"
                                :meta="attributesMeta.scheduled_to"
                                type="datetime"
                                @input="changeEndDate"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Travel time to task location') }}
                            </label>
                            <x-complex-input
                                v-model="model.formatted_travel_time_to"
                                :type="'text'"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_travel_time_to',
                                        placeholder: $t('scheduling', '0h 0m'),
                                        disabled: disableFields,
                                    },
                                }"
                                :meta="attributesMeta.formatted_travel_time_to"
                            />
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Travel time from task location') }}
                            </label>
                            <x-complex-input
                                v-model="model.formatted_travel_time_from"
                                :type="'text'"
                                :params="{
                                    options: {
                                        id: 'scheduling_task_preview_travel_time_from',
                                        placeholder: $t('scheduling', '0h 0m'),
                                        disabled: disableFields,
                                    },
                                }"
                                :meta="attributesMeta.formatted_travel_time_from"
                            />
                        </div>
                    </div>
                    <div
                        v-if="!newRecord"
                        class="row"
                        data-test-selector="task-preview-timestamps"
                    >
                        <div class="col">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Created') }}
                            </label>
                            <div
                                id="scheduling_task_preview_created_at"
                                v-text="formatDateTime(model.created_at)"
                            />
                        </div>
                        <div class="col">
                            <label class="col-form-label">
                                {{ $t('scheduling', 'Updated') }}
                            </label>
                            <div
                                id="scheduling_task_preview_updated_at"
                                v-text="formatDateTime(model.updated_at)"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-buttons">
                    <div class="row">
                        <div class="col-6">
                            <button
                                v-if="permissions.view"
                                id="admin_scheduling_task_preview_go_to_button"
                                type="button"
                                class="btn btn-link"
                                @click="goToTaskPage"
                                v-html="goToTaskButtonTitle"
                            />
                        </div>
                        <div class="col-6 text-end">
                            <button
                                id="admin_scheduling_task_preview_cancel_button"
                                class="btn btn-cancel"
                                @click.prevent="hideSidebar"
                                v-html="cancelButtonTitle"
                            />
                            <button
                                v-if="!disableFields"
                                id="admin_scheduling_task_preview_save_button"
                                class="btn btn-primary"
                                @click.prevent="$refs.form.submit()"
                            >
                                {{ $t('scheduling', 'Save') }}
                            </button>
                        </div>
                    </div>
                </div>
            </x-form>
        </x-preloader-block>
    </div>
</template>

<script>
import { XPreloaderBlock } from '@/components/common/XPreloaderBlock';
import { XForm } from '@/components/common/XForm';
import { XComplexInput } from '@/components/common/inputs/XComplexInput';
import {
    empty, formattedResponse, isset, promisifiedAjax,
} from '@/utils/functions';
import { SchedulingHelpers } from '@/apps/admin/services/scheduling/SchedulingHelpers';
import {
    AddressMixin,
    ChangeHandlers,
    ChangesMixin,
    ChecklistMixin,
    CoordinatesMixin,
    CurlyBrackets,
    CustomerMixin,
    DateMixin,
    geoDataSource,
    GoToTaskPage,
    HandlersMixin,
    HooksMixin,
    MentionsMixin,
    PartnersMixin,
    ServicesMixin,
    SidebarMixin,
    TemplatesForRender,
    TemplatesMixin,
    WarningsMixin,
} from '@/apps/admin/mixins/scheduling-calendar/view';

export default {
    name: 'SchedulingViewTask',
    mixins: [
        geoDataSource,
        AddressMixin,
        ChangeHandlers,
        ChangesMixin,
        ChecklistMixin,
        CoordinatesMixin,
        CurlyBrackets,
        CustomerMixin,
        DateMixin,
        GoToTaskPage,
        HandlersMixin,
        HooksMixin,
        MentionsMixin,
        PartnersMixin,
        ServicesMixin,
        SidebarMixin,
        TemplatesForRender,
        TemplatesMixin,
        WarningsMixin,
    ],
    components: {
        XComplexInput, XForm, XPreloaderBlock,
    },
    props: {
        id: [String, Number],
        args: {
            type: Object,
            default: null,
        },
        relatedId: {
            type: Number,
            default: null,
        },
        partnerId: {
            type: Number,
            default: null,
        },
        parentTaskId: {
            type: Number,
            default: null,
        },
        projectId: {
            type: [String, Number],
            default: null,
        },
        statusId: {
            type: [String, Number],
            default: null,
        },
        locationId: {
            type: [String, Number],
            default: null,
        },
        fromTicket: {
            type: Number,
            default: null,
        },
        adminCanEdit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            initialState: true,
            isSidebar: true,
            model: {},
            changedModel: false,
            attributesMeta: {},
            additionalAttributesMeta: {},
            permissions: {},
            options: {},
            className: '',
            loading: true,
            newRecord: true,
            formId: 'admin_scheduling_task_preview',

            oldCustomerValue: 0,
            oldServiceValue: 0,

            resources: {
                geo_data_src: {
                    customer: {},
                    service: {},
                },
            },
        };
    },
    created() {
        this.loadData({
            related_id: this.relatedId,
            partner_id: this.partnerId,
            project_id: this.projectId,
            workflow_status_id: this.statusId,
            location_id: this.locationId,
            from_ticket: this.fromTicket,
        }).then((data) => {
            let model = {};

            for (let attribute in data.model) {
                model[attribute] = data.model[attribute];
            }

            this.model = model;
            this.permissions = data.permissions;
            this.attributesMeta = data.attributesMeta.attributes;
            this.additionalAttributesMeta = data.attributesMeta.additional_attributes;
            this.options = data.resources.options;
            this.className = data.resources.class_name;
            this.newRecord = empty(data.model.id);

            this.oldCustomerValue = this.model.related_customer_id;
            this.oldServiceValue = this.model.related_service_id;

            this.preselectPartner(this.options.partners);

            this.__customerMixin_loadData(data);

            this.__changeHandlersMixin_loadData(data);

            this.__changesMixin_loadData(data);

            this.__addressMixin_loadData(data);

            this.__coordinatesMixin_loadData(data);

            if (this.args) this.setNewData(this.args);

            this.__mentionsMixin_loadData(data);

            this.__partnersMixin_loadData(data);

            this.__geoDataSourceMixin__loadData(data);

            this.loading = false;

            this.$nextTick(() => {
                this.__dateMixin_init(data);
                this.initialState = false;
            });
        });
    },
    computed: {
        formUrl() {
            if (this.newRecord) {
                return '/admin/scheduling/tasks--add-from-sidebar';
            }

            return `/admin/scheduling/tasks--edit?id=${this.id}&fromSidebar=true`;
        },
        toolbarButtons() {
            return [
                'bold', 'italic', 'underline',
                '|', 'formatOL', 'formatUL', 'insertLink',
                '|', 'color', 'clearFormatting', 'html',
            ];
        },
        goToTaskButtonTitle() {
            return !this.model.id ? this.$t('scheduling', 'Save & go to task page') : this.$t('scheduling', 'Go to task page');
        },
        cancelButtonTitle() {
            return this.model.is_archived ? this.$t('scheduling', 'Close') : this.$t('scheduling', 'Cancel');
        },
        disableFields() {
            return this.model.is_archived || !this.adminCanEdit;
        },
        disableEndDateTime() {
            return empty(this.model.scheduled_from) || this.disableFields;
        },
        disableProject() {
            return empty(this.model.project_id) || this.disableFields;
        },
    },
    methods: {
        onChangeRelatedService(newVal) {
            // Need trigger checking for updating geo data for new service
            this.setTaskGeoDataChangeParams({
                scenario: 'service',
                customer: empty(this.model.related_customer_id) ? 0 : this.model.related_customer_id,
                service: empty(newVal) ? 0 : newVal,
                old_service: empty(this.oldServiceValue) ? 0 : this.oldServiceValue,
            });

            this.oldServiceValue = newVal;

            this.changeRelatedServices(newVal);
        },
        setTaskGeoDataChangeParams(params) {
            let type = params.scenario;
            params.address = this.model.address;
            params.gps = this.model.gps;

            // Call to server and define if we need change address in inputs
            promisifiedAjax({
                url: `/admin/scheduling/tasks/view--define-geo-data-change?type=${type}_change`,
                data: params,
                method: 'POST',
            }).then((response) => {
                if (response.need_change) {
                    this.setAddressAction(response.address);
                    this.setCoordinateAction(response.marker);
                    this.setGeoDataSource(response.src, 'address', response.address);
                    this.setGeoDataSource(response.src, 'marker', response.marker);
                }
            });
        },
        onChangeCustomer(newVal) {
            // Need trigger checking for updating geo data for new customer
            this.setTaskGeoDataChangeParams({
                scenario: 'customer',
                customer: empty(newVal) ? 0 : newVal,
                old_customer: empty(this.oldCustomerValue) ? 0 : this.oldCustomerValue,
                service: empty(this.model.related_service_id) ? 0 : this.model.related_service_id,
            });
            this.oldCustomerValue = newVal;

            this.changeCustomer(newVal);
        },
        loadData(params) {
            let queryParams = '';
            for (let key in params) {
                if (params[key] !== null) {
                    queryParams += `&${key}=${params[key]}`;
                }
            }

            return new Promise((resolve) => {
                promisifiedAjax({
                    url: `/admin/scheduling/tasks--get-task-data?id=${empty(this.id) ? 0 : this.id}${queryParams}`,
                }).then((response) => {
                    resolve(formattedResponse(response));
                });
            });
        },
        empty,
        setNewData(args) {
            this.model.scheduled_from = SchedulingHelpers.parseDate(args.start);
            this.model.scheduled_to = SchedulingHelpers.parseDate(args.end);
            this.model.assigned_value = SchedulingHelpers.parseResource(args.resource);
        },
        changeListOfChecklist(value) {
            if (!empty(value)) {
                this.getChecklistByTemplate(value);
            } else {
                this.checklistTemplateItems = [];
            }
        },
        getAttributeLabel(attribute) {
            if (isset(this.attributesMeta, [attribute, 'label'])) {
                return this.attributesMeta[attribute].label;
            }

            return attribute;
        },
        preselectPartner(data) {
            const partners = Object.keys(data);
            if (partners.length === 1) {
                this.model.partner_id = partners[0];
            }
        },
    },
    watch: {
        'model.related_customer_id': function (newValue) {
            if (this.initialState) {
                return false;
            }
            this.onChangeCustomer(newValue);
        },
        'model.related_service_id': function (newValue) {
            if (this.initialState) {
                return false;
            }
            this.onChangeRelatedService(newValue);
        },
        model: {
            deep: true,
            handler(newVal, oldVal) {
                if (!isset(oldVal, 'id') && this.newRecord) {
                    return false;
                }
                if (!this.changedModel && this.newRecord) {
                    this.changedModel = true;
                }
            },
        },
        'model.scheduled_from': function () {
            if (empty(this.model.scheduled_from)) {
                this.model.scheduled_to = '';
            }
        },
    },
};
</script>
