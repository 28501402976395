import { stateToGetters } from '@/apps/admin/store/helpers';
import { isset } from '@/utils/functions';
import { SET_HISTORY_AND_PREVIEW_PAGE_DATA } from '../../mutation-types';

const state = {
    automaticEnabled: false,
    adminCanPreview: false,
    activeProgressIdentifiers: [],
    progressAlertTitle: '',
    currentProcessInfo: null,
    dataTableHeaders: [],
    dtColumnDefs: {},
    modelName: '',
    className: '',
    attributesMeta: {},
    model: {},
    partners: {},
    adminPartnerId: 0,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadHistoryAndPreviewData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/finance/history--get-page-data',
                success: (response) => {
                    commit(SET_HISTORY_AND_PREVIEW_PAGE_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_HISTORY_AND_PREVIEW_PAGE_DATA](state, info) {
        state.automaticEnabled = info.automaticEnabled;
        state.adminCanPreview = info.adminCanPreview;
        state.activeProgressIdentifiers = info.active_progress_identifiers;
        state.progressAlertTitle = info.title_for_progress_bars;
        state.dataTableHeaders = info.dataTableHeaders;
        state.dtColumnDefs = info.dtColumnDefs;
        state.modelName = info.modelName;
        state.partners = info.partners;
        state.attributesMeta = info.attributesMeta.attributes;
        state.className = info.attributesMeta.className;
        state.adminPartnerId = info.adminPartnerId;

        if (isset(info, 'model')) {
            state.model = info.model;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
