<template>
    <div
        :id="`message_${id}`"
        :class="classes"
    >
        <div class="d-flex flex-column message-content-container">
            <div class="message-head">
                <div class="message-head-info">
                    <div class="author-and-phone">
                        {{ authorAndPhone }}
                    </div>
                    <div class="date-and-time">
                        {{ date }}
                    </div>
                </div>
                <div
                    class="d-flex align-self-start"
                >
                    <chat-message-actions
                        :actions="messageActions"
                        @perform="onActionPerform"
                    />
                </div>
            </div>
            <div
                v-if="attachments.list.length"
                ref="attachmentElement"
                class="message-content"
            >
                <chat-attachment-image
                    v-for="attachment of attachments.getImages()"
                    :id="attachment.id"
                    :key="`${attachment.id}_image`"
                    :type="attachment.type"
                    :is-pws="attachment.isPws"
                    :download-url="attachment.downloadUrl"
                    :ratio="attachment.ratio"
                    :title="attachment.title"
                    :url="attachment.url"
                    :link="attachment.link"
                />
                <chat-attachment-iframe
                    v-for="attachment of attachments.getIframes()"
                    :id="attachment.id"
                    :key="`${attachment.id}_iframe`"
                    :type="attachment.type"
                    :is-pws="attachment.isPws"
                    :download-url="attachment.downloadUrl"
                    :ratio="attachment.ratio"
                    :title="attachment.title"
                    :url="attachment.url"
                    :link="attachment.link"
                />
                <chat-attachment-document
                    v-for="attachment of documentAttachments"
                    :id="attachment.id"
                    :key="`${attachment.id}_document`"
                    :type="attachment.type"
                    :is-pws="attachment.isPws"
                    :download-url="attachment.downloadUrl"
                    :ratio="attachment.ratio"
                    :url="attachment.url"
                    :link="attachment.link"
                    :size="attachment.size"
                    :name="attachment.name"
                />
                <chat-attachment-audio
                    v-for="attachment of audioAttachments"
                    :id="attachment.id"
                    :key="`${attachment.id}_audio`"
                    :type="attachment.type"
                    :is-pws="attachment.isPws"
                    :download-url="attachment.downloadUrl"
                    :ratio="attachment.ratio"
                    :url="attachment.url"
                    :link="attachment.link"
                    :size="attachment.size"
                    :name="attachment.name"
                    :audio-format="attachment.audioFormat"
                    :audio-alternative-link="attachment.audioAlternativeLink"
                    :audio-alternative-format="attachment.audioAlternativeFormat"
                />
            </div>
            <div
                class="message-content"
                v-html="formattedMessage"
            />
            <slot
                :location="location"
                name="message-location-element"
            />
            <message-linked-ticket
                v-if="ticket.id"
                :id="ticket.id"
                :url="ticket.url"
                :subject="ticket.subject"
            />
        </div>
        <message-status-icon
            :status="status"
            :out="isOut"
        />
        <div
            class="position-absolute"
            :style="massActionStyle"
        >
            <slot name="mass-check-element" />
        </div>
    </div>
</template>

<script>
// Services
import Message from '@/apps/admin/services/messengers/Message';
import Ticket from '@/apps/admin/services/tickets/Ticket';
import TicketAction from '@/apps/admin/services/tickets/TicketAction';
import MessageLocation from '@/apps/admin/services/messengers/MessageLocation';
import MessageAuthor from '@/apps/admin/services/messengers/MessageAuthor';
import Attachments from '@/apps/admin/services/messengers/attachments/Attachments';

// Mixins
import ChatMessageActionsMixin from '@/apps/admin/mixins/messengers/message/chat-message-actions-mixin';

// Components
import ChatMessageActions from './ChatMessageActions.vue';
import MessageStatusIcon from './MessageStatusIcon.vue';
import MessageLinkedTicket from './MessageLinkedTicket.vue';
import ChatAttachmentImage from './attachments/ChatAttachmentImage.vue';
import ChatAttachmentIframe from './attachments/ChatAttachmentIframe.vue';
import ChatAttachmentDocument from './attachments/ChatAttachmentDocument.vue';
import ChatAttachmentAudio from './attachments/ChatAttachmentAudio.vue';

export default {
    name: 'ChatMessage',
    mixins: [ChatMessageActionsMixin],
    props: {
        message: {
            type: String,
            default: '',
        },
        type: String,
        id: [Number, String],
        messenger: [Number, String],
        chatId: [Number, String],
        status: {
            type: [String],
            default: 'queue',
        },
        phone: {
            type: String,
            default: '',
        },
        date: {
            type: String,
            default: '',
        },
        author: {
            type: MessageAuthor,
            default: () => new MessageAuthor(),
        },
        location: {
            type: MessageLocation,
            default: () => new MessageLocation(),
        },
        attachments: {
            type: Attachments,
            default: () => new Attachments(),
        },
        chatCustomerId: [String, Number],
        senderType: String,
        ticket: {
            type: Ticket,
            default: () => new Ticket(),
        },
        unknownSender: Boolean,
    },
    data() {
        return {
            audioAttachments: [],
            documentAttachments: [],
        };
    },
    mounted() {
        this.makeAttachments();
    },
    methods: {
        makeAttachments() {
            this.audioAttachments = this.attachments.getAudio();
            this.documentAttachments = this.attachments.getDocuments();
        },
        wrapUrlsWithAnchorTags(text) {
            const urlPattern = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi;
            return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
        },
    },
    computed: {
        authorAndPhone() {
            const { name } = this.author;
            const { phone } = this;
            if (name) {
                if (phone) {
                    return `${name} (${phone})`;
                }
                return name;
            }
            return phone ?? this.phone ?? '';
        },
        messageActions() {
            let actions = [];
            const config = {};
            config.params = {
                messageId: this.id,
                customerId: this.chatCustomerId,
                ticketId: this.ticket.id,
                messenger: this.messenger,
                chatId: this.chatId,
                ticket: this.ticket,
            };
            // Create ticket action if it is not linked
            if (!this.isTicketLinkedToMessage) {
                actions.push(new TicketAction({
                    ...config,
                    title: this.$t('messengers', 'Create ticket'),
                    name: 'create-ticket',
                }));
            }
            if (this.chatCustomerId) {
                actions.push(new TicketAction({
                    ...config,
                    title: this.$t('messengers', 'Link ticket'),
                    name: 'link-ticket',
                }));
            }
            // Unlink ticket if it linked
            if (this.isTicketLinkedToMessage) {
                actions.push(new TicketAction({
                    ...config,
                    title: this.$t('messengers', 'Unlink ticket'),
                    name: 'unlink-ticket',
                }));
            }
            return actions;
        },
        isTicketLinkedToMessage() {
            return Ticket.isValidTicket(this.ticket);
        },
        formattedMessage() {
            const message = this.message?.replace(/\n/g, '<br />');
            if (!message) {
                return '';
            }
            return this.wrapUrlsWithAnchorTags(message);
        },
        classes() {
            return {
                'position-relative': true,
                message: true,
                'message-in': this.isIn,
                'message-out': this.isOut,
            };
        },
        massActionStyle() {
            const valueY = 'calc(50% - 6.5px)';
            const valueX = '-24px';

            const styles = {
                top: valueY,
            };
            if (this.isOut) {
                styles.left = valueX;
            } else {
                styles.right = valueX;
            }

            return styles;
        },
        isIn() {
            return this.type === Message.TYPE_IN;
        },
        isOut() {
            return this.type === Message.TYPE_OUT;
        },
    },
    watch: {
        attachments: {
            handler() {
                this.makeAttachments();
            },
            deep: true,
        },
    },
    components: {
        MessageStatusIcon,
        ChatMessageActions,
        MessageLinkedTicket,
        ChatAttachmentIframe,
        ChatAttachmentImage,
        ChatAttachmentDocument,
        ChatAttachmentAudio,
    },
};
</script>
