import {
    SET_PAYMENT_MODAL_INFO, CLEAR_PAYMENT_MODAL_INFO,
} from '@/apps/admin/store/mutation-types';
import { stateToGetters } from '@/apps/admin/store/helpers';
import { formattedResponse } from '@/utils/functions';

const state = {
    model: {},
    invoice: {},
    request: {},
    creditNote: {},
    paymentsTypesArray: {},
    attributesMeta: {},
    afAttributesMeta: {},
    isAggregatedBilling: false,
    typeLabelForAddFields: {},
    readOnly: false,
    customer: {},
    relatedItems: {},
    actionPermissions: {},
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    loadModalInfo({ commit }, {
        customer_id, payment_id, invoice_id, request_id, view,
    }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/customers/billing/payments--get-modal-info',
                method: 'GET',
                data: {
                    customer_id,
                    payment_id: payment_id ?? null,
                    invoice_id: invoice_id ?? null,
                    request_id: request_id ?? null,
                    view: view ?? 0,
                },
                success: (response) => {
                    commit(SET_PAYMENT_MODAL_INFO, formattedResponse(response));
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadFieldsForType(obj, type_id) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/customers/billing/payments--get-name-fields',
                method: 'GET',
                data: {
                    type: type_id,
                },
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    getNewNumber(obj, { customer_id, date, payment_type }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/admin/customers/billing/payments--receipt-number?customer_id=${customer_id}`,
                method: 'POST',
                data: {
                    date,
                    payment_type,
                },
                success: (response) => {
                    resolve(response);
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
};

const mutations = {
    [SET_PAYMENT_MODAL_INFO](state, info) {
        for (let key in info) {
            if (key === 'attributesMeta') {
                state[key] = info[key].attributes;
                state.afAttributesMeta = info[key].additional_attributes;
                continue;
            }
            if (key === 'isNewRecord') {
                state.readOnly = info[key];
            }
            state[key] = info[key];
        }
    },
    [CLEAR_PAYMENT_MODAL_INFO](state) {
        state.model = {};
        state.invoice = {};
        state.request = {};
        state.readOnly = false;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
