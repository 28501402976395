import { promisifiedAjax } from '@/utils/functions';
import { mapActions } from 'vuex';

/**
 * Dependencies:
 * props:
 *  - newRecord
 *  - parentTaskId
 * data:
 *  - warningIncorrectValues
 *  - errorMessages
 *  - model
 *   - id
 *   - partner_id
 *   - project_id
 *   - related_customer_id
 *   - assigned_value
 *   - options
 */
export default {
    data() {
        return {
            correctPartnerId: '',
        };
    },
    mounted() {
        this.correctPartnerId = this.model.partner_id;
    },
    methods: {
        /** @method Call it after loadData */
        __partnersMixin_loadData() {
            this.resetSidebarTaskWarningsArray();
            if (!this.newRecord) this.checkPartners();
        },
        ...mapActions('task_view_warnings', ['resetSidebarTaskWarningsArray', 'updateTaskWarningsArray', 'updateSidebarTaskWarningsArray']),
        checkPartners() {
            if (this.model.partner_id) {
                let data = {
                    partner_id: this.model.partner_id,
                    project_id: this.model.project_id,
                    related_customer_id: this.model.related_customer_id,
                    assigned_value: this.model.assigned_value,
                    parent_task_id: this.parentTaskId,
                };

                promisifiedAjax({
                    type: 'POST',
                    url: `/admin/scheduling/tasks/view--check-partners?id=${this.model.id}&withOutModelName=true`,
                    data,
                }).then((response) => {
                    /* (data.errorMessages) frontend/apps/admin/mixins/scheduling-calendar/view/warnings-mixin.js */
                    this.errorMessages = [];

                    this.correctPartnerId = response.related_customer_id?.correct_partner_id || this.model.partner_id;

                    if (!this.isSidebar) {
                        this.updateTaskWarningsArray(response);
                    } else {
                        this.updateSidebarTaskWarningsArray(response);
                    }
                });
            }
        },
    },
    computed: {
        formattedPartners() {
            if (this.model.related_customer_id) {
                const currentPartners = this.options.partners[this.correctPartnerId];
                const formattedPartner = this.$t('scheduling', "(Customer's partner)");

                if (currentPartners && !currentPartners.includes(formattedPartner)) {
                    return {
                        ...this.options.partners,
                        [this.correctPartnerId]: `${currentPartners} ${formattedPartner}`,
                    };
                }
            }

            return this.options.partners;
        },
    },
};
