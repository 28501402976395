import XTwigPage from '@/components/base/XTwigPage';

const CannedResponsesList = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/canned-responses/CannedResponsesList');
const TicketThingsConfig = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/ticket-things/TicketThingsConfig');
const TicketAutomationList = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/ticket-automation/TicketAutomationList');
const AddMailBoxPage = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/incoming-mail/AddMailBoxPage');
const EditMailBoxPage = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/incoming-mail/EditMailBoxPage');
const TicketTemplates = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/ticket-templates/TicketTemplates');
const EditTicketTemplate = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/ticket-templates/EditTicketTemplate');
const ChatBoxConfig = () => import(/* webpackChunkName: "config_support" */ '@/apps/admin/views/admin/config/support/chat-box/ChatBoxConfig');

export default [
    {
        delimiter: '--',
        path: 'settings',
        name: 'admin.config.support.settings',
        component: XTwigPage,
    },
    {
        path: 'ticket-templates',
        name: 'admin.config.support.ticket_templates',
        component: TicketTemplates,
        meta: {
            keepAlive: true,
            componentName: 'TicketTemplates',
            breadcrumbs: [
                { title: ['common', 'Config'], url: '/admin/config' },
                { title: ['common', 'Helpdesk'], url: '/admin/config#support' },
                { title: ['common', 'Ticket notifications'], url: '/admin/config/support/ticket-templates' },
            ],
        },
    },
    {
        path: 'ticket-templates--edit-template',
        name: 'admin.config.support.ticket_templates.edit',
        component: EditTicketTemplate,
        meta: {
            keepAlive: true,
            componentName: 'EditTicketTemplate',
            breadcrumbs: [
                { title: ['common', 'Config'], url: '/admin/config' },
                { title: ['common', 'Helpdesk'], url: '/admin/config#support' },
                { title: ['common', 'Ticket notifications'], url: '/admin/config/support/ticket-templates' },
                { title: ['common', 'Edit template'], url: '/admin/config/support/ticket-templates' },
            ],
        },
    },
    {
        path: 'ticket-canned-responses',
        name: 'admin.config.support.ticket_canned_responses',
        component: CannedResponsesList,
        meta: {
            keepAlive: true,
            componentName: 'CannedResponsesList',
        },
    },
    {
        path: 'ticket-things',
        name: 'admin.config.support.ticket_things',
        component: TicketThingsConfig,
        meta: {
            keepAlive: true,
            componentName: 'TicketThingsConfig',
        },
    },
    {
        path: 'feedback',
        name: 'admin.config.support.feedback',
        component: XTwigPage,
    },
    {
        path: 'incoming-mail',
        name: 'admin.config.support.incoming_mail',
        component: XTwigPage,
    },
    {
        path: 'incoming-mail--add-mailbox',
        name: 'admin.config.support.incoming_mail.add_mailbox',
        component: AddMailBoxPage,
        meta: {
            keepAlive: true,
            componentName: 'AddMailBoxPage',
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Helpdesk'], url: '/admin/config#support' },
                { title: ['config', 'Inboxes'], url: '/admin/config/support/incoming-mail' },
                { title: ['config', 'Add incoming mailbox'], url: '/admin/config/support/incoming-mail' },
            ],
            title: ['config', 'Add incoming mailbox'],
        },
    },
    {
        path: 'incoming-mail--edit-mailbox',
        name: 'admin.config.support.incoming_mail.edit_mailbox',
        component: EditMailBoxPage,
        meta: {
            keepAlive: true,
            componentName: 'EditMailBoxPage',
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Helpdesk'], url: '/admin/config#support' },
                { title: ['config', 'Inboxes'], url: '/admin/config/support/incoming-mail' },
            ],
            title: ['config', 'Edit incoming mailbox'],
        },
    },
    {
        path: 'ticket-automation',
        name: 'admin.config.support.ticket_automation',
        component: TicketAutomationList,
        meta: {
            keepAlive: true,
            componentName: 'TicketAutomationList',
        },
    },
    {
        path: 'chat-box',
        name: 'admin.config.support.chat_box',
        component: ChatBoxConfig,
        meta: {
            keepAlive: true,
            componentName: 'ChatBoxConfig',
            title: ['config', 'Chatbox'],
            breadcrumbs: [
                { title: ['config', 'Config'], url: '/admin/config' },
                { title: ['config', 'Helpdesk'], url: '/admin/config#support' },
                { title: ['config', 'Chatbox'], url: '/admin/config/support/chat-box' },
            ],
        },
    },
];
