import { promisifiedAjax } from '@/utils/functions';

export default class Permissions {
    #permissions;

    constructor() {
        this.#permissions = {};
    }

    #setPermissions(newPermissions) {
        this.#permissions = newPermissions;
    }

    async fetchPermissions() {
        const response = await promisifiedAjax({
            url: '/admin/get-data--permissions',
        });
        this.#setPermissions(response.permissions);
    }

    getAllPermissions() {
        return this.#permissions;
    }

    getPermissions(controller) {
        if (Object.hasOwn(this.#permissions, controller)) {
            return this.#permissions[controller];
        }
        throw new Error(`Controller ${controller} not found`);
    }
}
