<template>
    <div
        class="chat-box-wrapper"
    >
        <chat-box-button
            v-show="active"
            ref="button"
            :counter="commonUnread"
            @click.native="$openChatBox()"
        >
            <template #counter="{ value }">
                <transition name="fade">
                    <div
                        v-if="commonUnread > 0"
                        class="chat-box-counter"
                    >
                        {{ value }}
                    </div>
                </transition>
            </template>
        </chat-box-button>

        <chat-box />
    </div>
</template>

<script>
import ChatBoxButton from '@/components/common/XMessengersChatbox/ChatBoxButton';
import ChatBox from '@/components/common/XMessengersChatbox/ChatBox';
import { mapGetters, mapActions } from 'vuex';
import ChatBoxService from '@/apps/admin/services/messengers/chat-box/ChatBoxService';
import TabSingletonExecutor from '@/utils/classes/TabSingletonExecutor';

export default {
    name: 'XMessengersChatBoxWrapper',
    props: {
        active: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            executor: new TabSingletonExecutor('splynx-audio-lock'),
        };
    },
    mounted() {
        this.initChatBoxUpdating();
    },
    beforeUnmount() {
        this.executor?.destroy();
    },
    methods: {
        ...mapActions('chatbox', [
            'initChatBoxUpdating',
        ]),
        playSoundNotification() {
            this.executor.tryExecute(async () => {
                await ChatBoxService.playNotification(this.selectedNotificationsSound);
            });
        },
        playNotifyAnimation() {
            const element = this.$refs.button.$el;
            element.classList.add('bounce');
            setTimeout(() => {
                element.classList.remove('bounce');
            }, 1000);

            if (this.soundNotificationsActive) {
                this.playSoundNotification();
            }
        },
    },
    computed: {
        ...mapGetters('chatbox', [
            'notifyTrigger',
            'commonUnread',
            'soundNotificationsActive',
            'selectedNotificationsSound',
        ]),
    },
    watch: {
        notifyTrigger(newValue) {
            if (newValue) {
                this.playNotifyAnimation();
            }
        },
    },
    components: { ChatBox, ChatBoxButton },
};
</script>
