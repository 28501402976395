<template>
    <div
        :class="classes"
    >
        <template v-if="active">
            <div
                v-show="canAdminWrite"
                class="position-absolute chat-field-attach pe-4"
                @click="openAttachmentDialog"
            >
                <i class="icon-ic_fluent_attach_24_regular" />
            </div>
            <div
                v-show="canAdminWrite"
                :class="['position-absolute', 'chat-field-attach', { active: isCannedMessageButtonActive }, 'ps-4']"
                :style="{ left: '32px' }"
                @click="onCannedMessageClick"
            >
                <i class="icon-ic_fluent_comment_checkmark_24_regular" />
            </div>
            <x-complex-input
                v-show="canAdminWrite"
                ref="input"
                v-model="text"
                type="textarea"
                :params="{
                    options: {
                        rows,
                        style: 'width: 100%;',
                        placeholder: $t('messengers', 'Write your message'),
                    },
                }"
                :native-event-handlers="{
                    'keyup': onKeyup,
                }"
            />
            <button
                v-if="!canAdminWrite"
                :disabled="loading"
                type="button"
                class="btn btn-primary ms-16 nowrap"
                @click="openReassignToMeDialog"
            >
                {{ $t('messengers', 'Assign to me') }}
            </button>
            <button
                v-else
                :disabled="loading"
                type="button"
                :class="buttonSendClasses"
                @click="send"
            >
                {{ $t('messengers', 'Send') }}
            </button>
        </template>
        <template v-else>
            <button
                :disabled="loading"
                type="button"
                class="btn btn-primary"
                @click="$emit('start-conversation')"
            >
                {{ $t('messengers', 'Start conversation') }}
            </button>
        </template>
    </div>
</template>

<script>
import { XComplexInput } from '@/components/common/inputs/XComplexInput';

export default {
    name: 'ChatField',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        chatbox: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
        },
        canAdminWrite: {
            type: Boolean,
            default: false,
        },
        checkAccessAction: {
            type: String,
            default: '',
        },
        checkAccessController: {
            type: String,
            default: '',
        },
        setValueTrigger: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isCannedMessageButtonActive: false,
            text: '',
        };
    },
    computed: {
        buttonSendClasses() {
            return ['btn btn-primary ms-16', { 'chat-send': this.chatbox }];
        },
        classes() {
            return {
                'position-relative': true,
                'd-flex': true,
                'justify-content-end': !this.active || !this.canAdminWrite,
            };
        },
        rows() {
            return this.text.split('\n').length;
        },
    },
    methods: {
        focus(withSymbol) {
            if (withSymbol) {
                this.text = `${this.text}${withSymbol}`;
            }
            const input = this.$refs.input.$el;
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
        },
        onCannedMessageClick() {
            this.isCannedMessageButtonActive = !this.isCannedMessageButtonActive;
            if (this.isCannedMessageButtonActive) {
                this.focus();
                this.text = `/${this.text}`;
            } else if (this.text.at(0) === '/') {
                this.text = this.text.slice(1);
            }
        },
        onKeyup(event) {
            event.preventDefault();

            const bias = event.target.clientHeight + 120;
            this.$emit('set-scroll-block-height', {
                height: bias,
                isScroll: true,
            });

            // Canned messages recognition start with '/'
            this.isCannedMessageButtonActive = this.text.startsWith('/');

            const isDesktop = window.innerWidth > 768;
            if (isDesktop && event.key === 'Enter') {
                if (!event.shiftKey) {
                    this.processEnter();
                }
            }

            if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && this.isCannedMessageButtonActive) {
                this.$emit('arrow-select');
            }
            if (this.isCannedMessageButtonActive) {
                this.$emit('search-canned-messages', this.text.slice(1).trim());
            }
        },
        processEnter() {
            this.send();
        },
        send() {
            if (!this.text.trim()) {
                return false;
            }
            this.$emit('send', { content: this.text, files: [] });
            this.text = '';

            // Restore canned messages button state
            this.isCannedMessageButtonActive = false;
            // Restore textarea height
            this.$refs.input.$el.style.height = '1em';
            // Restore scroll block height
            this.$emit('set-scroll-block-height', { height: 148, isScroll: true });
        },
        sendMessageWithFiles(list, content = '') {
            const files = list.map((file) => file.id);
            this.$emit('send', { content, files });
        },
        setContentFromOutside(value) {
            this.text = value;
            this.isCannedMessageButtonActive = false;
            this.$refs.input.$el.style.height = `${this.rows * 32}px`;
            setTimeout(() => {
                this.focus();
                this.$refs.input.$el.dispatchEvent(new Event('input'));
                this.$emit('set-scroll-block-height', { height: this.$refs.input.$el.clientHeight + 120, isScroll: true });
            }, 10);
        },
        openAttachmentDialog() {
            if (this.canAdminWrite) {
                window.showModal('messenger-app-attachment-file', {
                    checkAccessAction: this.checkAccessAction,
                    checkAccessController: this.checkAccessController,
                    onSubmit: (data) => {
                        this.sendMessageWithFiles(data.files, data.message);
                    },
                });
            }
        },
        openReassignToMeDialog() {
            window.showModal('messenger-reassign-to-me', {
                text: this.$t('messengers', 'Are you sure you want to assign this chat to you?'),
                onSubmit: () => {
                    this.$emit('reassign-to-me');
                },
                onDismiss: () => {
                    this.$modals.close(this.name);
                },
            });
        },
    },
    components: {
        XComplexInput,
    },
    watch: {
        isCannedMessageButtonActive(value) {
            if (value) {
                this.$emit('open-canned-messages-dropdown');
            } else {
                this.$emit('close-canned-messages-dropdown');
            }
        },
        setValueTrigger(newValue) {
            if (newValue) {
                this.setContentFromOutside(newValue);
            }
        },
    },
};
</script>
