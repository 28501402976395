export default function createPersistedState(options = {}) {
    const storage = options.storage || window.localStorage;
    const key = options.key || 'vuex';
    const paths = options.paths || null;

    function getState() {
        try {
            const value = storage.getItem(key);
            return value ? JSON.parse(value) : undefined;
        } catch (err) {
            console.error('Failed to load state:', err);
            return undefined;
        }
    }

    function setState(state) {
        try {
            const data = paths ? filterState(state, paths) : state;
            storage.setItem(key, JSON.stringify(data));
        } catch (err) {
            console.error('Failed to save state:', err);
        }
    }

    function filterState(state, paths) {
        return paths.reduce((filtered, path) => {
            if (Object.prototype.hasOwnProperty.call(state, path)) {
                filtered[path] = state[path];
            }
            return filtered;
        }, {});
    }

    return function (store) {
        const savedState = getState();
        if (savedState) {
            store.replaceState({ ...store.state, ...savedState });
        }

        store.subscribe((mutation, state) => {
            if (typeof options.filter === 'function' && !options.filter(mutation)) {
                return;
            }
            setState(state);
        });
    };
}
