import BankStatementsRoutes from './finance/bank-statements';
import RefillCardsRoutes from './finance/refill-cards';

const FinanceDashboard = () => import(/* webpackChunkName: "finance_dashboard" */ '@/apps/admin/views/admin/finance/dashboard/FinanceDashboard');
const FinanceTransactionsList = () => import(/* webpackChunkName: "finance_transactions" */ '@/apps/admin/views/admin/finance/transactions/FinanceTransactionsList');
const FinanceInvoicesList = () => import(/* webpackChunkName: "finance_invoices" */ '@/apps/admin/views/admin/finance/invoices/FinanceInvoicesList');
const FinanceCreditNotesList = () => import(/* webpackChunkName: "finance_credit_notes" */ '@/apps/admin/views/admin/finance/credit-notes/FinanceCreditNotesList');
const FinanceProformaInvoices = () => import(/* webpackChunkName: "finance_proforma_invoices" */ '@/apps/admin/views/admin/finance/proforma-invoices/FinanceProformaInvoices');
const FinancePaymentsList = () => import(/* webpackChunkName: "finance_payments" */ '@/apps/admin/views/admin/finance/payments/FinancePaymentsList');
const FinanceCostsList = () => import(/* webpackChunkName: "finance_costs" */ '@/apps/admin/views/admin/finance/costs/FinanceCostsList');
const HistoryAndPreview = () => import(/* webpackChunkName: "finance_history" */ '@/apps/admin/views/admin/finance/finance-history/HistoryAndPreview');

export default [
    {
        path: 'dashboard',
        name: 'admin.finance.dashboard',
        component: FinanceDashboard,
        meta: {
            keepAlive: true,
            componentName: 'FinanceDashboard',
        },
    },
    {
        path: 'transactions',
        name: 'admin.finance.transactions',
        component: FinanceTransactionsList,
        meta: {
            title: ['finance', 'Transactions'],
            breadcrumbs: [
                { title: ['finance', 'Transactions'], url: 'admin/finance/transactions' },
            ],
            keepAlive: true,
            componentName: 'FinanceTransactionsList',
        },
    },
    {
        path: 'invoices',
        name: 'admin.finance.invoices',
        component: FinanceInvoicesList,
        meta: {
            title: ['finance', 'Invoices'],
            controller: 'controllers\\admin\\finance\\InvoicesController',
            breadcrumbs: [
                { title: ['finance', 'Invoices'], url: 'admin/finance/transactions' },
            ],
            keepAlive: false,
            componentName: 'FinanceInvoicesList',
        },
    },
    {
        path: 'credit-notes',
        name: 'admin.finance.credit-notes',
        component: FinanceCreditNotesList,
        meta: {
            keepAlive: true,
            componentName: 'FinanceCreditNotesList',
        },
    },
    {
        path: 'proforma-invoices',
        name: 'admin.finance.proforma_invoices',
        component: FinanceProformaInvoices,
        meta: {
            keepAlive: true,
            componentName: 'FinanceProformaInvoices',
        },
    },
    {
        path: 'payments',
        name: 'admin.finance.payments',
        component: FinancePaymentsList,
        meta: {
            keepAlive: true,
            componentName: 'FinancePaymentsList',
        },
    },
    {
        path: 'history',
        name: 'admin.finance.history',
        component: HistoryAndPreview,
        meta: {
            title: ['finance', 'History & Preview'],
            breadcrumbs: [
                { title: ['finance', 'History & Preview'], url: 'admin/finance/history' },
            ],
            keepAlive: true,
            componentName: 'HistoryAndPreview',
        },
    },
    {
        path: 'bank-statements',
        children: BankStatementsRoutes,
    },
    {
        path: 'refill-cards',
        children: RefillCardsRoutes,
    },
    {
        path: 'costs',
        name: 'admin.finance.costs',
        component: FinanceCostsList,
        meta: {
            keepAlive: true,
            componentName: 'FinanceCostsList',
        },
    },
];
