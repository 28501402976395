import Vue from 'vue';

import install from './install';
import { isset, isFunction } from './utils';

export default class Splang {
    static install;

    static version;

    messages = {};

    loadMessagesCallback = null;

    _vm = null;

    _splangWatcher = null;

    _dataListeners = [];

    _listTranslateCategories = [];

    constructor(options) {
        const locale = options.locale || 'en';
        if (options.loadMessages && typeof options.loadMessages === 'function') {
            this.loadMessagesCallback = options.loadMessages;
        }

        if (options.listTranslateCategories) {
            this._listTranslateCategories = options.listTranslateCategories;
        }

        this._initVm({
            locale,
        });

        this.loadLocale();
    }

    get vm() { return this._vm; }

    get locale() { return this._vm.locale; }

    set locale(locale) {
        this._vm.$set(this._vm, 'locale', locale);
        this.loadLocale();
    }

    loadLocale() {
        if (!isset(this.messages, this.locale)) {
            this.messages[this.locale] = {};
        }

        if (!isFunction(this.loadMessagesCallback)) {
            return;
        }

        let messages = this.loadMessagesCallback(this.locale);
        if (messages !== null && typeof messages === 'object') {
            this.messages[this.locale] = messages;
            this._forceUpdateListeners();
        }
    }

    destroyVM() {
        this._vm.$destroy();
    }

    watchSplangData() {
        const self = this;
        return this._vm.$watch('$data', () => {
            self._forceUpdateListeners();
        }, { deep: true });
    }

    _initVm(data) {
        const { silent } = Vue.config;
        Vue.config.silent = true;
        this._vm = new Vue({ data });
        Vue.config.silent = silent;
    }

    _forceUpdateListeners() {
        this._dataListeners.forEach((e) => {
            Vue.nextTick(() => {
                e && e.$forceUpdate();
            });
        });
    }

    _subscribeDataChanging(vm) {
        this._dataListeners.push(vm);
    }

    _unsubscribeDataChanging(vm) {
        const index = this._dataListeners.indexOf(vm);
        if (index > -1) {
            this._dataListeners.splice(index, 1);
        }
    }

    _t(category, phrase, params) {
        if (process.env.NODE_ENV === 'development' && this._listTranslateCategories.length !== 0 && this._listTranslateCategories.indexOf(category) === -1) {
            Vue.nextTick(() => {
                let errorMessage = `Translation category "${category}" does not exist!`;
                window.xApp.$showError(errorMessage, false);
            });
        }
        if (isset(this.messages, [this.locale, category, phrase])) {
            let translate = this.messages[this.locale][category][phrase];
            return Splang._interpolateMessage(translate, params);
        }
        // Translate not available, so return original phrase
        return Splang._interpolateMessage(phrase, params);
    }

    t(category, phrase, params) {
        return this._t(category, phrase, params);
    }

    static _interpolateMessage(phrase, params) {
        if (params && typeof phrase === 'string') {
            for (let key in params) {
                phrase = Splang._replaceAll(phrase, `{${key}}`, params[key]);
            }
        }
        return phrase;
    }

    static _replaceAll(str, find, replace) {
        if (typeof str !== 'string') {
            return str;
        }

        return str.replace(new RegExp(find, 'g'), replace);
    }
}

window.SPLANG_CLASS = Splang;

Splang.install = install;
Splang.version = '__VERSION__';
