import XTwigPage from '@/components/base/XTwigPage';
import HardwareView from '@/apps/admin/views/admin/networking/hardware/view/HardwareView.vue';

export default [
    {
        path: '',
        name: 'admin.networking.monitoring.view',
        component: HardwareView,
        meta: {
            breadcrumbs: [
                {
                    title: ['networking', 'Networking'],
                    url: '#',
                },
                {
                    title: ['networking', 'Hardware'],
                    url: '#',
                },
                {
                    title: ['networking', 'List'],
                    url: '/admin/networking/monitoring/list',
                },
            ],
            controller: 'controllers\\admin\\networking\\monitoring\\ViewController',
        },
    },
    {
        delimiter: '--',
        path: 'oid',
        name: 'admin.networking.monitoring.view.oid',
        component: XTwigPage,
        meta: {
            title: ['networking', 'SNMP OID'],
        },
    },
    {
        delimiter: '--',
        path: 'log',
        name: 'admin.networking.monitoring.view.log',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Logs'],
        },
    },
    {
        delimiter: '--',
        path: 'backup',
        name: 'admin.networking.monitoring.view.backup',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Backup'],
        },
    },
    {
        delimiter: '--',
        path: 'map',
        name: 'admin.networking.monitoring.view.map',
        component: XTwigPage,
        meta: {
            title: ['networking', 'Map'],
        },
    },
];
