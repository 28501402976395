import global_search from '@/apps/admin/store/modules/base/global_search';
import history_and_preview from '@/apps/admin/store/modules/finance/history_and_preview';
import administration from '@/apps/admin/store/modules/administration';
import hotspot_export_report from '@/apps/admin/store/modules/administration/reports/hotspot_export_report';
import menu_store from '@/components/base/XMenu/store';
import tabs_store from '@/components/common/XTabs/store';
import filters_store from '@/components/common/XFilter/store';
import global_notifications_store from '@/components/base/XGlobalNotification/store';
import scheduling_map from '@/apps/admin/store/modules/scheduling/scheduling_map';
import scheduling_calendars from '@/apps/admin/store/modules/scheduling/scheduling_calendars';
import customer_communication from '@/apps/admin/store/modules/customers/communication/customer_communication';
import lead_communication from '@/apps/admin/store/modules/crm/leads/communication/lead_communication';
import taxes from '@/common-store/taxes';
import sidebar from '@/components/base/XSidebar/sidebar';
import task_customer_bus from '@/apps/admin/store/modules/scheduling/task_customer_bus';
import geo_data from '@/apps/admin/store/modules/common/geo_data';
import network_sites_view from '@/apps/admin/store/modules/networking/network-sites/network_sites_view';
import update_table_items from '@/components/common/XDatatable/store';
import createPersistedState from './vuex-persisted-state';
import admin_permissions from './modules/admin_permissions';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import search from './modules/search';
import entry_points from './modules/entry_points';
import locations from './modules/locations';
import partners from './modules/partners';
import leads from './modules/leads';
import tasks from './modules/scheduling/tasks';
import scheduling_resources from './modules/scheduling/sheduling_resources';
import task_view from './modules/scheduling/task_view';
import render_task_template from './modules/scheduling/render_task_template';
import task_view_checklist from './modules/scheduling/task_view_checklist';
import task_view_warnings from './modules/scheduling/task_view_warnings';
import task_options_for_inventory_modals from './modules/scheduling/task_options_for_inventory_modals';
import notifications from './modules/base/notifications';
import internet_tariffs from './modules/tariffs/internet_tariffs';
import one_time_tariffs from './modules/tariffs/one_time_tariffs';
import recurring_tariffs from './modules/tariffs/recurring_tariffs';
import voice_tariffs from './modules/tariffs/voice_tariffs';
import bundle_tariffs from './modules/tariffs/bundle_tariffs';
import customer_invoices from './modules/customers/billing/customer_invoices';
import customer_credit_notes from './modules/customers/billing/customer_credit_notes';
import acs_provision_flow from './modules/configs/networkign/acs/provision_flow';
import settings from './modules/common/settings';
import customers from './modules/customers/customers';
import leads_view from './modules/crm/view/leads';
import inventory from './modules/inventotry/index';
import customer_billing from './modules/customers/billing/customer_billing';
import customer_proforma_invoices from './modules/customers/billing/customer_proforma_invoices';
import templates from './modules/configs/templates';
import labels from './modules/configs/labels';
import customer_payments from './modules/customers/billing/customer_payments';
import customer_statements from './modules/customers/billing/customer_statements';
import customer_transactions from './modules/customers/billing/customer_transactions';
import customer_recurring_invoices from './modules/customers/billing/customer_recurring_invoices';
import finance from './modules/finance';
import on_boarding from './modules/onboarding/onboarding';
import customers_overview from './modules/customers/overview';
import cap_configs from './modules/configs/main/cap/cap_configs';
import config_reminders from './modules/configs/finance/reminders/reminders';
import finance_settings from './modules/configs/finance/settings/finance_settings';
import company_info from './modules/configs/system/company_information';
import portal_configs from './modules/configs/main/portal/portal_configs';
import incoming_mail_boxes from './modules/configs/support/incoming-mail/incoming-mail-boxes';
import admin_profile from './modules/profile/admin_profile';
import admin_imap from './modules/profile/admin_imap';
import admin_customer_search from './modules/profile/admin_customer_search';
import admin_customers_list from './modules/customers/list';
import admin_scheduling from './modules/profile/admin_scheduling';
import permissions from './modules/common/permissions';
import addon_config_main from './modules/configs/modules/add-on/main-information';
import addon_config from './modules/configs/modules/add-on/add-on';
import serialize from './serializer';
import file_upload from './modules/common/fileUpload';
import chatbox from './modules/chatbox';
import chats_listener from './modules/chats_listener';

export default {
    state: () => ({
        menuStructure: [],
        admin: {},
        logo: '',
        footer: '',
        license: {},
        serverSideConfig: {},
        pageTitle: '',
        refreshRouteKey: (new Date().getTime()),
        documentationConfig: {},
        firstDayOfWeek: {},
        weekends: {},
        showPageError: {
            error: null,
            comment: null,
            code: 0,
            file: null,
            line: null,
            html: null,
        },
        headerShortcuts: [],
    }),
    mutations,
    actions,
    getters,
    modules: {
        admin_permissions,
        chatbox,
        chats_listener,
        filters_store,
        tabs_store,
        search,
        entry_points,
        locations,
        partners,
        leads,
        tasks,
        task_view,
        task_customer_bus,
        task_view_checklist,
        task_view_warnings,
        task_options_for_inventory_modals,
        global_notifications_store,
        sidebar,
        notifications,
        internet_tariffs,
        one_time_tariffs,
        recurring_tariffs,
        voice_tariffs,
        bundle_tariffs,
        scheduling_resources,
        customer_invoices,
        customer_credit_notes,
        customers,
        leads_view,
        customer_communication,
        lead_communication,
        customer_billing,
        acs_provision_flow,
        settings,
        inventory,
        customer_proforma_invoices,
        templates,
        labels,
        customer_payments,
        customer_statements,
        customer_transactions,
        customer_recurring_invoices,
        finance,
        global_search,
        history_and_preview,
        customers_overview,
        on_boarding,
        scheduling_map,
        hotspot_export_report,
        menu_store,
        administration,
        cap_configs,
        portal_configs,
        incoming_mail_boxes,
        admin_profile,
        admin_imap,
        admin_customer_search,
        admin_customers_list,
        admin_scheduling,
        taxes,
        permissions,
        config_reminders,
        company_info,
        finance_settings,
        addon_config_main,
        addon_config,
        scheduling_calendars,
        render_task_template,
        network_sites_view,
        file_upload,
        geo_data,
        update_table_items,
    },
    plugins: [
        createPersistedState({
            paths: ['notifications', 'admin_customers_list', 'filters_store', 'chatbox'],
            setState(key, state, storage) {
                storage.setItem(key, JSON.stringify(serialize(state)));
            },
            storage: {
                getItem: (key) => window.localStorage.getItem(key),
                setItem: (key, value) => window.localStorage.setItem(key, value),
                removeItem: (key) => window.localStorage.removeItem(key),
            },
        }),
    ],
};
