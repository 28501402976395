import Vue from 'vue';

import VueSlider from 'vue-slider-component';

import Popover from 'vue-js-popover';

import JSZip from 'jszip';

import Chart from 'chart.js';

import { encode, decode } from 'html-entities';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import VueAvatar from 'vue-avatar-editor-improved/src/components/VueAvatar.vue';
import html2canvas from 'html2canvas';
import md5 from 'md5';
import codeSnippetPlugin from '@/components/common/inputs/XFroala/custom-plugins/code_snippet';
import MaskedInput from '@/utils/classes/MaskedInput';

global.jQuery = require('jquery');

global.$ = global.jQuery;
window.jQuery = global.jQuery;
window.$ = global.jQuery;
window.html2canvas = html2canvas;
window.md5 = md5;
window.html_entity = { encode, decode };

require('@/../web/js/development/csrf');

window.Vue = Vue;
window.VueSlider = VueSlider;
Vue.use(Popover, { tooltip: true });
window.MaskedInput = MaskedInput;

require('@/../web/js/development/jquery.address.min');
require('jquery-ui');
require('jquery-ui/ui/widgets/autocomplete');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/tabs');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-slide');

require('bootstrap/js/dist/dropdown');
require('bootstrap/js/dist/collapse');
require('bootstrap/js/dist/popover');
require('bootstrap/js/dist/button');
require('bootstrap/js/dist/alert');
require('bootstrap/js/dist/modal');
// For canned responses
require('bootstrap/js/dist/tab');

require('@/../web/js/development/custom.jquery-ui.dialog.js');
require('@/../web/js/development/customcolorpicker');

require('@/../web/js/development/jquery.form.min');
require('@/../web/js/development/jquery.serializejson.min');
require('@/../web/js/development/loading.min');
window.autosize = require('@/../web/js/development/autosize.min');

require('@/../web/js/development/typeahead.min');
require('@/../web/js/development/head.min');

window.JSZip = JSZip;

require('datatables.net');
require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-bs');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.print.min');
require('datatables.net-buttons/js/buttons.html5.min');
require('datatables.net-buttons-bs');
require('jquery-datatables-checkboxes');
require('datatables.net-rowgroup');
require('@/../web/js/development/dataTables.sort');

require('multiple-select');

require('@/../web/js/development/jquery.cookie');

window.io = require('@/../web/js/development/socket.io');
require('@/../web/js/development/jquery.iframe-transport');
require('@/../web/js/development/jquery.fileupload');
require('@/../web/js/development/jquery.webui-popover.min');
require('@/../web/js/development/bootstrap-colorpicker.min');
require('@/../web/js/development/jquery.blockUI');

require('@/../web/js/development/fup');
require('@/../web/js/development/time.slider');
require('@/../web/js/development/list.min');

window.moment = require('moment-timezone');

window.reinitMoment = window.moment;

require('daterangepicker');
require('@/../web/js/development/customdatetimepicker');
require('@/../web/js/development/customization-date-range-picker');

require('@/../web/js/development/jquery.number.min');
require('@/../web/js/development/jquery.timepicker.min');
window.NProgress = require('@/../web/js/development/nprogress');
require('@/../web/js/development/original_values.min');

window.Chart = Chart;

require('@/../web/js/development/chartjs-plugin-streaming.min');

window.CodeMirror = require('@/../web/js/development/codemirror/codemirror.min');
require('@/../web/js/development/froala/froala_editor.pkgd.min');
require('@/../web/js/development/froala/froala_helpers');

require('@/../web/js/development/tribute.min');
require('@/../web/js/development/bootstrap-tagsinput.min');
require('@/../web/js/development/jquery.responsivetabs');
require('@/../web/js/development/tabs_state');
require('@/../web/js/development/jquery.scrollTo.min');
require('@/../web/js/development/print.min');

window.PhotoSwipe = PhotoSwipe;
window.PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default.min');

// Froala custom plugins from Vue for Twig
window.INIT_FROALA_CODE_SNIPPET_PLUGIN = codeSnippetPlugin;

require('@/../web/js/development/visible');
require('@/../web/js/development/select2.min');
require('@/../web/js/development/jquery.noty.packaged.min');
require('@/../web/js/development/jquery.noty.manager');

require('@/../web/js/development/splynx_photo_swiper.min');
require('@/../web/js/development/admin.events.min');
require('@/../web/js/development/jquery.timeago');

/* eslint-disable-next-line import/no-dynamic-require */
require(`@/../web/js/locale/timeago/jquery.timeago.${spl_config.language}`);

require('@/../web/js/development/functions');
require('@/../web/js/development/admin');

window.VueAvatar = VueAvatar;
// SPL-3301 Admin logout
add_socket_listener(`admin_logout_${spl_config.spl_user_id}`, () => {
    window.address_reload(false, true);
});
window.logout_listener = `admin_logout_${spl_config.spl_user_id}`;

load_io();
init_web_events();

require('./globals');
