export default class HalfPart {
    messages = [];

    conversations = {};

    order = [];

    constructor({
        messages, conversations, order_messages, prev_message_id, next_message_id,
    } = {}) {
        this.messages = messages ?? [];
        this.conversations = conversations ?? {};
        this.order = order_messages ?? [];

        this.earlierMessage = next_message_id ?? null;
        this.earliestMessage = this.order[this.order.length - 1] ?? null;

        this.laterMessage = prev_message_id ?? null;
        this.latestMessage = this.order[0] ?? null;
    }

    get() {
        return {
            messages: this.messages,
            conversations: this.conversations,
            order: this.order,
        };
    }
}
