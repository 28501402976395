<template>
    <div>
        <div :class="{'input-group': true, 'input-group-file': true, 'input-group-download-file': downloadButton}">
            <span class="btn btn-outline-secondary">
                {{ getButtonText }}
            </span>
            <input
                v-bind="options"
                ref="input"
                type="file"
                :multiple="multiple"
                class="form-control input-sm"
                @change.prevent.stop="inputHandler"
            >
            <input
                type="text"
                class="form-control input-sm"
                :readonly="!customPlaceholder"
                :placeholder="customPlaceholder"
                :value="internalFileName"
            >
            <span
                v-if="downloadButton"
                class="btn btn-outline-secondary btn-icon"
                :title="downloadButtonTitle"
                @click="downloadButtonHandler"
            >
                <i class="icon-ic_fluent_arrow_download_24_regular" />
            </span>
            <slot name="button" />
        </div>

        <x-file-view
            v-if="displayFileView"
            :meta="fileMeta"
            :file-data="fileData"
            :show-delete-button="showDeleteButton"
            :input-value="value"
        />
        <div
            v-if="fileIsHeic"
            class="alert alert-danger alert-dismissible mt-16"
            role="alert"
        >
            {{
                $t('common', 'The uploaded HEIC image cannot be displayed as a preview. It will be converted to JPG after saving.')
            }}
        </div>
    </div>
</template>

<script>
import { empty } from '@/utils/functions';
import XFileView from './XFileView.vue';

export default {
    name: 'XInputFile',
    props: {
        value: [File, String, FileList, Number],
        options: Object,
        buttonText: {
            type: String,
            default: '',
        },
        fileName: {
            type: String,
        },
        downloadButton: {
            type: Boolean,
            default: false,
        },
        downloadButtonTitle: {
            type: String,
            default: '',
        },
        downloadButtonUrl: {
            type: String,
            default: '',
        },
        customPlaceholder: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        showPreview: {
            type: Boolean,
            default: false,
        },
        fileMeta: {
            type: Object,
            default: () => {},
        },
        nonImage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            internalFileName: this.fileName,
            fileData: '',
            showDeleteButton: true,
            fileIsHeic: false,
            fileSizeIsLarge: false,
        };
    },
    methods: {
        getFilesNames(files) {
            const messageOther = files.length - 2 === 1 ? this.$t('common', 'other') : this.$t('common', 'others');
            files = Array.from(files).map((file, index) => {
                if (index > 2) {
                    return null;
                }
                if (index === 2) {
                    return `+ ${files.length - 2} ${messageOther}`;
                }
                return file.name;
            });
            files = files.filter((file) => !!file);
            return files.join(', ');
        },
        inputHandler(event) {
            this.internalFileName = event.target.files.length > 0 ? event.target.files[0].name : '';
            if (this.multiple) {
                this.internalFileName = event.target.files.length > 0 ? this.getFilesNames(event.target.files) : '';
                this.$emit('input', event.target.files);
                return;
            }

            if (this.showPreview) {
                this.onFileChange(event.target.files[0]);
            }
            this.$emit('input', event.target.files[0]);
        },
        downloadButtonHandler() {
            download_by_iframe(this.downloadButtonUrl);
        },
        onFileChange(file) {
            if (empty(file)) {
                this.internalFileName = this.fileName;
                return false;
            }

            this.fileIsHeic = file.type && (file.type.startsWith('image/heic') || file.type.startsWith('image/heif'));

            if (file.type && (file.type.split('/')[0] === 'image' || this.nonImage)) {
                if (file.size > 5242880) {
                    this.fileSizeIsLarge = true;
                    show_error(this.$t('config', 'Image size is too large! Max image size is 5 MB'), 5);
                } else {
                    this.fileSizeIsLarge = false;
                    let reader = new FileReader();
                    let vm = this;

                    reader.onload = (e) => {
                        vm.fileData = e.target.result;
                    };
                    reader.readAsDataURL(file);
                }
            } else {
                show_error(this.$t('config', 'Invalid file type!'), 5);
            }
            this.showDeleteButton = false;
        },
    },
    watch: {
        fileName() {
            this.internalFileName = this.fileName;
        },
        value() {
            if (this.value === null) {
                this.internalFileName = '';
            }
            if (typeof this.value === 'string') {
                this.internalFileName = this.fileName;
            }
        },
    },
    computed: {
        displayFileView() {
            return this.showPreview && !this.fileSizeIsLarge && !this.fileIsHeic;
        },
        getButtonText() {
            return !empty(this.buttonText) ? this.buttonText : this.$t('common', 'Browse');
        },
    },
    components: {
        XFileView,
    },
};
</script>
