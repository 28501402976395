<template>
    <x-addon-vue-component
        v-if="loaded"
        :url="url"
        :internal-preloader="true"
        :down-data="downData"
        :is-page="isPage"
        @up-data="onDataFromAddon"
        @processing="processing = $event"
    >
        <slot
            v-if="processing"
            name="preloader"
        >
            <div class="h-100 d-flex w-100 justify-content-center align-items-center">
                <x-bootstrap-spinner size="lg" />
            </div>
        </slot>
    </x-addon-vue-component>
</template>

<script>
import { promisifiedAjax } from '@/utils/functions';
import XAddonVueComponent from '@/components/base/XAddonVueComponent/XAddonVueComponent';
import XBootstrapSpinner from '@/components/common/XBootstrapSpinner/XBootstrapSpinner';

export default {
    name: 'XAddonVueCode',
    props: {
        module: String,
        point: [String, Number],
        params: {
            type: Object,
            default: () => ({}),
        },
        pointParams: {
            type: Object,
            default: () => ({}),
        },
        isPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            processing: true,
            loaded: false,
            url: '',
            pointData: {},
        };
    },
    mounted() {
        promisifiedAjax({
            url: '/admin/config/add-on--get-entry-point-data',
            data: {
                module: this.module,
                point: this.point,
                ...this.pointParams,
            },
        }).then(({
            uri, point,
        }) => {
            this.pointData = point;
            this.url = uri;

            if (this.isPage) {
                const url = new URL(`${window.location.origin}/${uri}`);
                const isVue = url.searchParams.get('is_vue');
                if (!isVue) {
                    this.$emit('no-vue-point');
                    return false;
                }
            }

            this.$nextTick(() => {
                this.loaded = true;
            });
        }).catch((error) => {
            this.$showError(error);
            this.url = '';
        });
    },
    methods: {
        onDataFromAddon(data) {
            if (data.action) {
                this.$emit(data.action, data.data);
            }
        },
    },
    computed: {
        downData() {
            return {
                params: this.params,
                pointParams: this.pointParams,
                pointData: this.pointData,
            };
        },
    },
    components: { XBootstrapSpinner, XAddonVueComponent },
};
</script>
